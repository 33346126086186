import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const FINISH_SEQUENCE_MUTATION = gql`
mutation finishSequence($hashStr: String){
  finishSequence(hashStr: $hashStr){
    success
  }
}
`;

export const POSTPONE_TRIAL_MUTATION = gql`
mutation postponeTrial($hashStr: String, $newDate: Date){
  postponeTrial(hashStr: $hashStr, newDate: $newDate){
    success
  }
}
`;

export const REJECT_FEEDBACK_MUTATION = gql`
mutation rejectFeedback($hashStr: String, $feedback: String, $reason: String){
  rejectFeedback(hashStr: $hashStr, feedback: $feedback, reason: $reason){
    success
  }
}
`;
