import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import copy from 'copy-to-clipboard';
import { ReactComponent as ShareLinkIcon } from '../../assets/icons/interviewActions/shareLinkIcon.svg';
import { CREATE_SHARE_LINK_MUTATION } from '../../queries/interview/interview';

const ShareSocial = ({ interviewId, ...style }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createLink] = useMutation(CREATE_SHARE_LINK_MUTATION);

  const handleClick = () => {
    createLink({
      variables: { interviewId },
    }).then(({ data: { createShareLink = {} } = {} }) => {
      if (!isEmpty(createShareLink.errors)) {
        enqueueSnackbar(createShareLink.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        copy(createShareLink.link || '');
        enqueueSnackbar('Link has been copied.', { variant: 'success', autoHideDuration: 4000 });
      }
    });
  };

  return (
    <Tooltip title="Share" arrow>
      <Box
        {...style}
        onClick={handleClick}
      >
        <ShareLinkIcon />
      </Box>
    </Tooltip>
  );
};

ShareSocial.propTypes = {
  interviewId: PropTypes.string.isRequired,
};

export default ShareSocial;
