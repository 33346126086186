import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import isEqual from 'lodash/isEqual';
import { useMutation, gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { REMOVE_FROM_READING_LIST_MUTATION } from '../../../../../queries/interview/interview';
import useAllIntervies from '../../../../../hooks/useAllIntervies';
import sidebarTabs from '../../../../../constants/sidebarTabs';
import useReadingListTitle from '../../../../../hooks/useReadingListTitle';

const useStyles = makeStyles((theme) => ({
  removeBtn: {
    padding: '2px 3px',
    borderRadius: '2px',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: theme.palette.info.light,
    },
  },
}));

const DeleteFromReadingList = ({ interviewId, icon }) => {
  const classes = useStyles();
  const activeTab = useSelector((state) => state.sideBar.activeTab, isEqual);
  const { allInterviews, updateQuery: updateAllInterviewsQuery } = useAllIntervies();
  const readingListTitle = useReadingListTitle();
  const { enqueueSnackbar } = useSnackbar();

  const [removeFromReadingList] = useMutation(REMOVE_FROM_READING_LIST_MUTATION, {
    update: (cache, { data }) => {
      if (activeTab === sidebarTabs.reading) {
      // update interviews in the middle column
        updateAllInterviewsQuery((previousQueryResult) => {
          const results = allInterviews.results.filter((el) => {
            if (el.interviewId !== interviewId) {
              return el;
            }
            return null;
          });
          return {
            allInterviews: {
              ...previousQueryResult.allInterviews,
              results,
            },
          };
        });
      } else {
        updateAllInterviewsQuery((previousQueryResult) => {
          const results = previousQueryResult.allInterviews.results.map((el) => {
            if (el.interviewId === interviewId) {
              return { ...el, readingListItem: null, isSeenInRl: null };
            }
            return el;
          });

          return {
            allInterviews: {
              ...previousQueryResult.allInterviews,
              results,
            },
          };
        });
      }
      // update reading list item on the right side
      data.removeIntervievFromRl.interviews.forEach((item) => {
        cache.writeFragment({
          id: cache.identify(item),
          fragment: gql`
            fragment interviewReadingList on InterviewType{
              readingListItem
            }
          `,
          data: {
            readingListItem: null,
          },
        });
      });
      enqueueSnackbar(`Interview has been removed from the ${readingListTitle.toLowerCase()}.`, { variant: 'success', autoHideDuration: 4000 });
    },
  });

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    removeFromReadingList({
      variables: { interviewIds: [interviewId] },
    }).catch((res) => {
      const messages = res.graphQLErrors.map((el) => el.message).join(', ');
      enqueueSnackbar(messages, { variant: 'error', autoHideDuration: 4000 });
    });
  };

  return (
    <Tooltip disableFocusListener title={`Remove Interview from ${readingListTitle.toLowerCase()}`}>
      <IconButton
        className={classes.removeBtn}
        aria-label={`remove interview from ${readingListTitle.toLowerCase()}`}
        onClick={handleDeleteClick}
        data-track="readingList/remove"
        disableRipple
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
};

DeleteFromReadingList.propTypes = {
  interviewId: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default DeleteFromReadingList;
