import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Field, Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation } from '@apollo/client';
import { INTERVIEW_QUERY, ADD_COMPLIANCE_OFFICER_MUTATION } from '../../queries/interview/interview';
import { ME_QUERY } from '../../queries/account';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    backgroundColor: theme.palette.background.paper,
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginBottom: '0',
      marginRight: '10px',
    },
  },
  logo: {
    width: '48px',
    minWidth: '48px',
    height: '48px',
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0',
      marginRight: '10px',
    },
  },
  text: {
    width: '80%',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '21px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      textAlign: 'left',
      marginBottom: '0',
      marginRight: '10px',
    },
  },
  sendBtn: {
    height: '40px',
    backgroundColor: theme.palette.info.main,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    padding: '11px 20px',
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  modalBody: {
    padding: '20px',
    position: 'relative',
    flex: '1 1 auto',
  },
  p0: {
    padding: '0',
  },
  closeBtn: {
    minWidth: '18px',
    position: 'absolute',
    right: '10px',
    top: '10px',
    padding: '5px',
  },
  modalTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '26px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    margin: '0 15px',
  },
  formContent: {
    padding: '0',
  },
  inputWrap: {
    marginBottom: '15px',
    flexWrap: 'wrap',
    display: 'flex',
  },
  inputLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    display: 'inline-block',
    marginBottom: '5px',
  },
  inputField: {
    display: 'block',
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    height: '40px',
    outline: 'none',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  submitBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
  buttonProgress: {
    color: theme.palette.info.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const AddComplianceOfficer = ({ interviewId }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [addOfficer, {
    loading,
  }] = useMutation(ADD_COMPLIANCE_OFFICER_MUTATION, {
    errorPolicy: 'all',
    refetchQueries: [
      { query: INTERVIEW_QUERY, variables: { interviewId } },
      { query: ME_QUERY },
    ],
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (values) => {
    addOfficer({
      variables: {
        interviewId,
        ...values,
      },
    });
    handleClose();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.leftSide}>
        <Box className={classes.logo}>
          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 0C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H7.51953C7.04953 19.4 6.68945 18.73 6.43945 18H2V2H14V8.0293L16 8.43945V2C16 0.9 15.1 0 14 0H2ZM4 5V7H12V5H4ZM4 9V11H7.51953C8.11953 10.18 8.8893 9.5 9.7793 9H4ZM13.5 10C12.201 10 10.9936 10.4545 10.0508 11.2246C8.79509 12.228 8 13.7783 8 15.5C8 15.706 8.01221 15.9125 8.03711 16.1152C8.34424 18.8593 10.6786 21 13.5 21C14.6545 21 15.7267 20.6398 16.6133 20.0273L18.543 21.957L19.957 20.543L18.0273 18.6133C18.6398 17.7267 19 16.6545 19 15.5C19 12.4718 16.5282 10 13.5 10ZM14 12.0488C15.7075 12.289 17 13.7197 17 15.5C17 17.4518 15.4518 19 13.5 19C11.7226 19 10.2914 17.6981 10.0469 16H14V12.0488ZM4 13V15H6.0293C6.0593 14.3 6.19945 13.63 6.43945 13H4Z" fill="#1168A7" />
          </svg>
        </Box>
        <Box className={classes.text}>
          Need a Compliance Review? Send this interview to your Compliance Officer
        </Box>
      </Box>
      <Button
        onClick={handleClickOpen}
        className={classes.sendBtn}
        disableRipple
      >
        Send
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Box className={classes.modalBody}>
          <DialogTitle id="form-dialog-title" className={classes.p0}>
            <Button
              onClick={handleClose}
              data-dismiss="modal"
              aria-label="Close"
              className={classes.closeBtn}
              disableRipple
            >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.69922 2.7002L15.2992 15.3002" stroke="#8293A6" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.69922 15.3002L15.2992 2.7002" stroke="#8293A6" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Button>
            <Box className={classes.modalTitle}>
              Send to your Compliance Officer
            </Box>
          </DialogTitle>
          <Form
            onSubmit={onSubmit}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              }
              return errors;
            }}
            render={({
              handleSubmit,
              submitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent className={classes.formContent}>
                  <Field name="email">
                    {({ input, meta }) => (
                      <Box className={classes.inputWrap}>
                        <Box component="div" className={classes.inputLabel}>
                          <label htmlFor="id_email">Compliance Officer&apos;s Email:</label>
                        </Box>
                        <TextField
                          {...input}
                          type="email"
                          id="id_email"
                          className={classes.inputField}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                        />
                        {(meta.error || meta.submitError) && meta.touched && (
                          <Box
                            component="span"
                            color="error.main"
                          >
                            {meta.error || meta.submitError}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Field>
                  <Field name="first_name">
                    {({ input, meta }) => (
                      <Box className={classes.inputWrap}>
                        <Box component="div" className={classes.inputLabel}>
                          <label htmlFor="id_first_name">Compliance Officer&apos;s First Name:</label>
                        </Box>
                        <TextField
                          {...input}
                          type="first_name"
                          id="id_first_name"
                          className={classes.inputField}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                        />
                        {(meta.error || meta.submitError) && meta.touched && (
                          <Box
                            component="span"
                            color="error.main"
                          >
                            {meta.error || meta.submitError}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Field>
                  <Field name="last_name">
                    {({ input, meta }) => (
                      <Box className={classes.inputWrap}>
                        <Box component="div" className={classes.inputLabel}>
                          <label htmlFor="id_last_name">Compliance Officer&apos;s Last Name:</label>
                        </Box>
                        <TextField
                          {...input}
                          type="last_name"
                          id="id_last_name"
                          className={classes.inputField}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                        />
                        {(meta.error || meta.submitError) && meta.touched && (
                          <Box
                            component="span"
                            color="error.main"
                          >
                            {meta.error || meta.submitError}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Field>
                  <DialogActions class={classes.p0}>
                    <Button
                      className={classes.submitBtn}
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      disableRipple
                    >
                      Send
                    </Button>
                    {loading
                    && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </DialogActions>
                </DialogContent>
              </form>
            )}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

AddComplianceOfficer.propTypes = {
  interviewId: PropTypes.string.isRequired,
};

export default AddComplianceOfficer;
