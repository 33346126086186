/* eslint-disable max-len */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { useMutation } from '@apollo/client';
import { Form } from 'react-final-form';
import { RESET_PASSWORD_MUTATION } from '../../queries/account';
import { FormTextField } from '../../components/FormFields';
import SubmitButton from '../../components/SubmitButton/SubmitButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexGrow: '1',
  },
  logBox: {
    position: 'relative',
    maxWidth: '540px',
    width: '100%',
    padding: '20px',
  },
  loginFormText: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  svgImageWrap: {
    width: '100%',
    '& svg': {
      width: '100%',
      height: 'auto',
      color: 'transparent',
    },
  },
  submitBtn: {
    borderRadius: '0',
    letterSpacing: 'normal',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    height: '40px',
    padding: '0 30px',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.dark,
    },
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const [resetPassword, {
    data, error, loading,
  }] = useMutation(RESET_PASSWORD_MUTATION, { errorPolicy: 'all' });

  const onSubmit = (values) => {
    resetPassword({ variables: values });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.logBox}>
        <Box
          p={{ xs: '20px', md: '50px' }}
          bgcolor="background.paper"
        >
          <Box className={classes.loginFormText}>
            {(data && !error && !data.errors)
              ? (
                <Box>
                  <Box mb="20px">
                    We&apos;ve emailed you instructions for setting your password,
                    if an account exists with the email you entered. You should receive them shortly.
                  </Box>
                  <Box>
                    If you don&apos;t receive an email,
                    please make sure you&apos;ve entered the address you registered with,
                    and check your spam folder.
                  </Box>
                </Box>
              )
              : (
                <Box>
                  Forgotten your password? Enter your email address below,
                  and we&apos;ll email instructions for setting a new one.
                </Box>
              )}
          </Box>
          {(data && !error && !data.errors)
            ? (
              <Box className={classes.svgImageWrap}>
                <svg width="380" height="249" viewBox="0 0 380 249" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g cliPath="url(#clip0)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M147.266 81.2075H301.436C344.826 81.2075 380 116.382 380 159.772C380 203.161 344.826 238.336 301.436 238.336H42.0762V186.398C42.0762 128.303 89.1715 81.2075 147.266 81.2075Z" fill="#F4F6FA" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M53.9101 31.8994H23.6679C10.5965 31.8994 4.57764e-05 42.4959 4.57764e-05 55.5672C4.57764e-05 68.6386 10.5965 79.2351 23.6679 79.2351H101.246C101.246 53.0923 80.0528 31.8994 53.9101 31.8994Z" fill="#F4F6FA" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M106.233 119.775L112.636 114.897C115.327 112.847 118.616 111.737 121.999 111.737H191.815C198.233 111.737 203.983 115.705 206.26 121.705L206.919 123.442L106.233 119.775Z" fill="white" stroke="#18233D" strokeLinecap="round" />
                    <path d="M201.834 117.367H110.45C106.819 117.367 103.875 120.31 103.875 123.941V169.962C103.875 173.593 106.819 176.536 110.45 176.536H201.834C205.465 176.536 208.408 173.593 208.408 169.962V123.941C208.408 120.31 205.465 117.367 201.834 117.367Z" fill="#D7E3FF" />
                    <path d="M287.823 85.7143C284.145 84.8652 282.289 79.3058 283.676 73.2969C285.063 67.2881 289.169 63.1052 292.847 63.9543C296.525 64.8034 298.382 70.3628 296.994 76.3717C295.607 82.3806 291.501 86.5634 287.823 85.7143Z" fill="#D7E3FF" />
                    <path d="M293.09 64.1641L284.744 97.0232L295.529 212.222" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M278.168 112.623C273.285 115.33 265.751 111.071 261.339 103.112C256.927 95.153 257.309 86.5069 262.191 83.8006C267.073 81.0943 274.608 85.3525 279.02 93.3117C283.431 101.271 283.05 109.917 278.168 112.623Z" fill="white" />
                    <path d="M286.958 125.453L261.307 84.811" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M304.539 197.144C299.235 195.421 297.216 187.001 300.031 178.339C302.845 169.677 309.427 164.053 314.731 165.776C320.036 167.5 322.055 175.919 319.24 184.581C316.426 193.243 309.844 198.868 304.539 197.144Z" fill="white" />
                    <path d="M291.631 140.336C286.141 139.368 282.972 131.309 284.554 122.336C286.137 113.363 291.87 106.874 297.361 107.842C302.851 108.81 306.02 116.869 304.437 125.842C302.855 134.815 297.122 141.304 291.631 140.336Z" fill="white" />
                    <path d="M283.903 185.04C278.806 187.309 271.672 182.407 267.969 174.09C264.266 165.773 265.396 157.191 270.494 154.922C275.591 152.652 282.725 157.555 286.428 165.871C290.131 174.188 289.001 182.77 283.903 185.04Z" fill="white" />
                    <path d="M297.344 107.839L290.335 156.99" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M294.496 202.495L270.179 155.598" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M297.344 228.983L314.704 166.783" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M221.557 1.65723H71.0035C55.0274 1.65723 42.0762 14.6084 42.0762 30.5846C42.0762 46.5607 55.0274 59.5119 71.0035 59.5119H221.557C237.533 59.5119 250.484 46.5607 250.484 30.5846C250.484 14.6084 237.533 1.65723 221.557 1.65723Z" fill="#DBE2EF" />
                    <path d="M232.076 1.32861H81.5225C65.3649 1.32861 52.2665 14.427 52.2665 30.5847C52.2665 46.7423 65.3649 59.8407 81.5225 59.8407H232.076C248.234 59.8407 261.332 46.7423 261.332 30.5847C261.332 14.427 248.234 1.32861 232.076 1.32861Z" fill="white" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M242.842 22.7803H77.3571V38.3892H242.842V22.7803Z" fill="#F4F6FA" />
                    <path d="M187.649 25.2188H77.3571V35.4621H187.649V25.2188Z" fill="#88CD00" />
                    <path d="M80.7441 46.0344C89.21 46.0344 96.073 39.1173 96.073 30.5846C96.073 22.0519 89.21 15.1348 80.7441 15.1348C72.2782 15.1348 65.4153 22.0519 65.4153 30.5846C65.4153 39.1173 72.2782 46.0344 80.7441 46.0344Z" fill="white" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M71.144 25.6467L77.7493 34.3164L94.7236 17.2544" stroke="#88CD00" strokeWidth="8" />
                    <path d="M160.1 46.0344C168.566 46.0344 175.429 39.1173 175.429 30.5846C175.429 22.0519 168.566 15.1348 160.1 15.1348C151.634 15.1348 144.771 22.0519 144.771 30.5846C144.771 39.1173 151.634 46.0344 160.1 46.0344Z" fill="white" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M151.12 25.3147L157.725 33.9844L174.7 16.9224" stroke="#88CD00" strokeWidth="8" />
                    <path d="M232.197 46.0344C240.663 46.0344 247.526 39.1173 247.526 30.5846C247.526 22.0519 240.663 15.1348 232.197 15.1348C223.731 15.1348 216.868 22.0519 216.868 30.5846C216.868 39.1173 223.731 46.0344 232.197 46.0344Z" fill="white" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M343.512 237.56H25.3115V248.079H343.512V237.56Z" fill="white" stroke="#182341" strokeWidth="2" />
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="24" y="174" width="321" height="63">
                      <path d="M67.3324 174.117H301.491L344.5 236.5H24.5L67.3324 174.117Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0)">
                      <path d="M67.3324 174.117H301.491L344.5 236.5H24.5L67.3324 174.117Z" fill="#D7E3FF" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M111.642 201.208C117.986 206.002 142.257 195.49 140.616 192.027C138.975 188.563 127.185 194.365 120.579 194.365C113.973 194.365 105.299 196.413 111.642 201.208Z" stroke="white" />
                      <path opacity="0.218797" fillRule="evenodd" clipRule="evenodd" d="M160.405 208.303L178.211 237.888H262.159L236.35 206.002L160.405 208.303Z" fill="url(#paint0_linear)" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M201.177 199.417C210.578 200.769 235.931 194.062 252.871 189.02C269.81 183.979 242.79 177.336 217.816 183.979C192.843 190.622 191.775 198.065 201.177 199.417Z" stroke="white" />
                      <path d="M40.7614 192.642C57.5414 204.368 76.5573 205.447 97.8092 195.879C129.687 181.526 160.153 177.082 155.286 188.414C150.419 199.746 112.801 206.009 112.801 225.489C112.801 244.968 219.403 196.915 232.746 240.038" stroke="white" />
                      <path d="M215.57 170.501C213.344 170.501 151.941 196.438 177.954 206.003C195.296 212.379 242.212 205.602 318.702 185.672C372.64 203.321 381.02 215.286 343.841 221.568C288.072 230.99 249.205 213.499 282.493 255.115C315.781 296.731 259.954 257.997 259.954 242.819C259.954 227.641 270.694 213.952 318.702 213.952C366.709 213.952 342.534 191.956 324.589 196.655C306.645 201.353 322.001 208.873 295.535 208.873C269.07 208.873 177.226 219.032 160.405 213.952C143.583 208.873 153.614 202.716 190.403 172.145" stroke="white" />
                      <path d="M30.4128 212.861C40.1432 215.158 58.4206 214.029 85.245 209.475C125.482 202.642 78.7634 237.256 117.024 240.038" stroke="white" />
                      <path d="M54.9834 186.612C64.4773 192.746 80.6488 191.868 103.498 183.978C126.347 176.089 146.214 171.597 163.099 170.501" stroke="white" />
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M63.1143 182.768L87.9738 215.64H139.377L109.166 182.768H63.1143Z" fill="white" />
                    <path d="M75.6056 187.822L98.616 187.576" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M84.1523 196.369L107.163 196.122" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M87.4395 200.314L110.45 200.067" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M99.2734 194.287L99.2734 227.817H150.554L145.135 194.287H99.2734Z" fill="#F1F5FF" />
                    <path d="M105.19 220.256H136.09" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M105.19 215.654H118.997" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M104.533 208.422H124.914" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M105.19 203.82H113.737" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M114.697 130.449C126.012 124.45 138.262 121.78 149.996 121.78C161.73 121.78 177.504 124.711 189.903 132.402C198.169 137.529 204.917 151.402 210.147 174.021H110.49C99.946 179.037 91.8328 180.176 86.15 177.437C80.4672 174.699 78.4709 168.689 80.1612 159.407C95.6411 144.101 107.153 134.449 114.697 130.449Z" fill="#1168A7" />
                    <path d="M152.855 146.504C153.762 146.504 154.498 145.769 154.498 144.861C154.498 143.953 153.762 143.217 152.855 143.217C151.947 143.217 151.211 143.953 151.211 144.861C151.211 145.769 151.947 146.504 152.855 146.504Z" fill="#E5E9F3" />
                    <path d="M152.855 159.653C153.762 159.653 154.498 158.918 154.498 158.01C154.498 157.102 153.762 156.366 152.855 156.366C151.947 156.366 151.211 157.102 151.211 158.01C151.211 158.918 151.947 159.653 152.855 159.653Z" fill="#E5E9F3" />
                    <path d="M152.855 172.145C153.762 172.145 154.498 171.409 154.498 170.501C154.498 169.593 153.762 168.857 152.855 168.857C151.947 168.857 151.211 169.593 151.211 170.501C151.211 171.409 151.947 172.145 152.855 172.145Z" fill="#E5E9F3" />
                    <path opacity="0.218797" fillRule="evenodd" clipRule="evenodd" d="M110.49 174.021L125.482 157.593L121.564 173.92L110.49 174.021Z" fill="#4873DB" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M80.3642 155.451C75.7666 161.131 75.0073 168.193 80.3642 175.03C85.7211 181.866 95.1686 184.433 102.246 176.372C109.323 168.31 102.807 158.392 97.7166 153.526C92.6265 148.659 84.9619 149.77 80.3642 155.451Z" fill="#182341" />
                    <path d="M149.239 141.574L148.144 174.021" stroke="white" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M140.843 120.147C138.12 121.024 134.504 121.669 133.808 125.206C133.344 127.565 133.344 134.007 133.808 144.532L149.525 138.65L164.938 143.618C165.24 130.823 164.679 123.578 163.257 121.883C161.836 120.187 158.928 119.453 154.536 119.681C147.223 119.406 142.659 119.562 140.843 120.147Z" fill="white" />
                    <path d="M155.285 72.0869C161.386 72.0869 166.332 67.1114 166.332 60.9739C166.332 54.8363 161.386 49.8608 155.285 49.8608C149.184 49.8608 144.238 54.8363 144.238 60.9739C144.238 67.1114 149.184 72.0869 155.285 72.0869Z" fill="#182341" />
                    <path d="M153.938 80.2183C158.7 80.2183 162.56 76.335 162.56 71.5447C162.56 66.7544 158.7 62.8711 153.938 62.8711C149.176 62.8711 145.316 66.7544 145.316 71.5447C145.316 76.335 149.176 80.2183 153.938 80.2183Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M141.284 96.9651C142.62 102.008 142.954 107.025 142.286 112.015C141.284 119.501 139.903 121.246 137.771 122.16L149.268 142.56L161.482 121.617C157.464 121.338 155.454 118.355 155.454 112.67C155.454 106.984 155.854 100.684 156.655 93.771L141.284 96.9651Z" fill="#FFD1AB" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M136.857 94.297C132.688 87.8233 130.95 73.8811 140.028 71.1911C149.721 59.651 162.804 64.5645 165.433 74.371C168.062 84.1776 164.811 96.1934 157.202 97.8561C149.593 99.5188 141.027 100.771 136.857 94.297Z" fill="#182341" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M156.17 99.9336C156.03 101.048 155.861 103.571 155.581 108.025C155.346 111.763 155.424 114.46 155.813 116.116C147.907 112.528 143.493 109.694 142.57 107.615C142.634 105.454 142.566 103.49 142.367 101.721L156.17 99.9336Z" fill="#CF8B54" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M137.78 86.974C140.273 85.5195 142.351 82.3641 144.016 77.5078C143.045 82.3063 153.63 87.1288 161.527 87.9901C161.787 87.9113 162.062 87.869 162.347 87.869C163.953 87.869 165.254 89.2146 165.254 90.8745C165.254 92.5344 163.953 93.8801 162.347 93.8801C161.598 93.8801 160.916 93.5875 160.401 93.107C158.045 100.154 153.091 109.199 147.527 108.352C143.505 107.74 140.348 102.589 138.058 92.8986C137.86 92.9423 137.655 92.9653 137.445 92.9653C135.84 92.9653 134.538 91.6197 134.538 89.9598C134.538 88.2999 135.84 86.9542 137.445 86.9542C137.559 86.9542 137.67 86.9609 137.78 86.974Z" fill="#FFD1AB" />
                    <path d="M148.176 89.1567C148.556 90.6067 148.018 93.2549 146.56 97.1012L148.857 97.9211" stroke="#18233D" strokeLinecap="round" />
                    <path d="M151.411 86.7063C153.105 85.8912 154.824 86.0466 156.568 87.1726" stroke="#18233D" strokeLinecap="round" />
                    <path d="M141.021 86.966C142.621 85.9772 144.347 85.951 146.199 86.8875" stroke="#18233D" strokeLinecap="round" />
                    <path d="M154.86 91.3501C155.412 91.3501 155.86 90.9024 155.86 90.3501C155.86 89.7978 155.412 89.3501 154.86 89.3501C154.308 89.3501 153.86 89.7978 153.86 90.3501C153.86 90.9024 154.308 91.3501 154.86 91.3501Z" fill="#18233D" />
                    <path d="M142.16 91.3501C142.712 91.3501 143.16 90.9024 143.16 90.3501C143.16 89.7978 142.712 89.3501 142.16 89.3501C141.608 89.3501 141.16 89.7978 141.16 90.3501C141.16 90.9024 141.608 91.3501 142.16 91.3501Z" fill="#18233D" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M145.855 100.109L150.787 99.7339C150.32 101.398 149.498 102.23 148.321 102.23C147.144 102.23 146.322 101.523 145.855 100.109Z" fill="white" />
                    <path d="M161.482 91.6027L163.099 91.0605" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M137.771 90.5182L136.155 89.9761" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M146.932 75.8813C149.792 80.8746 154.282 83.9465 160.404 85.097" stroke="white" strokeWidth="2" strokeLinecap="round" />
                    <path d="M155.156 96.3285C158.424 96.3285 161.073 93.6794 161.073 90.4116C161.073 87.1437 158.424 84.4946 155.156 84.4946C151.888 84.4946 149.239 87.1437 149.239 90.4116C149.239 93.6794 151.888 96.3285 155.156 96.3285Z" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M141.349 96.3285C144.617 96.3285 147.266 93.6794 147.266 90.4116C147.266 87.1437 144.617 84.4946 141.349 84.4946C138.082 84.4946 135.432 87.1437 135.432 90.4116C135.432 93.6794 138.082 96.3285 141.349 96.3285Z" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M146.56 87.1727C147.582 86.5772 148.694 86.5772 149.896 87.1727" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M187.765 155.281C186.038 166.584 184.139 172.797 182.067 173.92" stroke="white" strokeLinecap="round" />
                    <path d="M120.312 188.576L132.251 211.022" stroke="#182341" strokeWidth="3" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M155.59 164.761L219.09 165.475L233.645 95.3423H178.042C173.197 95.3423 169.022 98.7532 168.056 103.501L155.59 164.761Z" fill="white" stroke="#182341" strokeWidth="2" />
                    <path d="M201.8 117.825L173.542 117.936" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M207.488 121.769L172.655 121.88" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M199.76 130.316L171.502 130.427" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M185.725 134.261L170.616 134.372" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M196.329 144.122L168.071 144.233" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M179.007 148.067L167.185 148.178" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M222.644 116.052H216.07L214.983 122.626H221.557L222.644 116.052Z" fill="#88CD00" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M219.357 129.201H212.783L211.696 135.775H218.27L219.357 129.201Z" fill="#88CD00" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M216.07 142.349H209.496L208.408 148.924H214.983L216.07 142.349Z" fill="#88CD00" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M178.166 95.0137C182.722 95.0137 186.521 98.4978 186.914 103.037L187.159 105.861H246.008C247.581 101.733 245.509 97.1111 241.381 95.538C240.471 95.1914 239.506 95.0137 238.533 95.0137L178.166 95.0137Z" fill="#1168A7" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M159.3 206.003H235.74L219.639 181.509L147.843 179.713V183.915L159.3 206.003Z" fill="white" stroke="#182341" strokeWidth="2" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M160.439 204.03H235.745L219.882 179.542H148.048L160.439 204.03Z" fill="white" stroke="#182341" strokeWidth="2" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M168.64 158.667L159.449 206.261L160.588 207.97L235.758 206.009L246.252 160.375L245.114 158.667H168.64Z" fill="white" stroke="#182341" strokeWidth="2" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M169.684 160.311H246.604L237.336 208.304H160.415L169.684 160.311Z" fill="#1168A7" />
                    <path d="M204.135 187.923C206.132 187.923 207.751 186.304 207.751 184.307C207.751 182.31 206.132 180.691 204.135 180.691C202.138 180.691 200.519 182.31 200.519 184.307C200.519 186.304 202.138 187.923 204.135 187.923Z" fill="white" />
                    <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="74" y="157" width="57" height="57">
                      <path d="M95.7442 172.144C93.7141 161.342 89.8501 156.56 84.1523 157.797C75.6055 159.653 73.031 170.508 75.6055 178.888C77.3219 184.474 87.8409 194.319 107.163 208.422C113.37 211.788 118.765 213.471 123.348 213.471C127.931 213.471 130.285 210.72 130.41 205.218C131.619 203.409 130.745 201.534 127.786 199.593C124.827 197.652 120.144 197.652 113.737 199.593L94.7236 175.53L95.7442 172.144Z" fill="white" />
                    </mask>
                    <g mask="url(#mask1)">
                      <path d="M95.7442 172.144C93.7141 161.342 89.8501 156.56 84.1523 157.797C75.6055 159.653 73.031 170.508 75.6055 178.888C77.3219 184.474 87.8409 194.319 107.163 208.422C113.37 211.788 118.765 213.471 123.348 213.471C127.931 213.471 130.285 210.72 130.41 205.218C131.619 203.409 130.745 201.534 127.786 199.593C124.827 197.652 120.144 197.652 113.737 199.593L94.7236 175.53L95.7442 172.144Z" fill="#FFD1AB" />
                      <path d="M94.7236 175.53C92.059 171.233 89.6309 167.992 87.4395 165.807" stroke="white" strokeLinecap="round" />
                      <path d="M129.988 210.065C129.575 210.065 127.491 212.022 122.284 211.369" stroke="white" strokeLinecap="round" />
                      <path d="M130.742 208.209C127.424 208.776 124.824 208.776 122.941 208.209" stroke="white" strokeLinecap="round" />
                      <path d="M130.719 206.26C127.354 206.242 124.793 205.791 123.037 204.905" stroke="white" strokeLinecap="round" />
                    </g>
                    <path opacity="0.218797" fillRule="evenodd" clipRule="evenodd" d="M265.606 190.389L277.679 208.304H297.57L283.949 189.758L265.606 190.389Z" fill="url(#paint1_linear)" />
                    <path d="M263.633 167.99L265.491 189.549C267.793 191.831 270.865 192.972 274.707 192.972C278.549 192.972 281.63 191.901 283.949 189.758L285.986 167.99H263.633Z" fill="#1168A7" />
                    <path d="M264.783 173.25C261.383 173.384 259.486 175.143 259.092 178.525C258.501 183.598 262.355 185.741 265.606 185.741" stroke="#1168A7" strokeWidth="4" />
                    <path d="M274.81 169.633C280.801 169.633 285.658 168.898 285.658 167.99C285.658 167.082 280.801 166.346 274.81 166.346C268.819 166.346 263.962 167.082 263.962 167.99C263.962 168.898 268.819 169.633 274.81 169.633Z" fill="white" stroke="#182341" strokeWidth="2" />
                    <path d="M120.312 188.576L127.237 201.848" stroke="#182341" strokeWidth="3" strokeLinecap="round" />
                    <path d="M129.944 204.356C129.471 204.356 125.43 201.472 123.599 200.204" stroke="white" strokeLinecap="round" />
                  </g>
                  <defs>
                    <linearGradient id="paint0_linear" x1="211.282" y1="220.38" x2="211.918" y2="224.8" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#7EABEF" />
                      <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="281.588" y1="195.909" x2="282.172" y2="203.426" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#7EABEF" />
                      <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                    <clipPath id="clip0">
                      <rect width="380" height="249" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>
            )
            : (
              <Box className={classes.svgImageWrap} mb="16px">
                <svg width="380" height="294" viewBox="0 0 380 294" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M104.431 26.793H55.2661C24.7435 26.793 -3.05176e-05 51.5949 -3.05176e-05 82.1895C-3.05176e-05 112.784 24.7435 137.586 55.2661 137.586H208.862L208.862 131.471C208.862 73.6587 162.107 26.793 104.431 26.793Z" fill="#F4F6FA" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M255.019 147H316.426C351.537 147 380 175.53 380 210.724C380 245.918 351.537 274.448 316.426 274.448H139.43V262.862C139.43 198.873 191.181 147 255.019 147Z" fill="#F4F6FA" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M140.875 0.724121H164.354C174.129 0.724121 182.053 8.66721 182.053 18.4655C182.053 28.2638 174.129 36.2069 164.354 36.2069H105.475C105.475 16.6103 121.324 0.724121 140.875 0.724121Z" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M129.316 21.724C131.31 21.724 132.928 20.103 132.928 18.1034C132.928 16.1037 131.31 14.4827 129.316 14.4827C127.321 14.4827 125.703 16.1037 125.703 18.1034C125.703 20.103 127.321 21.724 129.316 21.724Z" fill="#182341" />
                    <path d="M145.209 21.724C147.204 21.724 148.821 20.103 148.821 18.1034C148.821 16.1037 147.204 14.4827 145.209 14.4827C143.214 14.4827 141.597 16.1037 141.597 18.1034C141.597 20.103 143.214 21.724 145.209 21.724Z" fill="#182341" />
                    <path d="M161.103 21.724C163.098 21.724 164.715 20.103 164.715 18.1034C164.715 16.1037 163.098 14.4827 161.103 14.4827C159.108 14.4827 157.49 16.1037 157.49 18.1034C157.49 20.103 159.108 21.724 161.103 21.724Z" fill="#182341" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M70.7401 86.689C79.8257 90.1179 88.1284 90.4863 95.6482 87.7942L107.941 106.816C90.3457 108.633 77.1708 106.194 68.4161 99.4993L70.7401 86.689Z" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M53.958 63L93.0522 65.7981C79.888 83.2331 74.292 96.2135 76.2643 104.739C63.819 99.6581 56.1612 93.4396 53.2907 86.0836C50.4203 78.7276 50.6427 71.0331 53.958 63Z" fill="white" />
                    <path d="M56.3577 71.2322L78.5423 73.6014" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M54.9128 77.7493L70.5956 80.1185" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M90.8042 92.7593L97.002 103.13" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M84.3022 92.7593L87.6103 98.7854" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M314.258 47.0681H148.821C131.266 47.0681 117.034 61.3332 117.034 78.9302C117.034 96.5271 131.266 110.792 148.821 110.792H314.258C331.814 110.792 346.046 96.5271 346.046 78.9302C346.046 61.3332 331.814 47.0681 314.258 47.0681Z" fill="#DBE2EF" />
                    <path d="M325.818 46.7068H160.38C142.625 46.7068 128.232 61.134 128.232 78.9309C128.232 96.7278 142.625 111.155 160.38 111.155H325.818C343.573 111.155 357.966 96.7278 357.966 78.9309C357.966 61.134 343.573 46.7068 325.818 46.7068Z" fill="white" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M337.648 70.3345H155.803V87.5269H337.648V70.3345Z" fill="#F4F6FA" />
                    <path d="M159.525 95.9481C168.828 95.9481 176.369 88.3292 176.369 78.9308C176.369 69.5325 168.828 61.9136 159.525 61.9136C150.222 61.9136 142.681 69.5325 142.681 78.9308C142.681 88.3292 150.222 95.9481 159.525 95.9481Z" fill="white" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M148.976 73.4918L156.234 83.0411L174.886 64.248" stroke="#88CD00" strokeWidth="8" />
                    <path d="M246.725 95.9481C256.028 95.9481 263.57 88.3292 263.57 78.9308C263.57 69.5325 256.028 61.9136 246.725 61.9136C237.423 61.9136 229.881 69.5325 229.881 78.9308C229.881 88.3292 237.423 95.9481 246.725 95.9481Z" fill="white" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M325.95 95.9481C335.253 95.9481 342.795 88.3292 342.795 78.9308C342.795 69.5325 335.253 61.9136 325.95 61.9136C316.648 61.9136 309.106 69.5325 309.106 78.9308C309.106 88.3292 316.648 95.9481 325.95 95.9481Z" fill="white" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M211.846 152.634C221.19 152.263 228.967 152.263 235.175 152.634C241.383 153.004 251.369 151.773 265.133 148.941L268.745 173.872L243.519 189.356C239.967 213.391 234.836 227.01 228.126 230.215H174.523C170.454 227.44 166.537 214.051 162.77 190.049H128.593L140.054 164.783C151.106 162.438 159.614 160.261 165.578 158.252C171.541 156.243 183.364 153.574 201.046 150.246L211.846 152.634Z" fill="#182341" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M209.867 150.608L216.399 153.619V166.788L207.915 158.633L197.204 157.791L185.765 164.867L187.132 153.642L194.544 150.608H209.867Z" stroke="white" />
                    <path d="M200.14 155.601H205.218V178.176H200.14V155.601Z" stroke="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M212.444 127.333C211.153 132.283 210.831 137.208 211.476 142.107C212.444 149.456 212.444 152.304 214.009 153.479L205.823 163.904L199.072 164.783L191.093 153.479C193.39 152.427 194.539 149.111 194.539 143.529C194.539 137.948 194.383 131.761 194.07 124.968L212.444 127.333Z" fill="#FFD1AB" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M214.892 126.127C218.921 119.772 220.171 107.789 211.397 105.149C210.802 96.114 190.835 100.019 188.294 109.646C185.754 119.273 190.981 132.903 198.334 134.535C205.688 136.167 210.862 132.482 214.892 126.127Z" fill="#182341" />
                    <path d="M210.951 109.345C217.214 111.419 220.974 111.419 222.232 109.345" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M188.914 103.794C190.316 110.229 189.233 113.776 185.665 114.437" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M199.828 97.0344C197.724 104.131 195.411 107.197 192.89 106.233" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M216.748 102.828C215.196 105.962 213.263 107.529 210.951 107.529" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M210.488 105.588C210.471 101.991 209.422 99.8641 207.338 99.2068" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M194.07 129.684C194.22 130.903 194.73 137.957 194.573 143.836C194.537 145.168 194.375 146.721 194.085 148.493C204.569 143.47 210.256 139.938 211.147 137.897C211.086 135.776 211.205 133.738 211.397 132.001L194.07 129.684Z" fill="#CF8B54" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M212.678 118.652C210.269 117.225 209.582 114.413 207.973 109.646C203.813 116.394 199.85 116.964 193.049 118.641C193.491 130.859 192.283 119.799 191.05 119.936C190.798 119.858 190.533 119.817 190.257 119.817C188.706 119.817 187.448 121.138 187.448 122.767C187.448 124.397 188.706 125.718 190.257 125.718C190.981 125.718 191.641 125.431 192.139 124.959C194.415 131.877 199.202 140.755 204.58 139.925C208.467 139.324 211.518 134.267 213.732 124.754C213.923 124.797 214.121 124.82 214.324 124.82C215.875 124.82 217.133 123.499 217.133 121.869C217.133 120.24 215.875 118.919 214.324 118.919C214.214 118.919 214.238 123.664 213.732 123.482C213.394 123.36 213.043 121.75 212.678 118.652Z" fill="#FFD1AB" />
                    <path d="M205.735 107.897C207.522 114.261 209.625 117.386 212.044 117.271" stroke="#18233D" strokeLinecap="round" />
                    <path d="M203.953 121.081C203.585 122.504 204.106 125.104 205.514 128.879L203.295 129.684" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M200.602 118.594C199.424 120.006 197.846 120.559 195.867 120.251" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M210.641 120.739C208.827 120.724 207.399 119.841 206.358 118.091" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M198.426 124.289C197.874 124.289 197.426 123.841 197.426 123.289C197.426 122.737 197.874 122.289 198.426 122.289C198.978 122.289 199.426 122.737 199.426 123.289C199.426 123.841 198.978 124.289 198.426 124.289Z" fill="#182341" />
                    <path d="M207.801 124.289C207.248 124.289 206.801 123.841 206.801 123.289C206.801 122.737 207.248 122.289 207.801 122.289C208.353 122.289 208.801 122.737 208.801 123.289C208.801 123.841 208.353 124.289 207.801 124.289Z" fill="#182341" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M205.864 132.653C205.26 132.033 202.033 130.276 201.43 131.464C200.827 132.653 202.107 133.914 203.813 133.914C205.519 133.914 206.467 133.273 205.864 132.653Z" fill="white" />
                    <path d="M191.093 123.482L189.531 122.95" stroke="#182341" strokeLinecap="round" />
                    <path d="M214.009 122.418L215.571 121.885" stroke="#18233D" strokeLinecap="round" />
                    <path d="M205.155 108.049C202.392 112.951 198.052 115.967 192.135 117.096" stroke="white" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M143.769 184.594C139.675 192.147 136.165 194.128 126.34 191.84C116.514 189.552 118.168 168.272 123.239 164.802C128.309 161.331 136.144 155.577 142.889 161.313C149.634 167.048 147.863 177.042 143.769 184.594Z" fill="#1168A7" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M279.034 171.62C275.1 176.33 274.815 180.77 260.746 179.632C253.467 179.043 250.748 173.314 249.755 167.637C248.83 162.342 244.263 157.36 247.437 155.604C254.016 151.966 264.123 145.989 274.391 150.554C284.659 155.119 282.967 166.91 279.034 171.62Z" fill="#1168A7" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M276.045 158.553C282.755 163.09 275.795 176.879 262.849 173.347C249.903 169.814 225.295 144.962 218.222 136.332C211.15 127.701 215.405 124.698 214.791 121.398C214.688 120.846 214.309 117.987 213.654 112.821L213.654 112.821C213.59 112.313 213.855 111.821 214.313 111.596C214.7 111.407 215.167 111.567 215.356 111.955C215.366 111.976 215.376 111.998 215.384 112.019L218.13 119.216L215.909 108.562C215.76 107.849 216.147 107.133 216.824 106.868C217.451 106.622 218.159 106.933 218.404 107.562C218.408 107.574 218.413 107.586 218.417 107.598L221.578 116.534L225.566 128.205C254.745 145.413 271.572 155.529 276.045 158.553Z" fill="#FFD1AB" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M119.434 172.357C121.802 180.116 126.494 189.308 134.441 186.874C142.388 184.439 142.297 169.303 134.959 152.672C127.622 136.042 121.295 119.735 115.128 110.431C108.962 101.127 104.855 104.327 101.886 102.773C101.39 102.513 98.7732 101.314 94.0363 99.1741L94.0363 99.1741C93.5711 98.964 93.0243 99.0739 92.6761 99.4475C92.3822 99.7629 92.399 100.258 92.7136 100.552C92.7307 100.568 92.7484 100.584 92.7667 100.598L98.8294 105.335L89.3153 100.089C88.6787 99.7384 87.8825 99.8996 87.4321 100.471C87.0145 101 87.1043 101.769 87.6326 102.188C87.6427 102.196 87.6529 102.204 87.6632 102.212L95.2642 107.856L84.1997 103.082C83.7021 102.867 83.1254 103.098 82.9115 103.596C82.9051 103.611 82.8991 103.626 82.8935 103.641C82.6758 104.227 82.8717 104.886 83.3735 105.257C85.7185 106.989 89.1577 108.963 93.6911 111.177C95.4447 112.034 99.2915 113.339 105.232 115.093C113.122 148.096 117.856 167.184 119.434 172.357Z" fill="#FFD1AB" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M230.341 225.871V229.847H187.486C187.67 227.621 189.531 225.871 191.8 225.871H230.341Z" fill="white" stroke="#18233D" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M213.704 182.779L204.673 229.848L205.861 229.848L277.212 227.968L287.142 184.374L286.089 182.779H213.704Z" fill="white" stroke="#18233D" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M214.583 183.984H287.529L278.739 230.21H205.793L214.583 183.984Z" fill="#1168A7" />
                    <path d="M246.661 210.622C248.595 210.622 250.164 209.044 250.164 207.097C250.164 205.149 248.595 203.571 246.661 203.571C244.726 203.571 243.158 205.149 243.158 207.097C243.158 209.044 244.726 210.622 246.661 210.622Z" fill="white" />
                    <path d="M340.989 230.276H105.475V240.414H340.989V230.276Z" fill="#85A9FF" />
                    <path d="M361.217 230.276H174.106V240.414H361.217V230.276Z" fill="#D7E3FF" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M323.49 169.755L319.329 168.645C318.329 168.378 317.694 167.395 317.86 166.371C318.04 165.27 317.566 164.165 316.647 163.538L316.208 163.239C315.123 162.499 314.817 161.03 315.518 159.915C315.685 159.65 315.852 159.384 316.019 159.119C315.84 158.829 315.662 158.539 315.484 158.249C314.826 157.178 315.159 155.775 316.229 155.115C316.28 155.083 316.331 155.054 316.384 155.027L316.855 154.785C317.818 154.29 318.998 154.637 319.54 155.574L319.727 155.896C320.195 156.705 321.121 157.13 322.039 156.957C322.774 156.817 323.482 157.301 323.62 158.037C323.632 158.1 323.639 158.163 323.642 158.226L323.732 160.198C323.783 161.326 324.737 162.197 325.863 162.145C325.889 162.144 325.916 162.142 325.942 162.14C326.546 162.088 327.078 162.537 327.129 163.142C327.148 163.369 327.097 163.596 326.982 163.792L323.49 169.755Z" fill="#88CD00" />
                    <path d="M321.631 208.28L324.389 171.879L318.874 160.223" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M322.16 181.366V177.635C322.16 176.652 321.414 175.83 320.437 175.736C319.384 175.635 318.48 174.94 318.11 173.946L317.976 173.589C317.559 172.47 316.353 171.862 315.209 172.194C314.89 172.286 314.572 172.378 314.253 172.471C314.03 172.248 313.806 172.026 313.582 171.803C312.712 170.937 311.306 170.943 310.443 171.815C310.435 171.823 310.428 171.83 310.421 171.838L310.091 172.18C309.419 172.879 309.438 173.992 310.135 174.666C310.141 174.671 310.146 174.676 310.151 174.681L310.424 174.938C311.029 175.508 311.184 176.411 310.806 177.152C310.512 177.727 310.739 178.432 311.313 178.727C311.376 178.759 311.442 178.786 311.51 178.806L313.456 179.389C314.387 179.669 314.916 180.651 314.638 181.585C314.625 181.628 314.611 181.67 314.595 181.712C314.401 182.219 314.653 182.787 315.158 182.981C315.348 183.055 315.556 183.067 315.753 183.016L322.16 181.366Z" fill="#88CD00" />
                    <path d="M323.579 182.258L314.168 174.991" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M325.749 172.439V168.709C325.749 167.725 326.494 166.903 327.471 166.809C328.525 166.709 329.428 166.014 329.799 165.02L329.932 164.662C330.349 163.544 331.555 162.936 332.7 163.267C333.018 163.36 333.336 163.452 333.655 163.544C333.879 163.322 334.103 163.099 334.326 162.877C335.197 162.011 336.602 162.017 337.466 162.889C337.473 162.896 337.48 162.904 337.488 162.911L337.817 163.254C338.49 163.952 338.47 165.065 337.773 165.739C337.768 165.745 337.762 165.75 337.757 165.755L337.485 166.011C336.88 166.582 336.724 167.485 337.103 168.226C337.396 168.801 337.169 169.506 336.596 169.8C336.532 169.833 336.466 169.86 336.398 169.88L334.452 170.463C333.521 170.742 332.992 171.725 333.27 172.658C333.283 172.701 333.298 172.744 333.314 172.786C333.508 173.292 333.256 173.86 332.751 174.055C332.56 174.128 332.352 174.14 332.155 174.089L325.749 172.439Z" fill="#88CD00" />
                    <path d="M324.329 173.332L333.741 166.064" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M321.093 199.124V194.461C321.093 193.232 320.161 192.204 318.94 192.087C317.623 191.96 316.493 191.092 316.03 189.85L315.863 189.402C315.342 188.004 313.835 187.244 312.404 187.659C312.006 187.774 311.608 187.89 311.21 188.005C310.93 187.727 310.651 187.449 310.371 187.17C309.283 186.089 307.526 186.095 306.446 187.186C306.437 187.195 306.428 187.204 306.419 187.214L306.007 187.642C305.166 188.515 305.191 189.906 306.062 190.749C306.069 190.755 306.076 190.762 306.083 190.768L306.423 191.089C307.179 191.802 307.374 192.931 306.901 193.857C306.533 194.576 306.817 195.457 307.534 195.825C307.613 195.866 307.696 195.899 307.781 195.925L310.213 196.654C311.377 197.002 312.039 198.231 311.691 199.398C311.675 199.452 311.657 199.505 311.637 199.557C311.394 200.19 311.709 200.9 312.341 201.143C312.578 201.235 312.838 201.25 313.085 201.186L321.093 199.124Z" fill="#88CD00" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M325.084 192.486V187.823C325.084 186.594 326.016 185.566 327.236 185.449C328.553 185.323 329.683 184.454 330.146 183.212L330.313 182.764C330.834 181.366 332.342 180.607 333.772 181.021C334.17 181.136 334.568 181.252 334.966 181.367C335.246 181.089 335.526 180.811 335.806 180.533C336.894 179.451 338.651 179.458 339.73 180.548C339.739 180.557 339.748 180.566 339.757 180.576L340.169 181.004C341.01 181.877 340.985 183.268 340.114 184.111C340.107 184.118 340.101 184.124 340.094 184.13L339.754 184.451C338.998 185.164 338.803 186.293 339.276 187.219C339.643 187.938 339.36 188.819 338.642 189.187C338.563 189.228 338.481 189.261 338.395 189.287L335.963 190.016C334.799 190.364 334.138 191.593 334.486 192.76C334.502 192.814 334.52 192.867 334.54 192.919C334.783 193.552 334.467 194.262 333.836 194.505C333.598 194.597 333.338 194.612 333.092 194.549L325.084 192.486Z" fill="#88CD00" />
                    <path d="M322.77 194.585L337.388 183.49" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M322.16 199.575L308.858 189.798" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="301" y="205" width="40" height="25">
                      <path d="M301.255 205.384L308.247 229.874H335.649L340.189 205.384H301.255Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0)">
                      <path d="M301.255 205.384L308.247 229.874H335.649L340.189 205.384H301.255Z" fill="#1168A7" />
                      <path d="M303.915 215.495L305.277 216.178C307.684 217.385 310.538 217.279 312.85 215.898L313.903 215.269C315.533 214.295 317.57 214.323 319.172 215.341C320.806 216.379 322.847 216.538 324.621 215.767L324.839 215.672C326.768 214.835 328.974 214.934 330.819 215.941L331.349 216.23C333.427 217.364 335.989 217.09 337.781 215.541L339.165 214.345" stroke="white" />
                      <path d="M303.915 218.392L305.277 219.075C307.684 220.282 310.538 220.176 312.85 218.795L313.903 218.165C315.533 217.192 317.57 217.219 319.172 218.237C320.806 219.275 322.847 219.435 324.621 218.664L324.839 218.569C326.768 217.731 328.974 217.83 330.819 218.838L331.349 219.127C333.427 220.261 335.989 219.987 337.781 218.438L339.165 217.242" stroke="white" />
                    </g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M190.722 231.616C201.904 226.314 210.082 218.626 215.256 208.552L234.791 231.616C226.138 243.671 215.817 250.225 203.829 251.276L190.722 231.616Z" fill="white" />
                    <path d="M215.285 217.241L225.399 230.276" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M211.673 221.586L217.904 229.83" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M208.783 225.207L217.182 235.798" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M204.449 228.828L206.929 232.19" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M340.456 274.487L327.701 293.62C315.99 292.527 305.888 286.115 297.379 274.359L316.383 251.92C321.546 261.719 329.574 269.243 340.456 274.487L340.456 274.487Z" stroke="#182341" strokeWidth="2" />
                    <path d="M316.426 259.966L306.312 273" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M320.038 264.31L313.807 272.554" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M322.928 267.931L314.529 278.522" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                    <path d="M327.262 271.552L324.782 274.914" stroke="#182341" strokeWidth="2" strokeLinecap="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="380" height="294" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </Box>
            )}
          {(data && !error && !data.errors)
            ? ''
            : (
              <Form
                onSubmit={onSubmit}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = 'Required';
                  }
                  return errors;
                }}
                render={({
                  handleSubmit,
                  submitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box mb="20px">
                      <FormTextField
                        fieldName="email"
                        fieldType="email"
                        label="Email"
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                    <SubmitButton
                      loading={loading}
                      submitting={submitting}
                      className={classes.submitBtn}
                    >
                      Reset Password
                    </SubmitButton>
                  </form>
                )}
              />
            )}
        </Box>
      </Box>
    </Box>
  );
};

ResetPassword.propTypes = {
};

export default withRouter(ResetPassword);
