/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  ALL_READING_LISTS_QUERY,
  CREATE_READING_LIST_MUTATION,
} from '../../../../../../../../../queries/interview/interview';
import useReadingListTitle from '../../../../../../../../../hooks/useReadingListTitle';

const CreateReadingListField = withStyles((theme) => ({
  root: {
    marginBottom: '10px',
    '& .MuiInputBase-root': {
      borderRadius: '5px',
      height: '30px',
      paddingRight: '0px',
      '& input': {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        paddingLeft: '10px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
      },
      '& button': {
        padding: '10px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& fieldset': {
        borderColor: theme.palette.info.main,
        borderStyle: 'dashed',
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.primary.main,
          },
        },
      },
      '&.Mui-disabled': {
        '& input': {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.default,
        },
        '& fieldset': {
          borderColor: theme.palette.info.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.info.main,
          },
        },
      },
    },
  },
}))(TextField);

const CreateReadingList = ({
  startAdornment, placeholder, handleAddInterview, ...style
}) => {
  // save input value from creation RL field
  const [inputValue, setInputValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [createReadingList] = useMutation(CREATE_READING_LIST_MUTATION, {
    onCompleted: (data) => {
      window.analytics.track('Transcript Saved');
      handleAddInterview(data.createReadingList.readingList.id)();
    },
  });

  // update values mentioned below
  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setInputValue(value);
  };

  const readingListTitle = useReadingListTitle();

  const handleCreate = () => {
    if (!inputValue) {
      enqueueSnackbar(`${readingListTitle} name is required!`, { variant: 'error', autoHideDuration: 4000 });
      return;
    }
    createReadingList({
      variables: {
        title: inputValue.trim(),
      },
      update: ((cache, { data }) => {
        const { allReadingList } = cache.readQuery({ query: ALL_READING_LISTS_QUERY });
        cache.writeQuery({
          query: ALL_READING_LISTS_QUERY,
          data: {
            allReadingList: {
              ...allReadingList,
              totalCount: allReadingList.totalCount + 1,
              results: [
                data.createReadingList.readingList,
                ...allReadingList.results,
              ],
            },
          },
        });
        setInputValue('');
      }),
    }).catch((res) => {
      const messages = res.graphQLErrors.map((el) => el.message).join(', ');
      enqueueSnackbar(messages, {
        variant: 'error',
        autoHideDuration: 4000,
      });
    });
  };

  const handleEnterCreate = (e) => {
    if (e.keyCode === 13) {
      handleCreate();
    } else {
      e.stopPropagation();
    }
  };

  return (
    <CreateReadingListField
      {...style}
      id="createReadingListName"
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      value={inputValue}
      onChange={handleChange}
      onKeyDown={handleEnterCreate}
      inputProps={{
        maxLength: 40,
      }}
      InputProps={{
        startAdornment,
        endAdornment: inputValue.length > 0 ? (
          <IconButton
            aria-label={`create ${readingListTitle.toLowerCase()}`}
            onClick={handleCreate}
            disableRipple
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM7.71 13.29C7.32 13.68 6.69 13.68 6.3 13.29L2.71 9.7C2.32 9.31 2.32 8.68 2.71 8.29C3.1 7.9 3.73 7.9 4.12 8.29L7 11.17L13.88 4.29C14.27 3.9 14.9 3.9 15.29 4.29C15.68 4.68 15.68 5.31 15.29 5.7L7.71 13.29Z" fill="#50A549" />
            </svg>
          </IconButton>
        ) : (
          <IconButton
            aria-label={`new ${readingListTitle}`}
            disableRipple
            disabled
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 0C1.35503 0 0 1.35503 0 3V15C0 16.645 1.35503 18 3 18H15C16.645 18 18 16.645 18 15V3C18 1.35503 16.645 0 15 0H3ZM3 2H15C15.565 2 16 2.43497 16 3V15C16 15.565 15.565 16 15 16H3C2.43497 16 2 15.565 2 15V3C2 2.43497 2.43497 2 3 2ZM8.98438 3.98633C8.7195 3.99047 8.46709 4.09953 8.28254 4.28957C8.09799 4.47962 7.99637 4.73512 8 5V8H5C4.86749 7.99813 4.73593 8.02261 4.61296 8.07202C4.48999 8.12143 4.37807 8.1948 4.2837 8.28784C4.18933 8.38088 4.11439 8.49176 4.06324 8.61401C4.0121 8.73627 3.98576 8.86747 3.98576 9C3.98576 9.13253 4.0121 9.26373 4.06324 9.38599C4.11439 9.50824 4.18933 9.61912 4.2837 9.71216C4.37807 9.8052 4.48999 9.87857 4.61296 9.92798C4.73593 9.97739 4.86749 10.0019 5 10H8V13C7.99813 13.1325 8.02261 13.2641 8.07202 13.387C8.12143 13.51 8.1948 13.6219 8.28784 13.7163C8.38088 13.8107 8.49176 13.8856 8.61401 13.9368C8.73627 13.9879 8.86747 14.0142 9 14.0142C9.13253 14.0142 9.26373 13.9879 9.38599 13.9368C9.50824 13.8856 9.61912 13.8107 9.71216 13.7163C9.8052 13.6219 9.87857 13.51 9.92798 13.387C9.97739 13.2641 10.0019 13.1325 10 13V10H13C13.1325 10.0019 13.2641 9.97739 13.387 9.92798C13.51 9.87857 13.6219 9.8052 13.7163 9.71216C13.8107 9.61912 13.8856 9.50824 13.9368 9.38599C13.9879 9.26373 14.0142 9.13253 14.0142 9C14.0142 8.86747 13.9879 8.73627 13.9368 8.61401C13.8856 8.49176 13.8107 8.38088 13.7163 8.28784C13.6219 8.1948 13.51 8.12143 13.387 8.07202C13.2641 8.02261 13.1325 7.99813 13 8H10V5C10.0018 4.86621 9.9768 4.73341 9.92638 4.60947C9.87597 4.48553 9.80119 4.37297 9.70649 4.27845C9.61179 4.18393 9.49908 4.10937 9.37504 4.05919C9.25101 4.00902 9.11816 3.98424 8.98438 3.98633Z" fill="#BFCCDC" />
            </svg>
          </IconButton>
        ),
      }}
    />
  );
};

CreateReadingList.defaultProps = {
  startAdornment: '',
  placeholder: '',
};

CreateReadingList.propTypes = {
  startAdornment: PropTypes.elementType,
  placeholder: PropTypes.string,
  handleAddInterview: PropTypes.func.isRequired,
};

export default CreateReadingList;
