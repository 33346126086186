// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
import React from 'react';
import './wdyr';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/styles';
import {
  ApolloClient, ApolloProvider, from, InMemoryCache,
} from '@apollo/client';
import { SingletonHooksContainer } from 'react-singleton-hook';
import { Provider } from 'react-redux';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/client/link/error';
import track from 'react-tracking';
import { SnackbarProvider } from 'notistack';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'; // choose your lib
import { LocalizationProvider } from '@material-ui/pickers';
import { createUploadLink } from 'apollo-upload-client';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import jwtDecode from 'jwt-decode';
import theme from './theme';
import './assets/scss/index.scss';
import { store, history } from './store';
import Routes from './Routes';
import { tokenName } from './config';
import { TRACK_ACTION_MUTATION } from './queries/auditlog';
import SentryFallback from './components/SentryFallback/SentryFallback';
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';
import OptimizelyHOC from './hocs/optimizelyProviderHoc';

const firebaseConfig = {
  apiKey: 'AIzaSyAx_u4TWhHwsp-4kfkYgTA4LBwX299SHRI',
  authDomain: 'stream-b57c5.firebaseapp.com',
  projectId: 'stream-b57c5',
  storageBucket: 'stream-b57c5.appspot.com',
  messagingSenderId: '101906532733',
  appId: '1:101906532733:web:477bb6c96632989c45a414',
  measurementId: 'G-8Z2DX0KBXC',
};

firebase.initializeApp(firebaseConfig);

// const httpLink = createHttpLink({
//   uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
// });

window.dataLayer = window.dataLayer || [];

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(tokenName) || '';
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

// eslint-disable-next-line no-unused-vars
const errorLink = onError(({ graphQLErrors, networkError }) => {
  let decoded;
  try {
    decoded = jwtDecode(localStorage.getItem(tokenName));
  } catch (e) {
    decoded = 'anonymous';
  }

  if (graphQLErrors) {
    const criticalErrors = [
      'Error decoding signature',
      'Signature has expired',
      'User is disabled',
      'Unauthorized',
    ];
    // eslint-disable-next-line no-unused-vars
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (criticalErrors.includes(message)) {
        window.localStorage.clear();
        const locationParams = `${window.location.pathname}${window.location.search}`;
        window.location.href = `/accounts/login/?next=${locationParams}`;
      }
    });
    Sentry.captureException(JSON.stringify({ email: decoded.email, graphQLErrors }));
  }
  if (networkError) {
    Sentry.captureException(JSON.stringify({ email: decoded.email, networkError }));
  }
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
});

const sentryLink = new SentryLink();

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  link: from([sentryLink, authLink, errorLink, uploadLink]),
  resolvers: {},
});

let prevPath = null;

// listen and notify Segment of client-side page updates
history.listen((location) => {
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
    window.analytics.page();
  }
});

const App = () => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <LocalizationProvider dateAdapter={DateFnsAdapter}>
        <ThemeProvider theme={theme}>
          <Sentry.ErrorBoundary fallback={SentryFallback}>
            <ConnectedRouter history={history}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <OptimizelyHOC>
                  <SingletonHooksContainer />
                  <Routes />
                </OptimizelyHOC>
              </SnackbarProvider>
            </ConnectedRouter>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </LocalizationProvider>
    </ApolloProvider>
  </Provider>
);

export default track(
  // app-level tracking data
  { token: localStorage.getItem(tokenName) || '' },

  // top-level options
  {
    // custom dispatch to console.log in addition to pushing to dataLayer[]
    dispatch: (data) => {
      client.mutate({
        mutation: TRACK_ACTION_MUTATION,
        variables: { input: { ...data } },
      });
    },
  },
)(Sentry.withProfiler(App));
