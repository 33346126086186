import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import FollowingItem from '../FollowingItem';
import {
  ALL_FOLLOWING_SECTORS_QUERY,
  DELETE_SECTOR_FOLLOWING,
  UPDATE_SECTOR_MUTATION,
} from '../../../../../../../../../../queries/interview/following';
import ItemsSkeleton from '../../../ItemsSkeleton';
import FollowingPlaceholder from '../FollowingPlaceholder';

const FollowingSectors = ({ filterDataSectors, handleFilterSelecion }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: {
      allSectorsWatchList = [],
    } = {},
    loading,
    updateQuery,
  } = useQuery(ALL_FOLLOWING_SECTORS_QUERY);

  const [deleteSector] = useMutation(DELETE_SECTOR_FOLLOWING, {
    refetchQueries: [{ query: ALL_FOLLOWING_SECTORS_QUERY }],
  });

  const [updateSector] = useMutation(UPDATE_SECTOR_MUTATION, {
    refetchQueries: [{ query: ALL_FOLLOWING_SECTORS_QUERY }],
  });

  const handleKeywordDelete = (followingId) => {
    deleteSector({ variables: { followingId } });
  };

  const handleSectorUpdate = (followingId, sectorId) => (params) => {
    updateSector({
      variables: {
        input: {
          id: followingId,
          sector: sectorId,
          ...params,
        },
      },
    }).then(({ data: { updateSectorMutation = {} } = {} }) => {
      if (!isEmpty(updateSectorMutation.errors)) {
        enqueueSnackbar(updateSectorMutation.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        enqueueSnackbar('Saved.',
          { variant: 'success', autoHideDuration: 4000 });
      }
    });
  };
  const isSectorActive = (id) => {
    if (filterDataSectors) {
      const active = filterDataSectors.filter((el) => el.id === id);
      return active.length > 0;
    }
    return false;
  };

  const handleSelecion = (item) => () => {
    handleFilterSelecion({
      sectors: [{
        id: item.sector.id,
        title: item.sector.title,
        uuid: item.sector.uuid,
      }],
    });
    updateQuery((previousQueryResult) => {
      const result = previousQueryResult.allSectorsWatchList.map((el) => {
        if (el.id === item.id) {
          return { ...el, notSeenInterviewsCount: 0 };
        }
        return el;
      });
      return {
        allSectorsWatchList: result,
      };
    });
  };

  return (
    <>
      <Fade in={loading}>
        <Box>
          {loading && <ItemsSkeleton />}
        </Box>
      </Fade>
      <Fade in={!loading}>
        <Box>
          {allSectorsWatchList.length > 0
            ? allSectorsWatchList.map((item) => (
              <FollowingItem
                key={item.id}
                data-xlink-keyword-id={item.sector.uuid}
                itemName={item.sector.title}
                isItemActive={isSectorActive(item.sector.id)}
                unreadCount={item.notSeenInterviewsCount}
                onDelete={() => handleKeywordDelete(item.id)}
                onUpdate={handleSectorUpdate(item.id, item.sector.id)}
                onClick={handleSelecion(item)}
                initials={{
                  notify: item.notify,
                  emailAlerts: item.emailAlerts,
                }}
              />
            ))
            : (
              <FollowingPlaceholder>
                Add sectors to your watchlist to know first about new interviews
              </FollowingPlaceholder>
            )}
        </Box>
      </Fade>
    </>
  );
};

FollowingSectors.propTypes = {
  filterDataSectors: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleFilterSelecion: PropTypes.func.isRequired,
};

export default FollowingSectors;
