import React from 'react';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { TagCloud } from 'react-tagcloud';
import { isEmpty } from 'lodash/lang';
import { useDispatch } from 'react-redux';
import { POPULAR_KEYWORDS_QUERY } from '../../../queries/interview/interview';
import { openCompanies } from '../../../actions/sidebarActions';
import { writeFilterData } from '../../../actions/filterActions';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: '34px',
    fontWeight: '500',
    lineHeight: '42px',
    color: theme.palette.text.primary,
    paddingBottom: '0',
  },
  sectionSubtitle: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '25px',
    color: theme.palette.text.primary,
    paddingBottom: '24px',
  },
  tagCloud: {
    margin: '0 -12px',
    '& .tag-cloud': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    '& .tag-cloud-tag': {
      color: theme.palette.primary.main,
      lineHeight: '42px',
      fontWeight: '400',
      margin: '0 12px !important',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
  tagCloudSkeleton: {
    backgroundColor: theme.palette.info.main,
    height: '16px',
    margin: '13px 12px',
    borderRadius: '5px',
  },
}));

const KeywordsArea = () => {
  const classes = useStyles();
  const TOP_N_KEYWORDS = 50;
  const PERIOD_DAYS_TOP_KEYWORDS = 60;
  const dispatch = useDispatch();
  const handleOpenCompanies = () => dispatch(openCompanies());
  const handleWriteFilterData = (data) => dispatch(writeFilterData(data));

  const {
    data: {
      popularKeywords = [],
    } = {}, loading,
  } = useQuery(POPULAR_KEYWORDS_QUERY, {
    variables: { first: TOP_N_KEYWORDS, days: PERIOD_DAYS_TOP_KEYWORDS },
  });

  const handleTagClick = (tag) => {
    handleOpenCompanies();
    handleWriteFilterData({ keywords: [tag] });
    window.scrollTo(0, 0);
  };

  const getTags = () => {
    if (!isEmpty(popularKeywords) && !loading) {
      return popularKeywords.map((item) => (
        { value: item.title, count: item.interviewsCount, id: item.id }
      ));
    }
    return [];
  };

  const customRenderer = (tag, size) => (
    <span
      key={tag.id}
      style={{ fontSize: `${size}px` }}
      className="tag-cloud-tag"
      data-track="discovery/filter-keywords"
    >
      {tag.value}
    </span>
  );

  const KeywordsSkeleton = () => {
    const items = [];
    const skeletonItem = () => (
      <>
        <Box className={classes.tagCloudSkeleton} width="100px" />
        <Box className={classes.tagCloudSkeleton} width="150px" />
        <Box className={classes.tagCloudSkeleton} width="200px" />
      </>
    );
    for (let i = 0; i < 6; i += 1) {
      items.push(skeletonItem());
    }
    return (
      <>{items}</>
    );
  };

  return (
    <>
      <Box className={classes.sectionTitle}>Popular Keywords</Box>
      <Box className={classes.sectionSubtitle}>Mentioned over the past 60 days</Box>
      <Box className={classes.tagCloud}>
        {!loading
          ? (
            <TagCloud
              minSize={16}
              maxSize={34}
              tags={getTags()}
              onClick={(tag) => handleTagClick({ title: tag.value, id: tag.id })}
              disableRandomColor
              renderer={customRenderer}
            />
          ) : (
            <Box className="tag-cloud">
              <KeywordsSkeleton />
            </Box>
          )}
      </Box>
    </>
  );
};

export default KeywordsArea;
