/* eslint-disable react/require-default-props */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import isEmpty from 'lodash/isEmpty';
// import isEqual from 'lodash/isEqual';
import { useMutation } from '@apollo/client';
import { formatDate } from '../../../../helpers/dateHelpers';
import { COMPLIANCE_ROLES } from '../../../../config';
import InterviewItemActions from './components/InterviewItemActions';
import InterviewItemProgress from './components/InterviewItemProgress';
import useAllIntervies from '../../../../hooks/useAllIntervies';
import {
  SET_INTERVIEW_IS_SEEN_MUTATION,
  SET_INTERVIEW_PERCENTS_READ_MUTATION,
} from '../../../../queries/interview/interview';
import useMe from '../../../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    position: 'relative',
    padding: '10px',
    paddingBottom: '5px',
    border: '2px solid',
    borderColor: theme.palette.background.paper,
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
    transition: 'border-color 250ms linear',
    marginBottom: '10px',
    '&:hover': {
      borderColor: theme.palette.info.main,
      cursor: 'pointer',
    },
    '&.active': {
      borderColor: theme.palette.primary.main,
    },
  },
  ticker: {
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    margin: '0 6px 5px 0',
    padding: '0',
    minWidth: '10px',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.dark,
    },
  },
  company: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    margin: '0 10px 5px 0',
  },
  date: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
  headline: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '23px',
    letterSpacing: 'normal',
  },
  headlineLink: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  title: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: 'normal',
  },
  titleLink: {
    color: theme.palette.text.primary,
    marginRight: '8px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  requestStatus: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '16px',
    marginBottom: '10px',
    alignItems: 'center!important',
    display: 'flex!important',
  },
  mr2: {
    marginRight: '3px',
  },
  requestStatusSpan: {
    fontWeight: '500',
    color: '#8293A6',
    marginLeft: '3px',
    marginRight: '3px',
  },
  mr5: {
    marginRight: '5px',
  },
  ml5: {
    marginLeft: '5px',
  },
  requestStatusComplianceSpan: {
    fontWeight: '500',
    color: '#182341',
    marginLeft: '5px',
    marginRight: '5px',
  },
  pipe: {
    fontWeight: '500',
    color: '#8293A6;',
    marginRight: '3px',
  },
  alignItemsRow: {
    display: 'flex',
    flexDirection: 'row',
  },

  printInterview: {
    padding: '2px',
    marginLeft: '12px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const InterviewItem = ({
  id,
  uuid,
  interviewId,
  readingTime,
  publishedAt,
  callDate,
  perspective,
  headline,
  relevantRole,
  tickers,
  isActive,
  isSeenInRl,
  className,
  accessRequest,
  accessRequests,
  readingListItem,
  isSelected,
  me,
  isUnread,
  percentsRead,
  userCanViewInterview,
  transcriptionStatus,
  handleWriteFilterData,
  ...rest
}) => {
  const classes = useStyles();
  const { updateQuery: updateAllInterviewsQuery } = useAllIntervies();
  const { refetch: refetchMe } = useMe();
  const [setInterviewPercents] = useMutation(SET_INTERVIEW_PERCENTS_READ_MUTATION);
  const [setIsSeen] = useMutation(SET_INTERVIEW_IS_SEEN_MUTATION, {
    update: (cache, { data }) => {
      refetchMe();
      updateAllInterviewsQuery((previousQueryResult) => {
        const rlItem = data.setInterviewIsSeen.readingListItem;
        const results = previousQueryResult.allInterviews.results.map((el) => {
          if (el.interviewId === rlItem.interview.interviewId) {
            return {
              ...el,
              readingListItem: rlItem,
              isSeenInRl: rlItem.isSeenInReadingList,
            };
          }
          return el;
        });

        return {
          allInterviews: {
            ...previousQueryResult.allInterviews,
            results,
          },
        };
      });
    },
  });

  const location = useLocation();
  const locationSearch = location.search;
  const history = useHistory();

  const numOfPending = () => {
    let num = 0;
    for (let i = 0; i < accessRequests.length; i += 1) {
      if (accessRequests[i].status === 'PENDING') {
        num += 1;
      }
    }
    if (num > 0) {
      return (num > 1 ? `${num} Pending Requests ` : `${num} Pending Request `);
    }
  };

  const numOfApproved = () => {
    let num = 0;
    for (let i = 0; i < accessRequests.length; i += 1) {
      if (accessRequests[i].status === 'APPROVED') {
        num += 1;
      }
    }

    if (num > 0) {
      return (`${num} Approved `);
    }
  };

  const numOfRejected = () => {
    let num = 0;
    for (let i = 0; i < accessRequests.length; i += 1) {
      if (accessRequests[i].status === 'REJECTED') {
        num += 1;
      }
    }
    if (num > 0) {
      return (`${num} Rejected`);
    }
  };

  const handleTickerClick = (ticker) => (event) => {
    event.stopPropagation();
    handleWriteFilterData({ tickers: [{ id: ticker.id, title: ticker.title, uuid: ticker.uuid }] });
  };

  const handleIconClick = () => (event) => {
    event.stopPropagation();
  };

  const handleReadingListItemSeenStatusUpdate = () => {
    if (!isEmpty(readingListItem) && !readingListItem.isSeenInReadingList) {
      // automatically when user opens interview
      setIsSeen({
        variables: {
          interviewId,
          isSeen: true,
        },
      });
    }
  };

  const handleMarkInterviewReadStatus = () => {
    if (id && percentsRead < 5) {
      setInterviewPercents({
        variables: {
          interviewPk: id,
          percentsRead: 5,
        },
        update: () => {
          updateAllInterviewsQuery((previousQueryResult) => {
            const results = previousQueryResult.allInterviews?.results.map((el) => {
              if (el.interviewId === interviewId) {
                return { ...el, percentsRead: 5 };
              }
              return el;
            });

            return {
              allInterviews: {
                ...previousQueryResult.allInterviews,
                results,
              },
            };
          });
        },
      });
    }
  };

  const handleInterviewItemClick = (e) => {
    handleMarkInterviewReadStatus();
    handleReadingListItemSeenStatusUpdate();
    if (e.ctrlKey || e.metaKey) {
      window.open(`/${interviewId}/fullscreen${locationSearch}`, '_blank');
      return;
    }
    history.push(`/${interviewId}${locationSearch}`);
  };

  return (
    <Box
      {...rest}
      onClick={handleInterviewItemClick}
      data-xlink-interview-id={uuid}
      className={clsx(
        classes.root,
        isActive && 'active',
        className,
      )}
    >
      <Box
        display="flex!important"
      >
        { (me.role === 'MEMBER')
          ? (
            <>
              <Box>
                {accessRequest.status === 'REJECTED'
                  ? (
                    <Box className={classes.requestStatus}>
                      <svg className={classes.mr2} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.99966 14.6032C11.6465 14.6032 14.6028 11.6468 14.6028 7.99999C14.6028 4.35316 11.6465 1.39682 7.99966 1.39682C4.35283 1.39682 1.39648 4.35316 1.39648 7.99999C1.39648 11.6468 4.35283 14.6032 7.99966 14.6032Z" stroke="#CC0000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 6L10 10" stroke="#CC0000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 10L10 6" stroke="#CC0000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <Box
                        display="flex!important"
                      >
                        <span className={classes.requestStatusSpan}>Declined by</span>
                        {accessRequest.statusChangedBy && accessRequest.statusChangedBy.fullName}
                        <span className={classes.requestStatusSpan}>on</span>
                        {formatDate(accessRequest.statusChangedAt)}
                      </Box>
                    </Box>
                  )
                  : (
                    <Box>
                      {accessRequest.status === 'PENDING'
                        ? (
                          <Box className={classes.requestStatus}>
                            <svg className={classes.mr2} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.99966 14.6032C11.6465 14.6032 14.6028 11.6468 14.6028 7.99999C14.6028 4.35316 11.6465 1.39682 7.99966 1.39682C4.35283 1.39682 1.39648 4.35316 1.39648 7.99999C1.39648 11.6468 4.35283 14.6032 7.99966 14.6032Z" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M8 4.5V7.99999H11.5556" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <Box
                              display="flex!important"
                            >
                              <span className={classes.requestStatusSpan}>Access Requested on</span>
                              {formatDate(accessRequest.createdAt)}
                            </Box>
                          </Box>
                        )
                        : ''}
                    </Box>
                  )}
              </Box>
              {accessRequest.status === 'APPROVED'
                ? (
                  <Box className={classes.requestStatus}>
                    <svg className="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 6L7 10L5 8" stroke="#1168A7" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#1168A7" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <Box
                      display="flex!important"
                    >
                      <span className={classes.requestStatusSpan}>Approved by</span>
                      {accessRequest.statusChangedBy && accessRequest.statusChangedBy.fullName}
                      <span className={classes.requestStatusSpan}>on</span>
                      {formatDate(accessRequest.statusChangedAt)}
                    </Box>
                  </Box>
                ) : '' }
            </>
          )
          : (
            <div>
              { COMPLIANCE_ROLES.includes(me.role) && (accessRequests && accessRequests.length > 0)
                ? (
                  <div className={classes.alignItemsRow}>
                    { numOfPending() ? (
                      <Box className={`${classes.requestStatus}`}>
                        <svg className={classes.mr2} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.99966 14.6032C11.6465 14.6032 14.6028 11.6468 14.6028 7.99999C14.6028 4.35316 11.6465 1.39682 7.99966 1.39682C4.35283 1.39682 1.39648 4.35316 1.39648 7.99999C1.39648 11.6468 4.35283 14.6032 7.99966 14.6032Z" stroke="#1168A7" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8 4.5V7.99999H11.5556" stroke="#1168A7" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <Box>
                          <span className={classes.requestStatusSpan}>{numOfPending()}</span>
                          <span className={classes.pipe}>{ numOfApproved() || numOfRejected() ? '|' : ''}</span>
                        </Box>
                      </Box>
                    )
                      : ''}
                    { numOfApproved() ? (
                      <Box className={`${classes.requestStatus}`}>
                        <svg className={classes.mr2} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11 6L7 10L5 8" stroke="#88CD00" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#88CD00" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <Box>
                          <span className={classes.requestStatusSpan}>{numOfApproved()}</span>
                        </Box>
                        <span className={classes.pipe}>{ numOfRejected() ? '|' : ''}</span>
                      </Box>
                    )
                      : ''}
                    { numOfRejected() ? (
                      <Box className={`${classes.requestStatus}`}>
                        <svg className={classes.mr2} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.99966 14.6032C11.6465 14.6032 14.6028 11.6468 14.6028 7.99999C14.6028 4.35316 11.6465 1.39682 7.99966 1.39682C4.35283 1.39682 1.39648 4.35316 1.39648 7.99999C1.39648 11.6468 4.35283 14.6032 7.99966 14.6032Z" stroke="#CC0000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6 6L10 10" stroke="#CC0000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6 10L10 6" stroke="#CC0000" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <Box>
                          <span className={classes.requestStatusSpan}>{numOfRejected()}</span>
                        </Box>
                      </Box>
                    )
                      : ''}
                  </div>
                )
                : ('')}
            </div>
          )}
      </Box>
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
          <Box display="flex" flexDirection="row" alignItems="flex-start">
            <Box display="flex" flexWrap="wrap">
              {tickers.length > 0
              && (
                <Button
                  key={tickers[0].ticker.id}
                  className={classes.ticker}
                  onClick={handleTickerClick(tickers[0].ticker)}
                  disableRipple
                >
                  {tickers[0].ticker.title.toUpperCase()}
                </Button>
              )}
            </Box>
            <Box display="flex">
              {tickers.length > 0
              && (
                <Box component="span" className={classes.company}>
                  {tickers[0].ticker.description}
                </Box>
              )}
            </Box>
          </Box>
          {(me.role !== 'COMPLIANCE')
          && (
            <InterviewItemActions
              isSeenInRl={isSeenInRl}
              interviewId={interviewId}
              isSelected={isSelected}
              isPrintAllowed={userCanViewInterview && me.hasPaidAccess && transcriptionStatus === 'TRANSCRIBED'}
              stopPropagation={handleIconClick()}
            />
          )}
        </Box>
        <Box display="flex" alignItems="center" mb="5px">
          <Typography
            variant="h5"
            component="h5"
            className={classes.title}
          >
            {me.role !== 'EXPERT'
            && (
              <Link
                to={`/${interviewId}${locationSearch}`}
                className={classes.titleLink}
                // onClick={handleLinkClick}
              >
                {relevantRole}
              </Link>
            )}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
          <Box display="flex" flexGrow="1" mb="5px" mr="10px" className={classes.date}>
            <Box component="span" mr="4px">
              Perspective:
            </Box>
            <Box component="span" color="text.primary" fontWeight="fontWeightBold">
              {perspective}
            </Box>
          </Box>
          <Box display="flex" flexGrow="1" justifyContent="flex-end" mb="5px" className={classes.date}>
            {me.role === 'MEMBER' && userCanViewInterview && me.hasPaidAccess && transcriptionStatus === 'TRANSCRIBED' && percentsRead >= 5
            && (
              <InterviewItemProgress
                progress={percentsRead}
              />
            )}
            <Box component="span">
              {formatDate(callDate)}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

InterviewItem.defaultProps = {
  className: '',
  headline: '',
  relevantRole: '',
  callDate: '',
  perspective: '',
  isActive: false,
  accessRequest: {},
  accessRequests: [],
  isUnread: false,
  percentsRead: 0,
  isSeenInRl: null,
  readingListItem: {},
};

InterviewItem.propTypes = {
  id: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  readingTime: PropTypes.number.isRequired,
  publishedAt: PropTypes.string.isRequired,
  callDate: PropTypes.string,
  perspective: PropTypes.string,
  headline: PropTypes.string,
  relevantRole: PropTypes.string,
  tickers: PropTypes.arrayOf(PropTypes.object).isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  accessRequest: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  accessRequests: PropTypes.objectOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  readingListItem: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object.isRequired,
  isUnread: PropTypes.bool,
  percentsRead: PropTypes.number,
  isSeenInRl: PropTypes.bool,
  isSelected: PropTypes.bool.isRequired,
  userCanViewInterview: PropTypes.bool.isRequired,
  transcriptionStatus: PropTypes.string.isRequired,
  handleWriteFilterData: PropTypes.func.isRequired,
};

// const areEqual = (prevProps, nextProps) => {
//   const prevObj = {
//     uuid: prevProps.uuid,
//     isActive: prevProps.isActive,
//     isUnread: prevProps.isUnread,
//     isSeenInRl: prevProps.isSeenInRl,
//     isSelected: prevProps.isSelected,
//     percentsRead: prevProps.percentsRead,
//   };
//   const nextObj = {
//     uuid: nextProps.uuid,
//     isActive: nextProps.isActive,
//     isUnread: nextProps.isUnread,
//     isSeenInRl: nextProps.isSeenInRl,
//     isSelected: nextProps.isSelected,
//     percentsRead: nextProps.percentsRead,
//   };
//   return isEqual(prevObj, nextObj);
// };

// export default React.memo(InterviewItem, areEqual);

export default InterviewItem;
