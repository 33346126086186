/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'apollo-link-sentry/lib/utils';
import Fuse from 'fuse.js';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from 'notistack';
import { useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { gql } from '@apollo/client/core';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as AddedIcon } from '../../../../../../assets/icons/interviewActions/addedIcon.svg';
import { ReactComponent as AddRLIcon } from '../../../../../../assets/icons/interviewActions/addRLIcon.svg';
import { ReactComponent as SearchIcon } from '../../../../../../assets/icons/interviewActions/searchIcon.svg';
import { ReactComponent as CreateListIcon } from '../../../../../../assets/icons/interviewActions/createListIcon.svg';
import CreateReadingList
  from '../../../../../../layouts/Main/components/Sidebar/components/SidebarDrawer/components/ReadingList/components/CreateReadingList';
import {
  ADD_INTERVIEW_TO_READING_LIST_MUTATION,
  ALL_READING_LISTS_QUERY,
  REMOVE_FROM_READING_LIST_MUTATION,
} from '../../../../../../queries/interview/interview';
import useAllIntervies from '../../../../../../hooks/useAllIntervies';
import { clearAll } from '../../../../../../actions/bulkSelectActions';
import useReadingListTitle from '../../../../../../hooks/useReadingListTitle';

const SearchRLField = withStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '5px',
    padding: '0px 8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      height: '30px',
      paddingLeft: '8px',
      '& input': {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        color: theme.palette.text.primary,
        padding: '5px 8px 5px 6px',
      },
      '& fieldset': {
        borderColor: theme.palette.common.white,
      },
      '&:hover': {
        '& svg path': {
          stroke: theme.palette.info.dark,
        },
        '& fieldset': {
          borderColor: theme.palette.info.main,
        },
      },
      '&.Mui-focused': {
        '& svg path': {
          stroke: theme.palette.primary.main,
        },
        '& fieldset': {
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& svg path': {
            stroke: theme.palette.primary.main,
          },
          '& fieldset': {
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  readingListMenuBtn: {
    height: '30px',
    padding: '5px 7px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    textTransform: 'inherit',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.common.white,
      },
      '& svg rect': {
        fill: theme.palette.common.white,
      },
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  btnTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  menuItem: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    padding: '0px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,
        },
      },
    },
  },
  menuItemClick: {
    display: 'flex',
    width: '100%',
    padding: '6px 16px',
  },
  menuItemRL: {
    display: 'flex',
    width: '100%',
    padding: '4px 16px',
  },
  removeFromBtn: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.error.main,
    cursor: 'pointer',
    marginTop: '5px',
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  moveText: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    marginBottom: '5px',
  },
  noLists: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    lineHeight: '18px',
    padding: '0px 16px',
    marginBottom: '5px',
  },
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  superInput: {
    maxWidth: '226px',
    padding: '0px 8px',
    marginBottom: '0px',
    marginTop: '5px',
    '& .MuiOutlinedInput-root': {
      padding: '0px 8px',
      '& input': {
        padding: '5px 6px',
      },
      '& button': {
        padding: '0px',
      },
      '& fieldset': {
        borderColor: theme.palette.common.white,
        borderStyle: 'solid',
      },
      '&:hover': {
        '& svg.create-list-icon path': {
          fill: theme.palette.primary.main,
        },
        '& fieldset': {
          borderColor: theme.palette.info.main,
        },
      },
      '&.Mui-focused': {
        '& svg.create-list-icon path': {
          fill: theme.palette.primary.main,
        },
        '&:hover': {
          '& svg.create-list-icon path': {
            fill: theme.palette.primary.main,
          },
        },
      },
    },
    '& MuiIconButton-root': {
      padding: '0px',
    },
  },
  removeBlock: {
    padding: '0 16px',
    '&:focus': {
      outline: 'none',
    },
  },
}));
const InterviewReadingList = ({
  readingListItem, interviewIds, isButton, className,
}) => {
  const classes = useStyles();
  const [readingListMenu, setReadingListMenu] = React.useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const isFullScreen = !isEmpty(useRouteMatch({ path: '/:interviewId/fullscreen' }));

  const [addInterviewToReadingList] = useMutation(ADD_INTERVIEW_TO_READING_LIST_MUTATION);
  const [removeFromReadingList] = useMutation(REMOVE_FROM_READING_LIST_MUTATION);
  const { updateQuery: updateAllInterviewsQuery, allInterviews, refetch } = useAllIntervies();
  const activeTab = useSelector((state) => state.sideBar.activeTab, isEqual);

  const {
    data: { allReadingList = {} } = {},
    networkStatus,
  } = useQuery(ALL_READING_LISTS_QUERY, {
    notifyOnNetworkStatusChange: true,
  });

  const handleOpenReadingListMenu = (event) => {
    setReadingListMenu(event.currentTarget);
  };

  const handleCloseReadingListMenu = () => {
    setReadingListMenu(null);
  };

  const handleSearchChange = (event) => {
    event.preventDefault();
    setSearch(event.target.value);
  };

  const dispatch = useDispatch();

  const readingListTitle = useReadingListTitle();

  const addInterview = (readingListId) => () => {
    window.analytics.track('Transcript Saved');
    addInterviewToReadingList({
      variables: {
        interviewIds,
        readingListId,
      },
      refetchQueries: [
        { query: ALL_READING_LISTS_QUERY },
      ],
      // update rl item in allInterviewsQuery
      update: ((cache, { data }) => {
        if (!isFullScreen) {
          if (isEmpty(allInterviews.results)) {
            refetch();
          }
          allInterviews.results.map((element) => {
            const newRlItem = data.addInterviewToReadingList.readingListItems.find(
              (elem) => element.interviewId === elem.interview.interviewId,
            );
            if (!newRlItem && activeTab === 'reading') {
              refetch();
            } else {
              updateAllInterviewsQuery((previousQueryResult) => {
                const results = previousQueryResult.allInterviews.results.map((el) => {
                  const rlItem = data.addInterviewToReadingList.readingListItems.find(
                    (elem) => el.interviewId === elem.interview.interviewId,
                  );
                  if (rlItem) return { ...el, readingListItem: rlItem, isSeenInRl: false };
                  return el;
                });

                return {
                  allInterviews: {
                    ...previousQueryResult.allInterviews,
                    results,
                  },
                };
              });
            }

            return data;
          });
          // update RL item on current interview
          cache.modify({
            fields: {
              interview(interview = {}) {
                cache.writeFragment({
                  // eslint-disable-next-line no-underscore-dangle
                  id: interview.__ref,
                  fragment: gql`
                    fragment interviewReadingListItem on InterviewType {
                      readingListItem,
                      isSeenInRl
                    }
                  `,
                  data: {
                    readingListItem: data.addInterviewToReadingList.readingListItems[0],
                    isSeenInRl: false,
                  },
                });
              },
            },
          });
        }

        enqueueSnackbar(`Interview has been added to the ${readingListTitle.toLowerCase()}.`, { variant: 'success', autoHideDuration: 4000 });
        if (!isFullScreen) {
          dispatch(clearAll());
        }
        handleCloseReadingListMenu();
      }),
    });
  };

  const hadleRemoveFromReadingList = () => {
    removeFromReadingList({
      variables: { interviewIds },
      refetchQueries: [
        { query: ALL_READING_LISTS_QUERY },
      ],
      // eslint-disable-next-line no-unused-vars
      update: ((cache, { data }) => {
        if (!isFullScreen) {
          updateAllInterviewsQuery((previousQueryResult) => {
            // TODO: orugets
            const first = interviewIds.find(Boolean);

            // eslint-disable-next-line no-unused-vars
            const results = previousQueryResult.allInterviews.results.map((el) => {
              if (el.interviewId === first) return { ...el, readingListItem: null, isSeenInRl: null };
              return el;
            });
            // TODO: ogurets
            return {
              allInterviews: {
                ...previousQueryResult.allInterviews,
                results,
              },
            };
          });
        } else {
          cache.modify({
            fields: {
              interview(interview = {}) {
                cache.writeFragment({
                  // eslint-disable-next-line no-underscore-dangle
                  id: interview.__ref,
                  fragment: gql`
                    fragment interviewReadingListItem on InterviewType {
                      readingListItem,
                      isSeenInRl
                    }
                  `,
                  data: {
                    readingListItem: null,
                    isSeenInRl: null,
                  },
                });
              },
            },
          });
        }

        enqueueSnackbar(`Interview has been removed from the ${readingListTitle.toLowerCase()}.`, { variant: 'success', autoHideDuration: 4000 });
        handleCloseReadingListMenu();
      }),
    });
  };

  useEffect(() => {
    if (allReadingList.totalCount > 0) {
      const options = {
        keys: ['title'],
        threshold: 0.2,
      };
      const fuse = new Fuse(allReadingList.results, options);
      let result = [];
      if (search) {
        const fuseResult = fuse.search(search);
        result = fuseResult.map((el) => el.item);
      } else {
        result = allReadingList.results;
      }
      setSearchResults(result.slice().sort(
        (a, b) => new Date(b.newestInterivew) - new Date(a.newestInterivew),
      ));
    }
  }, [search, allReadingList.results, allReadingList.totalCount]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [networkStatus]);
  // const readingListTitle = useReadingListTitle();

  const renderReadingListTitle = () => {
    if (isEmpty(readingListItem)) {
      if (interviewIds.length > 1) {
        return `${interviewIds.length} to ${readingListTitle}`;
      }
      return readingListTitle;
    }
    return readingListItem.readingList.title;
  };

  const renderTooltip = () => {
    if (!isEmpty(readingListItem)) {
      if (readingListItem.readingList.title.length > 16) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      {isButton
        ? (
          <Tooltip title={renderReadingListTitle()} arrow>
            <IconButton
              className={clsx(
                classes.readingListMenuBtn,
                className,
              )}
              aria-controls="readingListMenu"
              aria-haspopup="true"
              onClick={handleOpenReadingListMenu}
              disableRipple
            >
              {isEmpty(readingListItem) ? <AddRLIcon /> : <AddedIcon />}
            </IconButton>
          </Tooltip>
        )
        : (
          <Tooltip
            disableFocusListener
            disableHoverListener={renderTooltip()}
            disableTouchListener={renderTooltip()}
            title={renderReadingListTitle()}
            arrow
          >
            <Button
              className={clsx(
                classes.readingListMenuBtn,
                className,
              )}
              aria-controls="readingListMenu"
              aria-haspopup="true"
              onClick={handleOpenReadingListMenu}
              disableRipple
              startIcon={
                isEmpty(readingListItem) ? <AddRLIcon /> : <AddedIcon />
              }
            >
              <Box className={classes.btnTitle}>
                {renderReadingListTitle()}
              </Box>
            </Button>
          </Tooltip>
        )}
      <Menu
        id="readingListMenu"
        anchorEl={readingListMenu}
        keepMounted
        open={Boolean(readingListMenu)}
        onClose={handleCloseReadingListMenu}
        disableAutoFocusItem
        autoFocus={false}
      >
        {(allReadingList.totalCount >= 6 && !loading)
          && (
          <Box>
            <SearchRLField
              id="searchReadingList"
              name="searchReadingList"
              placeholder="Quick Search"
              onChange={handleSearchChange}
              InputProps={{ startAdornment: <SearchIcon /> }}
              variant="outlined"
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Box>
          )}

        {!isEmpty(readingListItem)
          && (
          <Box className={classes.removeBlock}>
            <Box
              className={classes.removeFromBtn}
              onClick={hadleRemoveFromReadingList}
              data-track="readingList/remove"
            >
              Remove from&nbsp;
              {readingListItem.readingList.title}
            </Box>
            <Divider className={classes.divider} />
            <Box className={classes.moveText}>Move to</Box>
          </Box>
          )}

        <Fade in={searchResults.length > 0 && !loading}>
          <Box>
            {(searchResults.length > 0 && !loading)
              && searchResults.slice(0, 6).map((item) => (
                <MenuItem
                  key={item.id}
                  className={classes.menuItem}
                  onClick={addInterview(item.id)}
                  data-track="readingList/addTo"
                  disableRipple
                >
                  <Box className={classes.menuItemRL}>
                    {item.title}
                          &nbsp;(
                    {item.totalCount}
                    )
                  </Box>
                </MenuItem>
              ))}
          </Box>
        </Fade>

        {searchResults.length === 0 && !loading && !search && (
        <Fade in={searchResults.length === 0 && !loading}>
          <Box className={classes.noLists}>{`No ${readingListTitle.toLowerCase()}s`}</Box>
        </Fade>
        )}

        {searchResults.length === 0 && !loading && search && (
        <Box className={classes.noLists}>0 result found</Box>
        )}

        <Box>
          <CreateReadingList
            className={classes.superInput}
            placeholder={`Create New ${readingListTitle}`}
            handleAddInterview={addInterview}
          >
            <CreateListIcon />
          </CreateReadingList>
        </Box>
      </Menu>
    </>
  );
};

InterviewReadingList.defaultProps = {
  readingListItem: {},
  interviewIds: [],
  isButton: false,
  className: '',
};

InterviewReadingList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  readingListItem: PropTypes.object,
  interviewIds: PropTypes.arrayOf(PropTypes.string),
  isButton: PropTypes.bool,
  className: PropTypes.string,
};

const areEqual = (prevProps, nextProps) => {
  const equals = [
    isEqual(prevProps.readingListItem, nextProps.readingListItem),
    isEqual(prevProps.interviewIds, nextProps.interviewIds),
  ];

  return equals.every((v) => v === true);
};

export default React.memo(InterviewReadingList, areEqual);
