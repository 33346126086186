import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Keywords from './Keywords';

const useStyles = makeStyles((theme) => ({
  expandBtn: {
    minWidth: '20px',
    height: '20px',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '16px',
    padding: '2px 0',
    borderRadius: '5px',
    marginBottom: '10px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
    '&:active': {
      backgroundColor: theme.palette.info.main,
    },
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.info.light,
      },
    },
  },
  minimizeBtn: {
    minWidth: '20px',
    height: '20px',
    padding: '9px 5px',
    borderRadius: '5px',
    marginBottom: '10px',
    backgroundColor: theme.palette.info.main,
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
    '&:active': {
      backgroundColor: theme.palette.info.main,
    },
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.info.dark,
      },
      '&:active': {
        backgroundColor: theme.palette.info.light,
      },
    },
  },
}));

const KeywordsGroup = ({ keywords, interviewId }) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);
  const showCount = 3;
  const keywordsCount = keywords.length;
  const displayedKeywords = showAll ? keywords : keywords.slice(0, showCount);

  const renderButtons = () => {
    if (showAll) {
      return (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={() => setShowAll(false)}
          disableRipple
          className={classes.minimizeBtn}
        >
          <img alt="remove" src="/images/icons/remove.svg" />
        </Button>
      );
    }
    return (
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => setShowAll(true)}
        disableRipple
        className={classes.expandBtn}
      >
        +
        {keywordsCount - showCount}
      </Button>
    );
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
    >
      {displayedKeywords.map((keyword) => (
        <Keywords
          key={keyword.id}
          keywordId={keyword.id}
          uuid={keyword.uuid}
          tickerUUID={keyword.ticker && keyword.ticker.uuid}
          tickerId={keyword.ticker && keyword.ticker.id}
          tickerTitle={keyword.ticker && keyword.ticker.title}
          title={keyword.title}
          interviewId={interviewId}
          isAddedToWatchList={keyword.isAddedToWatchList}
        />
      ))}
      {keywordsCount > showCount
      && renderButtons()}
    </Box>
  );
};

KeywordsGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  keywords: PropTypes.array.isRequired,
  interviewId: PropTypes.string.isRequired,
};

export default KeywordsGroup;
