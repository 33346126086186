import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  fullScreen: {
    height: '30px',
    padding: '5px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    marginLeft: '10px',
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.common.white,
      },
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const FullScreenBtn = ({ interviewId }) => {
  const classes = useStyles();

  return (
    <Tooltip title="Full Screen" arrow>
      <Link
        to={`/${interviewId}/fullscreen`}
        target="_blank"
        color="inherit"
        size="small"
        className={classes.fullScreen}
      >
        <Box display="flex">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4297 0C12.8997 0 12.6348 0.640625 13.0098 1.01562L14.2891 2.29688L10.293 6.29297C10.197 6.38511 10.1204 6.49548 10.0676 6.61761C10.0148 6.73974 9.98691 6.87117 9.98556 7.00421C9.98421 7.13726 10.0094 7.26923 10.0597 7.39241C10.11 7.51558 10.1844 7.62749 10.2784 7.72157C10.3725 7.81565 10.4844 7.89001 10.6076 7.9403C10.7308 7.99059 10.8627 8.01579 10.9958 8.01444C11.1288 8.01308 11.2603 7.9852 11.3824 7.93241C11.5045 7.87963 11.6149 7.80301 11.707 7.70703L15.7031 3.71094L16.9844 4.99023C17.3594 5.36523 18 5.10031 18 4.57031V0.59375C18 0.26575 17.7333 0 17.4043 0H13.4297ZM6.98047 9.99023C6.72067 9.99798 6.47409 10.1066 6.29297 10.293L2.29688 14.2891L1.01562 13.0098C0.640625 12.6348 0 12.8997 0 13.4297V17.4043C0 17.7333 0.26575 18 0.59375 18H4.57031C5.10031 18 5.36523 17.3594 4.99023 16.9844L3.71094 15.7031L7.70703 11.707C7.85153 11.5666 7.95023 11.3857 7.99019 11.1882C8.03015 10.9907 8.00951 10.7857 7.93097 10.6001C7.85242 10.4145 7.71965 10.257 7.55004 10.1482C7.38043 10.0393 7.1819 9.98429 6.98047 9.99023Z" fill="#1168A7" />
          </svg>
        </Box>
      </Link>
    </Tooltip>
  );
};

FullScreenBtn.propTypes = {
  interviewId: PropTypes.string.isRequired,
};

export default FullScreenBtn;
