import React from 'react';
import Box from '@material-ui/core/Box';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import TickerSelect from './components/TickerSelect';
import IndustrySelect from './components/IndustrySelect';
import SectorSelect from './components/SectorSelect';
import KeywordSelect from './components/KeywordSelect';
import DefaultSettings from './components/DefaultSettings';
import FollowingItemsSection from './components/FollowingItemsSection';
import { FollowingCompanies, FollowingIndustries, FollowingKeywords } from './components';
import FollowingSectors from './components/FollowingSectors';
import { writeFilterData } from '../../../../../../../../actions/filterActions';
import { hideSideBar } from '../../../../../../../../actions/sidebarActions';
import useMe from '../../../../../../../../hooks/useMe';
// import { mostPopular } from '../../../../../../../../constants/orderingOptions';

const Accordion = withStyles(() => ({
  root: {
    borderBottom: '1px solid #EAEEF4',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles(() => ({
  root: {
    minHeight: '30px',
    padding: '0 20px',
    '&$expanded': {
      minHeight: '30px',
    },
  },
  content: {
    margin: '6px 0',
    '&$expanded': {
      margin: '6px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: '5px 20px',
    overflow: 'auto',
    height: 'calc(100vh - 180px)',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    overflow: 'hidden',
    margin: '-20px',
  },
  headingPosition: {
    position: 'relative',
    margin: '20px 20px 12px',
  },

  title: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
  },
  badge: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',
    padding: '2px 10px',
    borderRadius: '9px',
    marginLeft: '5px',
  },
  settings: {
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&:hover svg path': {
      stroke: theme.palette.primary.main,
    },
  },
  collapse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover svg path': {
      stroke: theme.palette.primary.main,
    },
  },
}));

const Following = ({ filterData, handleWriteFilterData }) => {
  const [expanded, setExpanded] = React.useState('panelCompanies');
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });
  const { me } = useMe();
  const handleFilterSelecion = (values) => {
    const extraData = {};
    // if (values.tickers && values.tickers.length > 0) {
    //   if (filterData.o === null) {
    //     extraData.o = mostPopular;
    //   }
    // }
    handleWriteFilterData({ ...values, ...extraData });
    if (isMobile) {
      dispatch(hideSideBar());
    }
  };
  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box className={classes.container}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.headingPosition}
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Box className={classes.title}>
            Watchlist
          </Box>
        </Box>
        <DefaultSettings />
      </Box>

      <Box>
        <Accordion expanded={expanded === 'panelCompanies'} onChange={handleExpanded('panelCompanies')}>
          <AccordionSummary aria-controls="panelCompanies-content" id="panelCompanies-header">
            <FollowingItemsSection
              sectionTitle="Companies"
              expanded={expanded === 'panelCompanies'}
              unreadCount={me.companyWatchlistUnreadCount}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box className="xlink-watchlist-companies" width="100%">
              <TickerSelect />
              <FollowingCompanies
                filterDataTickers={filterData.tickers}
                handleFilterSelecion={handleFilterSelecion}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panelIndustries'} onChange={handleExpanded('panelIndustries')}>
          <AccordionSummary aria-controls="panelIndustries-content" id="panelIndustries-header">
            <FollowingItemsSection
              sectionTitle="Industries"
              expanded={expanded === 'panelIndustries'}
              unreadCount={me.industryWatchlistUnreadCount}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box className="xlink-watchlist-industries" width="100%">
              <IndustrySelect />
              <FollowingIndustries
                filterDataIndustries={filterData.industries}
                handleFilterSelecion={handleFilterSelecion}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panelSectors'} onChange={handleExpanded('panelSectors')}>
          <AccordionSummary aria-controls="panelSectors-content" id="panelSectors-header">
            <FollowingItemsSection
              sectionTitle="Sectors"
              expanded={expanded === 'panelSectors'}
              unreadCount={me.sectorWatchlistUnreadCount}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box className="xlink-watchlist-sectors" width="100%">
              <SectorSelect />
              <FollowingSectors
                filterDataSectors={filterData.sectors}
                handleFilterSelecion={handleFilterSelecion}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panelKeywords'} onChange={handleExpanded('panelKeywords')}>
          <AccordionSummary aria-controls="panelKeywords-content" id="panelKeywords-header">
            <FollowingItemsSection
              sectionTitle="Keywords"
              expanded={expanded === 'panelKeywords'}
              unreadCount={me.keywordWatchlistUnreadCount}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box className="xlink-watchlist-keywords" width="100%">
              <KeywordSelect />
              <FollowingKeywords
                filterDataKeywords={filterData.keywords}
                handleFilterSelecion={handleFilterSelecion}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

Following.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filterData: PropTypes.object.isRequired,
  handleWriteFilterData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { filterState } = state;
  return {
    filterData: filterState.filterData,
  };
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps.filterData, nextProps.filterData);

export default React.memo(
  connect(
    mapStateToProps,
    {
      handleWriteFilterData: writeFilterData,
    },
  )(Following),
  areEqual,
);
