import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { formatDate } from '../../helpers/dateHelpers';
import { MEMBER_ACCESS_REQUEST } from '../../queries/interview/interview';
import useAllIntervies from '../../hooks/useAllIntervies';

const useStyles = makeStyles(() => ({
  statusContent: {
    border: '1px solid #E2E9F1',
    backgroundColor: '#FFFFFF',
    color: '#182341',
    padding: '20px',
    margin: '20px 0',
    textAlign: 'center!important',
  },
  statusLogo: {
    height: '48px',
    width: '48px',
    backgroundColor: '#E6EAF2',
    marginBottom: '10px',
    marginLeft: 'auto!important',
    marginRight: 'auto!important',
    alignItems: 'center!important',
    justifyContent: 'center!important',
    display: 'flex!important',
    borderRadius: '50%!important',
  },
  statusText: {
    fontFamily: 'PT Serif Caption!important',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginBottom: '.5rem!important',
  },
  statusInfoLine: {
    fontFamily: 'PT Root UI',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    marginBottom: '10px',
    alignItems: 'center!important',
    justifyContent: 'center!important',
    display: 'flex!important',
  },
  mr3: {
    marginRight: '0.5em',
    marginBottom: '-3px',
  },
  redBckgrnd: {
    backgroundColor: '#FFDADA',
  },
  repeatRequestBttn: {
    border: '1px solid #E2E9F1',
    borderRadius: '0px',
    height: '40px',
    color: '#182341',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    padding: '11px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1168A7',
      color: 'white',
    },
  },
}));

const MemberAccessRequestStatus = ({
  accessRequest, interviewId, interviewPK,
}) => {
  const classes = useStyles();
  const { updateQuery: updateAllInterviewsQuery } = useAllIntervies();
  const [createRequest, { loading, called }] = useMutation(MEMBER_ACCESS_REQUEST, {
    errorPolicy: 'all',
    update: (cache, { data: { memberAccessRequest } }) => {
      cache.modify({
        id: `InterviewType:${interviewPK}`,
        fields: {
          accessRequest(fieldValue, details) {
            return details.INVALIDATE;
          },
        },
      });
      updateAllInterviewsQuery((previousQueryResult) => {
        const results = previousQueryResult.allInterviews.results.map((el) => {
          if (el.interviewId === interviewId) {
            return { ...el, accessRequest: memberAccessRequest.accessRequest };
          }
          return el;
        });

        return {
          allInterviews: {
            ...previousQueryResult.allInterviews,
            results,
          },
        };
      });
    },
  });

  const handleRequest = () => {
    if (!loading) {
      createRequest({ variables: { interviewId } });
    }
  };
  return (
    accessRequest.status === 'PENDING'
      ? (
        <Box className={classes.statusContent}>
          <Box className={classes.statusLogo}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.9997 22.2222C17.0569 22.2222 16.1997 21.6 15.8569 20.6222C15.8569 20.5333 15.8569 20.4444 15.7711 20.4444H8.14258C8.14258 20.5333 8.14258 20.6222 8.05686 20.6222C7.79972 21.6 6.94258 22.2222 5.99972 22.2222C5.48544 22.2222 5.14258 22.5778 5.14258 23.1111C5.14258 23.6444 5.48544 24 5.99972 24H17.9997C18.514 24 18.8569 23.6444 18.8569 23.1111C18.8569 22.5778 18.514 22.2222 17.9997 22.2222Z" fill="#1168A7">
                {/*  */}
              </path>
              <path d="M14.7426 7.02222L12.8569 7.64444V5.33333C12.8569 4.8 12.514 4.44444 11.9997 4.44444C11.4854 4.44444 11.1426 4.8 11.1426 5.33333V8.88889C11.1426 9.15555 11.314 9.42222 11.4854 9.6C11.6569 9.68889 11.8283 9.77777 11.9997 9.77777C12.0854 9.77777 12.1711 9.77777 12.2569 9.68888L15.2569 8.62222C15.6854 8.44444 15.9426 8 15.7711 7.46666C15.6854 7.11111 15.1711 6.84444 14.7426 7.02222Z" fill="#1168A7">
                {/*  */}
              </path>
              <path d="M21.4286 0H2.57143C1.11429 0 0 1.15556 0 2.66667V16C0 17.5111 1.11429 18.6667 2.57143 18.6667H8.48571H15.4286H21.4286C22.8857 18.6667 24 17.5111 24 16V2.66667C24 1.15556 22.8857 0 21.4286 0ZM12 15.1111C8.65714 15.1111 6 12.3556 6 8.88889C6 5.42222 8.65714 2.66667 12 2.66667C15.3429 2.66667 18 5.42222 18 8.88889C18 12.3556 15.3429 15.1111 12 15.1111Z" fill="#1168A7">
                {/*  */}
              </path>
            </svg>
          </Box>
          <Box className={classes.statusText}>
            Your request has been successfully sent to your Compliance Officer
          </Box>
          <Box component="div" className={classes.statusInfoLine}>
            <Box className={classes.mr3}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 16.3125C13.0386 16.3125 16.3125 13.0386 16.3125 9C16.3125 4.96142 13.0386 1.6875 9 1.6875C4.96142 1.6875 1.6875 4.96142 1.6875 9C1.6875 13.0386 4.96142 16.3125 9 16.3125Z" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                  {/*  */}
                </path>
                <path d="M9 3.9375V9H12.9375" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                  {/*  */}
                </path>
              </svg>
            </Box>
            {formatDate(accessRequest.createdAt)}
          </Box>
          {/* <Box component="div" className={classes.statusInfoLine}>
            {accessRequest.statusChangedAt}
          </Box> */}
          <Box component="div" className={classes.statusInfoLine}>
            <Box className={classes.mr3}>
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                  <path d="M12.1229 13.5931C10.7691 13.2828 9.84599 12.0414 9.84599 10.6759C10.9537 10.0552 11.6921 8.81379 11.6921 7.44827C11.6921 6.76551 12.246 6.20689 12.9229 6.20689V4.77931C12.9229 3.04138 11.6306 1.42758 9.90753 1.24138C8.67676 1.11724 7.56907 1.61379 6.89214 2.54482C6.39983 2.42069 5.90753 2.48276 5.29214 2.91724C4.67676 3.41379 4.30753 4.15862 4.30753 4.96551V5.5862V6.51724V7.32413C4.30753 8.68965 5.04599 9.9931 6.15368 10.6759C6.15368 12.1034 5.16907 13.2828 3.81522 13.6552C2.21522 14.0897 1.04599 15.3931 0.676758 17.0069C0.676758 17.1931 0.799835 17.3793 1.04599 17.3793H15.0152C15.2614 17.3793 15.3844 17.1931 15.3229 16.9448C14.9537 15.331 13.7229 14.0276 12.1229 13.5931Z" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                    {/*  */}
                  </path>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="16" height="18" fill="white">
                      {/*  */}
                    </rect>
                  </clipPath>
                </defs>
              </svg>
            </Box>
            {/* {complianceOfficers.map((officer, index) => {
              if (complianceOfficers.length === index + 1) {
              // last iteration
                return <Box key={officer.id}>{officer.fullName}</Box>;
              }
              return (
                <Box key={officer.id}>
                  {`${officer.fullName}, `}
                </Box>
              );
            })} */}
          </Box>
        </Box>
      ) : (
        <Box className={classes.statusContent}>
          <Box className={`${classes.statusLogo} ${classes.redBckgrnd}`}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM15 13.8C15.3429 14.1429 15.3429 14.6571 15 15C14.8286 15.1714 14.5714 15.2571 14.4 15.2571C14.2286 15.2571 13.9714 15.1714 13.8 15L12 13.2L10.2 15C10.0286 15.1714 9.77143 15.2571 9.6 15.2571C9.42857 15.2571 9.17143 15.1714 9 15C8.65714 14.6571 8.65714 14.1429 9 13.8L10.8 12L9 10.2C8.65714 9.85714 8.65714 9.34286 9 9C9.34286 8.65714 9.85714 8.65714 10.2 9L12 10.8L13.8 9C14.1429 8.65714 14.6571 8.65714 15 9C15.3429 9.34286 15.3429 9.85714 15 10.2L13.2 12L15 13.8Z" fill="#CC0000">
                {/*  */}
              </path>
            </svg>
          </Box>
          <Box className={classes.statusText}>
            Your request to access this Interview has been declined
          </Box>
          <Box component="div" className={classes.statusInfoLine}>
            <Box className={classes.mr3}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 16.3125C13.0386 16.3125 16.3125 13.0386 16.3125 9C16.3125 4.96142 13.0386 1.6875 9 1.6875C4.96142 1.6875 1.6875 4.96142 1.6875 9C1.6875 13.0386 4.96142 16.3125 9 16.3125Z" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                  {/*  */}
                </path>
                <path d="M9 3.9375V9H12.9375" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                  {/*  */}
                </path>
              </svg>
            </Box>
            {formatDate(accessRequest.createdAt)}
          </Box>
          {/* <Box component="div" className={classes.statusInfoLine}>
            {accessRequest.statusChangedAt}
          </Box> */}
          <Box component="div" className={classes.statusInfoLine}>
            <Box className={classes.mr3}>
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                  <path d="M12.1229 13.5931C10.7691 13.2828 9.84599 12.0414 9.84599 10.6759C10.9537 10.0552 11.6921 8.81379 11.6921 7.44827C11.6921 6.76551 12.246 6.20689 12.9229 6.20689V4.77931C12.9229 3.04138 11.6306 1.42758 9.90753 1.24138C8.67676 1.11724 7.56907 1.61379 6.89214 2.54482C6.39983 2.42069 5.90753 2.48276 5.29214 2.91724C4.67676 3.41379 4.30753 4.15862 4.30753 4.96551V5.5862V6.51724V7.32413C4.30753 8.68965 5.04599 9.9931 6.15368 10.6759C6.15368 12.1034 5.16907 13.2828 3.81522 13.6552C2.21522 14.0897 1.04599 15.3931 0.676758 17.0069C0.676758 17.1931 0.799835 17.3793 1.04599 17.3793H15.0152C15.2614 17.3793 15.3844 17.1931 15.3229 16.9448C14.9537 15.331 13.7229 14.0276 12.1229 13.5931Z" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                    {/*  */}
                  </path>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="16" height="18" fill="white">
                      {/*  */}
                    </rect>
                  </clipPath>
                </defs>
              </svg>
            </Box>
            {/* {complianceOfficers.map((officer, index) => {
              if (complianceOfficers.length === index + 1) {
              // last iteration
                return <Box key={officer.id}>{officer.fullName}</Box>;
              }
              return (
                <Box key={officer.id}>
                  {`${officer.fullName}, `}
                </Box>
              );
            })} */}
          </Box>
          <Button
            onClick={handleRequest}
            className={classes.repeatRequestBttn}
            disabled={loading || called}
          >
            Repeat Request
          </Button>
        </Box>
      )
  );
};

MemberAccessRequestStatus.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  accessRequest: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // complianceOfficers: PropTypes.array.isRequired,
  interviewId: PropTypes.string.isRequired,
  interviewPK: PropTypes.string.isRequired,
};

export default MemberAccessRequestStatus;
