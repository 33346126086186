/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1260px',
    width: '100%',
    padding: '20px 20px 30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
  },
  messageBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 221px)',
    flexGrow: '1',
    maxWidth: '500px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  primaryText: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '30px',
    color: theme.palette.primary.main,
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '48px',
      lineHeight: '60px',
    },
  },
  secondaryText: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom: '20px',
  },
}));

const StartTrialLaterDone = ({ date }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.messageBlock}>
        <Box mb="20px">
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M29.9815 49.5385C29.2615 49.5398 28.5649 49.2863 28.0123 48.8247L21.9691 43.7908C20.6018 42.7815 20.3113 40.8554 21.3193 39.488C22.3285 38.1207 24.2546 37.8302 25.622 38.8382C25.7218 38.912 25.8165 38.9908 25.9075 39.0769L29.7475 42.2769L38.3629 33.2677C39.5383 32.0406 41.4867 31.9999 42.7137 33.1754C43.9408 34.3508 43.9814 36.2992 42.8059 37.5262L32.172 48.603C31.5975 49.1975 30.8087 49.5348 29.9815 49.5385Z" fill="#1168A7" />
            <path d="M53.5385 5.84611H49.2308V3.38461C49.2308 1.68486 47.8535 0.307739 46.1539 0.307739C44.4541 0.307739 43.077 1.68499 43.077 3.38461V5.84611H20.9231V3.38461C20.9231 1.68486 19.5459 0.307739 17.8463 0.307739C16.1465 0.307739 14.7694 1.68499 14.7694 3.38461V5.84611H10.4615C4.68675 5.85349 0.007375 10.5329 0 16.3077V53.2309C0.007375 59.0056 4.68675 63.685 10.4615 63.6924H53.5384C59.3131 63.685 63.9925 59.0056 63.9999 53.2309V16.3077C63.9926 10.5329 59.3133 5.85349 53.5385 5.84611ZM10.4615 12H53.5384C55.9175 12 57.8461 13.9286 57.8461 16.3077V19.3846H6.15387V16.3077C6.15387 13.9286 8.0825 12 10.4615 12ZM53.5385 57.5385H10.4615C8.08237 57.5385 6.15375 55.6099 6.15375 53.2307V25.5385H57.846V53.2307C57.8461 55.6099 55.9175 57.5385 53.5385 57.5385Z" fill="#1168A7" />
          </svg>
        </Box>
        <Box className={classes.secondaryText}>
          We will send you a reminder that your access to our library of transcripts and one-on-one calls with experts will restart on the
        </Box>
        <Box className={classes.primaryText}>
          {date}
        </Box>
        <Box className={classes.secondaryText}>
          If you would like to begin before feel free to get in touch at any point and we can start your trial sooner.
        </Box>
        <Box className={classes.secondaryText}>
          Thank you!
        </Box>
      </Box>
    </Box>
  );
};

StartTrialLaterDone.propTypes = {
  date: PropTypes.string.isRequired,
};

export default StartTrialLaterDone;
