import React from 'react';
import { Field } from 'react-final-form';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const InputField = withStyles((theme) => ({
  root: {
    marginTop: '8px',
    '& .MuiInputBase-root': {
      borderRadius: '5px',
      height: '40px',
      '&.MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
      },
      '& input': {
        height: '100%',
        padding: '0 52px 0 14px',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
      },
      '& fieldset': {
        borderColor: theme.palette.info.main,
      },
      '& button': {
        position: 'absolute',
        right: 0,
        padding: '8px 14px',
        '&:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.text.secondary,
        },
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.primary.main,
          },
        },
      },
      '&.Mui-disabled': {
        '& input': {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.default,
        },
        '& fieldset': {
          borderColor: theme.palette.info.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.info.main,
          },
        },
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
  },
  errorMsg: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.error.main,
  },
}));

const FormTextField = ({
  fieldName, fieldType, label, ...rest
}) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const fieldTypeValue = () => {
    if (fieldType === 'password') {
      return values.showPassword ? 'text' : 'password';
    }
    return fieldType;
  };

  const togglePasswordIcon = () => {
    if (fieldType === 'password') {
      return (
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          disableRipple
        >
          {values.showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      );
    }
  };

  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <Box>
          <label
            htmlFor={`id_${fieldName}`}
            className={classes.label}
          >
            {label}
          </label>
          <InputField
            {...input}
            type={fieldTypeValue()}
            id={`id_${fieldName}`}
            fullWidth
            InputProps={{
              endAdornment: togglePasswordIcon(),
            }}
            {...rest}
          />
          {(meta.error || meta.submitError) && meta.touched && (
            <span className={classes.errorMsg}>{meta.error || meta.submitError}</span>
          )}
        </Box>
      )}
    </Field>
  );
};

FormTextField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FormTextField;
