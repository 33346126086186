import { gql } from '@apollo/client';

const accessRequestfields = `
  id
  createdAt
  statusChangedAt
  status
  interview {
    id
    uuid
    interviewId
  }
  user {
    id
    uuid
    fullName
    realFullName
  }
  statusChangedBy {
    id
    fullName
  }
`;

const interviewFragment = `
  id
  uuid
  interviewId
  publishedAt
  readingTime
  headline
  relevantRole
  perspective
  callDate
  isUnread
  percentsRead
  grade {
    value
  }
  audio
  transcriptionStatus
  userCanViewInterview
  summary
  tableOfContents
  questionnaire
  content
  orders {
    id
    createdAt
  }
  accessRequest {
    ${accessRequestfields}
  }
  accessRequests {
    ${accessRequestfields}
  }
  expert {
    id
    uuid
    shortName
    realShortName
    fullName
    realFullName
    employmentHistory
    slug
    bio
    isCallRequestAllowed
  }
  analyst {
    id
    shortName
    realShortName
    fullName
    realFullName
    slug
  }
  tickers {
    ticker {
      id
      uuid
      title
      description
      slug
      company {
        id
      }
      industry {
        id
        uuid
        title
        isAddedToWatchList
      }
      sector {
        id
        uuid
        title
        isAddedToWatchList
      }
      isAddedToWatchList
      interviews(first: 5) {
        totalCount
        results {
          id
          interviewId
          headline
          relevantRole
          perspective
          publishedAt
          tickers {
            ticker {
              id
              uuid
              title
              description
              isAddedToWatchList
            }
          }
          summary
          expert {
            bio
          }
          userCanViewInterview
        }
      }
    }  
  }
  keywords {
    id
    uuid
    title
    slug
    isAddedToWatchList
    ticker {
      id
      uuid
      slug
      title
    }
  }
  readingListItem {
    id
    isSeenInReadingList
    interview {
      interviewId
    }
    readingList {
      id
      title
    }
  }
`;

export const ALL_INTERVIEWS_QUERY = gql`
query allInterviews($o: String, $tickers: [ID], $industries: [ID], $sectors: [ID], $keywords: [ID], $search: String, $analyst: [ID], $expert: [ID], $perspective: [String], $complianceStatus: String, $transcriptionStatus: String, $publishedAtFrom: Date, $publishedAtTo: Date, $callDateFrom: Date, $callDateTo: Date, $companyName: [String], $first: Int, $skip: Int, $readingLists: [ID], $activeTab: String, $isPrimaryTicker: Boolean!) {
  allInterviews(o: $o, tickers: $tickers, industries: $industries, sectors: $sectors, keywords: $keywords, search: $search, analyst: $analyst, expert: $expert, perspective: $perspective, complianceStatus: $complianceStatus, transcriptionStatus: $transcriptionStatus, publishedAtFrom: $publishedAtFrom, publishedAtTo: $publishedAtTo, callDateFrom: $callDateFrom, callDateTo: $callDateTo, companyName: $companyName, first: $first, skip: $skip, readingLists: $readingLists, activeTab: $activeTab, isPrimaryTicker: $isPrimaryTicker) {
    totalCount
    primaryCount
    secondaryCount
    results {
      id
      uuid
      interviewId
      readingTime
      publishedAt
      isUnread
      percentsRead
      callDate
      perspective
      headline
      isSeenInRl
      userCanViewInterview
      transcriptionStatus
      relevantRole
      accessRequest {
        ${accessRequestfields}
      }
      accessRequests {
        ${accessRequestfields}
      }
      tickers {
        ticker {
          id
          uuid
          title
          slug
          description
        }
      }
      readingListItem{
        id
        interview {
          interviewId
        }
        isSeenInReadingList
        readingList{
          id
          title
        }
      }
    }
  }
}
`;

export const INTERVIEW_QUERY = gql`
query Interview($interviewId: String!, $autoDisplayed: Boolean) {
  interview(interviewId: $interviewId, autoDisplayed: $autoDisplayed) {
    ${interviewFragment}
  }
}
`;

export const INTERVIEW_PUBLIC_QUERY = gql`
query InterviewPublic($interviewHash: String, $hashid: String, $intId: String) {
  interviewPublic(interviewHash: $interviewHash, hashid: $hashid, intId: $intId) {
    ${interviewFragment}
  }
}
`;

export const ALL_COMPANIES_QUERY = gql`
query allTickers($order: String, $first: Int, $skip: Int, $search: String) {
  allTickers(order: $order, first: $first, skip: $skip, search: $search) {
    totalCount
    results {
      id
      uuid
      title
      description
      watchlistId
      lastCallDate
      lastPublishedAt
      sector {
        title
      }
    }
  }
}
`;

export const ALL_COMPANIES_PARENTS_QUERY = gql`
query allCompanies($first: Int, $skip: Int, $search: String, $order: String) {
  allCompanies(first: $first, skip: $skip, search: $search, order: $order) {
    totalCount
    results {
      id
      uuid
      title
      lastCallDate
      lastPublishedAt
      watchlistId
      tickers {
        id
        uuid
        title
      }
    }
  }
}
`;

export const ALL_READING_LISTS_QUERY = gql`
query allReadingList($search: String, $order: String){
  allReadingList(search: $search, order: $order){
    totalCount
    results {
      id
      title
      numOfNewInterviews
      totalCount
      orderIndex
      newestInterivew
    }
  }
}
`;

export const SAVED_FILTER_QUERY = gql`
query savedFilter($hashid: String!){
  savedFilter(hashid: $hashid){
    id
    hashid
    filterData
  }
}
`;

export const POPULAR_KEYWORDS_QUERY = gql`
query popularKeywords($first: Int!, $days: Int){
  popularKeywords(first: $first, days: $days){
    id
    title
    interviewsCount
  }
}
`;

export const ALL_INDUSTRIES = gql`
query allIndustries($first: Int!, $skip: Int!, $search: String){
  allIndustries(first: $first, skip: $skip, search: $search){
    totalCount
    results {
      id
      title
      slug
    }
  }
}
`;

export const ALL_SECTORS = gql`
query allSectors($first: Int!, $skip: Int!, $search: String){
  allSectors(first: $first, skip: $skip, search: $search){
    totalCount
    results {
      id
      title
      slug
    }
  }
}
`;

export const ALL_KEYWORDS = gql`
query allKeywords($first: Int!, $skip: Int!, $search: String){
  allKeywords(first: $first, skip: $skip, search: $search){
    totalCount
    results {
      id
      title
      slug
    }
  }
}
`;

export const COMPLIANCE_REQUEST_ACTION = gql`
mutation complianceAccessRequest($access_request_id: Int!, $status: String!) {
  complianceAccessRequest(accessRequestId: $access_request_id, status: $status) {
    accessRequest {
      ${accessRequestfields}
    }
  }
}
`;

export const MEMBER_ACCESS_REQUEST = gql`
mutation memberAccessRequest($interviewId: String!){
  memberAccessRequest(interviewId: $interviewId){
    accessRequest{
      ${accessRequestfields}
    }
  }
}
`;

export const FILTERS_CACHE = gql`
query filtersCache {
  filtersCache {
    filterData
  }
}
`;

export const ADD_COMPLIANCE_OFFICER_MUTATION = gql`
mutation addComplianceOfficer($email: String!, $first_name: String, $interviewId: String!, $last_name: String){
  addComplianceOfficer(email: $email, firstName: $first_name, interviewId: $interviewId, lastName: $last_name){
    added
    accessRequest{
      ${accessRequestfields}
    }
  }
}
`;

export const DOWNLOAD_INTERVIEW_PDF_MUTATION = gql`
mutation downloadInterviewPdf($interviewIds: [String]!) {
  downloadInterviewPdf(interviewIds: $interviewIds) {
    filename
    file
  }
}
`;

export const ORDER_INTERVIEW_MUTATION = gql`
mutation($interviewId: String!){
  orderInterview(interviewId: $interviewId){
    success
  }
}
`;

export const CREATE_SHARE_LINK_MUTATION = gql`
mutation($interviewId: String!){
  createShareLink(interviewId: $interviewId){
    link
  }
}
`;

export const CREATE_SAVED_FILTER_MUTATION = gql`
mutation($filterData: JSONString!){
  createSavedFilter(filterData: $filterData){
    savedFilterHashid
  }
}
`;

export const UPGRADE_NOW_MUTATION = gql`
mutation {
  upgradeNow {
    success
  }
}
`;

export const CREATE_READING_LIST_MUTATION = gql`
  mutation addReadingList ($title: String!){
    createReadingList(title: $title) {
      readingList {
        id
        title
        numOfNewInterviews
        totalCount
        orderIndex
      }
    }
  }
`;

export const UPDATE_READING_LIST_MUTATION = gql`
  mutation UpdateReadingList ($readingListId: Int! $title: String, $orderIndex: Float){
    updateReadingList(readingListId: $readingListId, title: $title, orderIndex: $orderIndex) {
      readingList {
        id
        title
        numOfNewInterviews
        totalCount
        orderIndex
      }
    }
  }
`;

export const DELETE_READING_LIST_MUTATION = gql`
  mutation DeleteReadingList ($readingListId: Int!){
    deleteReadingList(readingListId: $readingListId) {
      readingList {
        title
        numOfNewInterviews
        totalCount
      }
    }
  }
`;

export const ADD_INTERVIEW_TO_READING_LIST_MUTATION = gql`
  mutation AddInterviewToReadingList ($interviewIds: [String]!, $readingListId: Int! ){
    addInterviewToReadingList( interviewIds: $interviewIds, readingListId: $readingListId) {
      readingListItems {
        id
        isSeenInReadingList
        interview{
          interviewId
        }
        readingList{
          id
          title
        }
      }
    }
  }
`;

export const SET_INTERVIEW_IS_SEEN_MUTATION = gql`
  mutation SetInterviewIsSeen ($interviewId: String!, $isSeen: Boolean! ){
    setInterviewIsSeen( interviewId: $interviewId, isSeen: $isSeen) {
      readingListItem {
        id
        isSeenInReadingList
        interview{
          interviewId
        }
        readingList{
          id
          title
        }
      }
    }
  }
`;

export const REMOVE_FROM_READING_LIST_MUTATION = gql`
  mutation removeIntervievFromRl ($interviewIds: [String]!){
    removeIntervievFromRl( interviewIds: $interviewIds) {
      interviews {
        id
      }
    }
  }
`;

export const REQUEST_EXPERT_CALL_MUTATION = gql`
  mutation sendExpertCallRequest ($expertPk: Int!){
    sendExpertCallRequest(expertPk: $expertPk) {
      success
    }
  }
`;

export const SET_INTERVIEW_PERCENTS_READ_MUTATION = gql`
  mutation setInterviewPercentsRead ($interviewPk: Int!, $percentsRead: Int!){
    setInterviewPercentsRead(interviewPk: $interviewPk, percentsRead: $percentsRead) {
      success
    }
  }
`;

export const SAVE_USER_SESSION_FILTERS = gql`
  mutation saveUserSessionFilters ($filterData: JSONString!){
    saveUserSessionFilters (filterData: $filterData) {
      success
    }
  }
`;

export const TRANSCRIPT_RATING_MUTATION = gql`
  mutation setInterviewGrade ($interviewId: String!, $grade: String!){
    setInterviewGrade(interviewId: $interviewId, grade: $grade) {
      grade {
        value
      }
    }
  }
`;

export const SEND_INVITES_TO_JOIN_MUTATION = gql`
  mutation sendInvitesToJoin ($interviewId: String!, $emails: [String]!){
    sendInvitesToJoin(interviewId: $interviewId, emails: $emails) {
      validatedEmails
    }
  }
`;
