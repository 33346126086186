import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { useMutation } from '@apollo/client';
import { Form } from 'react-final-form';
import { VALIDATE_TOKEN_MUTATION, SET_PASSWORD_MUTATION } from '../../queries/account';
import { FormTextField, LinearProgressMosaic } from '../../components';
import SubmitButton from '../../components/SubmitButton/SubmitButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexGrow: '1',
  },
  logBox: {
    position: 'relative',
    maxWidth: '540px',
    width: '100%',
    padding: '20px',
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginBottom: '20px',
    '& ul': {
      paddingLeft: '1.3rem',
    },
  },
  textInv: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
  submitBtn: {
    borderRadius: '0',
    letterSpacing: 'normal',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    height: '40px',
    padding: '0 30px',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.dark,
    },
  },
}));

const SetPassword = () => {
  const classes = useStyles();

  const { uidb, token } = useParams();
  const history = useHistory();

  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [validateTokenFunc, {
    data: { validateToken = {} } = {},
  }] = useMutation(VALIDATE_TOKEN_MUTATION, { errorPolicy: 'all' });

  const [setPasswordFunc, {
    data: { setPassword = {} } = {},
    loading,
    error,
  }] = useMutation(SET_PASSWORD_MUTATION, { errorPolicy: 'all' });

  const onSubmit = (values) => {
    setPasswordFunc({ variables: { uidb, token, ...values } });
  };

  useEffect(() => {
    validateTokenFunc({ variables: { uidb, token } }).then(() => {
      setIsLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uidb, token]);

  useEffect(() => {
    setIsTokenValid(Boolean(validateToken && validateToken.valid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateToken]);

  useEffect(() => {
    if (setPassword.user && setPassword.user.email) {
      history.push('/accounts/password-reset-complete');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPassword]);

  if (error) {
    console.log(error);
  }

  if (!isLoaded) return <LinearProgressMosaic />;

  return (
    <Box className={classes.root}>
      <Box className={classes.logBox}>
        <Box
          p={{ xs: '20px', md: '50px' }}
          bgcolor="background.paper"
        >
          {isTokenValid
            ? (
              <>
                <Box className={classes.text}>
                  <Box mb="20px">
                    Please enter your new password twice so we can verify you typed it in correctly:
                  </Box>
                  <ul>
                    <li>Your password can&apos;t be similar to your other personal information.</li>
                    <li>Your password must contain at least 8 characters.</li>
                    <li>Your password can&apos;t be a commonly used password.</li>
                    <li>Your password can&apos;t be entirely numeric.</li>
                  </ul>
                </Box>
                <Form
                  onSubmit={onSubmit}
                  validate={(values) => {
                    const errors = {};
                    if (!values.new_password1) {
                      errors.new_password1 = 'Required';
                    }
                    if (!values.new_password2) {
                      errors.new_password2 = 'Required';
                    }
                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    submitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box mb="20px">
                        <FormTextField
                          fieldName="new_password1"
                          fieldType="password"
                          label="New Password"
                          variant="outlined"
                          size="small"
                        />
                      </Box>
                      <Box mb="20px">
                        <FormTextField
                          fieldName="new_password2"
                          fieldType="password"
                          label="Re-enter New Password"
                          variant="outlined"
                          size="small"
                        />
                      </Box>
                      <SubmitButton
                        loading={loading}
                        submitting={submitting}
                        className={classes.submitBtn}
                      >
                        Set Password
                      </SubmitButton>
                    </form>
                  )}
                />
              </>
            )
            : (
              <Box className={classes.textInv}>
                The password reset link was invalid, possibly because
                it has already been used. Please request a new password reset.
              </Box>
            )}
        </Box>
      </Box>
    </Box>
  );
};

SetPassword.propTypes = {
};

export default SetPassword;
