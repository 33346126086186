import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { closeSideBar, hideSideBar } from '../../actions/sidebarActions';
import Footer from '../../layouts/Main/components/Footer/Footer';
import SectorsArea from './components/SectorsArea';
import KeywordsArea from './components/KeywordsArea';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1440px',
    minHeight: 'calc(100vh - 70px)',
    width: '100%',
    padding: '20px 20px 0',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      padding: '40px 100px 0',
    },
  },
  footer: {
    padding: '26px 0',
  },
  section: {
    paddingBottom: '72px',
  },
}));

const DiscoveryPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });
  const dispatch = useDispatch();
  const handleHideSidebar = () => dispatch(hideSideBar());
  const handleCloseSidebar = () => dispatch(closeSideBar());

  useEffect(() => {
    handleCloseSidebar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMobile) {
      handleHideSidebar();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.section}>
          <SectorsArea />
        </Box>

        <Box className={classes.section}>
          <KeywordsArea />
        </Box>
      </Box>
      <Footer className={classes.footer} />
    </>
  );
};

export default DiscoveryPage;
