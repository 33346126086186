import { createMatchSelector, push } from 'connected-react-router';

import sidebarTabs from '../constants/sidebarTabs';
import { store } from '../store';
import {
  HIDE_SIDEBAR, SET_ACTIVE_TAB, SET_ACTIVE_TAB_NULL, SHOW_SIDEBAR,
} from './sidebarConstants';
import { cleanFilterData } from './filterActions';

/**
 * Redirects user to home page if tab was clicked not from interview page
 */
const isInterviewPage = () => {
  const state = store.getState();
  const matchSelectorInterview = createMatchSelector({ path: '/:interviewId' });
  const matchSelectorHome = createMatchSelector({ path: '/' });
  const matchDiscoveryPage = createMatchSelector({ path: '/discovery' });

  const matchInterview = matchSelectorInterview(state);
  const matchHome = matchSelectorHome(state);
  const matchDiscovery = matchDiscoveryPage(state);
  return (matchInterview?.isExact || matchHome?.isExact) && !matchDiscovery?.isExact;
};

const handleRedirect = () => {
  if (!isInterviewPage()) {
    store.dispatch(push('/'));
  }
};

export const closeSideBar = () => ({ type: SET_ACTIVE_TAB_NULL });
export const openCompanies = () => {
  handleRedirect();
  const state = store.getState();

  if (state.sideBar.activeTab !== sidebarTabs.companies) {
    if (isInterviewPage()) {
      store.dispatch(cleanFilterData());
    }
    return { type: SET_ACTIVE_TAB, payload: sidebarTabs.companies };
  }
  return closeSideBar();
};
export const openFollowing = () => {
  handleRedirect();
  const state = store.getState();

  if (state.sideBar.activeTab !== sidebarTabs.following) {
    store.dispatch(cleanFilterData());
    return { type: SET_ACTIVE_TAB, payload: sidebarTabs.following };
  }
  return closeSideBar();
};
export const openReading = () => {
  handleRedirect();
  const state = store.getState();

  if (state.sideBar.activeTab !== sidebarTabs.reading) {
    store.dispatch(cleanFilterData());
    return { type: SET_ACTIVE_TAB, payload: sidebarTabs.reading };
  }
  return closeSideBar();
};
export const openSideBar = () => ({ type: SET_ACTIVE_TAB, payload: sidebarTabs.companies });
export const hideSideBar = () => ({ type: HIDE_SIDEBAR });
export const showSideBar = () => ({ type: SHOW_SIDEBAR });
