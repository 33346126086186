import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { UPDATE_USER_PROFILE_MUTATION } from '../../queries/account';
import {
  FormSelectField,
  FormTextField,
  LinearProgressMosaic,
} from '../../components';
// import FormFileField from '../../components/FormFields/FormFileField';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import login from '../../helpers/login';
import useMe from '../../hooks/useMe';
import { hideSideBar } from '../../actions/sidebarActions';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '940px',
    width: '100%',
    padding: '20px 20px 30px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '28px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.info.main,
    marginBottom: '20px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '23px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '30px',
    },
  },
  submitBtn: {
    borderRadius: '0',
    letterSpacing: 'normal',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    height: '40px',
    padding: '0 30px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  avatarBox: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.info.main,
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    height: '70px',
    width: '70px',
    minWidth: '70px',
    overflow: 'hidden',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    [theme.breakpoints.up('md')]: {
      marginRight: '20px',
      height: '100px',
      width: '100px',
      minWidth: '100px',
    },
  },
  uploadBtn: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    padding: '10px 30px',
    transition: 'all .25s',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  formBox: {
    paddingBottom: '20px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.info.main,
    marginBottom: '20px',
    '& .MuiGrid-container': {
      width: 'calc(100% + 10px)',
      margin: '-5px',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% + 20px)',
        margin: '-10px',
      },
      '& .MuiGrid-item': {
        padding: '5px',
        [theme.breakpoints.up('md')]: {
          padding: '10px',
        },
      },
    },
  },
  checkbox: {
    padding: '0 10px 0 0',
    color: theme.palette.info.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  pwdBox: {
    '& .MuiGrid-container': {
      width: 'calc(100% + 10px)',
      margin: '-5px',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100% + 20px)',
        margin: '-10px',
      },
      '& .MuiGrid-item': {
        padding: '5px',
        [theme.breakpoints.up('md')]: {
          padding: '10px',
        },
      },
    },
  },
  listStyle: {
    paddingLeft: '1.3rem',
  },
}));

const Profile = () => {
  const classes = useStyles();

  const { me, refetch, loading: meLoading } = useMe();

  const [updateProfile, { loading }] = useMutation(UPDATE_USER_PROFILE_MUTATION, {});
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });
  const dispatch = useDispatch();
  const handleHideSidebar = () => dispatch(hideSideBar());

  useEffect(() => {
    if (isMobile) {
      handleHideSidebar();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const onSubmit = async (values) => {
    const errors = {};
    const valuesCopy = { ...values };
    if (valuesCopy.avatarFile) {
      // eslint-disable-next-line prefer-destructuring
      valuesCopy.avatarFile = values.avatarFile[0];
    }
    await updateProfile({
      variables: valuesCopy,
    }).then(({ data = {} }) => {
      const { submitErrors, token, refreshExpiresIn } = data.updateUserProfile;
      if (!data.updateUserProfile.errors) {
        enqueueSnackbar('Profile successfully updated!', { variant: 'success', autoHideDuration: 4000 });
      } else {
        data.updateUserProfile.errors.forEach((err) => {
          enqueueSnackbar(`${err.messages.join('\n')}`,
            { variant: 'error', autoHideDuration: 4000, style: { whiteSpace: 'pre-line' } });
        });
      }
      if (token) {
        login(token, refreshExpiresIn);
        refetch();
      }
      if (submitErrors) {
        submitErrors.forEach((err) => {
          errors[err.field] = err.messages;
        });
      }
    });
    return errors;
  };

  if (meLoading) return <LinearProgressMosaic loading={meLoading} />;

  return (
    <Box className={classes.container}>
      <Box
        bgcolor="background.paper"
        p={{ xs: '20px', md: '50px' }}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={{
            nickname: me.nickname,
            email: me.email,
            firstName: me.firstName,
            lastName: me.lastName,
            userTimezone: me.userTimezone,
            oldPassword: me.oldPassword,
            newPassword: me.newPassword,
            keepAnonymous: me.keepAnonymous,
            isConsentToReceiveInfo: me.isConsentToReceiveInfo,
          }}
          render={({
            handleSubmit,
            submitting,
          }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <Box
                display="flex"
                flexDirection="column"
              >
                <Box className={classes.header}>
                  <Box className={classes.title}>Profile Settings</Box>
                  <SubmitButton
                    loading={loading}
                    submitting={submitting}
                    className={classes.submitBtn}
                  >
                    Save
                  </SubmitButton>
                </Box>
                {/* <Box className={classes.avatarBox}>
                  <Box
                    display="flex"
                    alignItems="center"
                    mb={{ xs: '10px', md: '0' }}
                  >
                    <Box className={classes.avatar}>
                      {me.avatar
                        ? <img alt="avatar" width="100px" height="auto" src={me.avatar} />
                        : <img alt="avatar placeholder" width="50px"
                          height="50px" src="/images/icons/profile.svg" />}
                    </Box>
                    <Box>
                      <Box
                        fontSize="18px"
                        fontWeight="bold"
                        lineHeight="23px"
                        color="text.primary"
                        mb="8px"
                      >
                        Change Avatar
                      </Box>
                      <Box
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="18px"
                        color="text.secondary"
                      >
                        Photos should be square and at least 400x400 pixels
                      </Box>
                    </Box>
                  </Box>
                  <FormFileField
                    fieldName="avatarFile"
                    label="Upload"
                    accept="image/*"
                    className={classes.uploadBtn}
                  />
                </Box> */}
                <Box className={classes.formBox}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        fieldName="nickname"
                        fieldType="text"
                        label="User Nickname"
                        variant="outlined"
                        size="small"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        fieldName="email"
                        fieldType="email"
                        label="Email Address"
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        fieldName="firstName"
                        fieldType="text"
                        label="First Name"
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        fieldName="lastName"
                        fieldType="text"
                        label="Last Name"
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    {/* TODO Remove from system deanonimizer */}
                    {/* <Grid item xs={12}>
                      <FormCheckboxField
                        label="Keep my profile anonymous and use nickname instead of my real name"
                        fieldName="keepAnonymous"
                        disableRipple
                        className={classes.checkbox}
                      />
                    </Grid> */}
                    {/* <Grid item xs={12}>
                      <FormCheckboxField
                        label="I consent to receive info via email"
                        fieldName="isConsentToReceiveInfo"
                        disableRipple
                        className={classes.checkbox}
                      />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                      <FormSelectField
                        fieldName="userTimezone"
                        label="Timezone"
                        options={me.timezoneOptions || []}
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className={classes.pwdBox}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    mb="10px"
                  >
                    <Box
                      className={classes.title}
                      mb={{ xs: '10px', md: '20px' }}
                    >
                      Change Password
                    </Box>
                    <Box
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="18px"
                      color="text.primary"
                      mb="20px"
                    >
                      Please enter your old password, for security&apos;s sake,
                      and then enter your new password so we can verify you typed it in correctly:
                    </Box>
                    <Box
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="18px"
                      color="text.primary"
                    >
                      <ul className={classes.listStyle}>
                        <li>
                          Your password can&apos;t be similar to your other personal information.
                        </li>
                        <li>Your password must contain at least 8 characters.</li>
                        <li>Your password can&apos;t be a commonly used password.</li>
                        <li>Your password can&apos;t be entirely numeric.</li>
                      </ul>
                    </Box>
                  </Box>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        fieldName="oldPassword"
                        fieldType="password"
                        label="Old Password"
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        fieldName="newPassword"
                        fieldType="password"
                        label="New Password"
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </form>
          )}
        />
      </Box>
    </Box>
  );
};

export default Profile;
