import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
// import { useTracking } from 'react-tracking';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  INTERVIEW_QUERY,
} from '../../queries/interview/interview';
import { COMPLIANCE_ROLES } from '../../config';
import {
  ADD_KEYWORD_TO_FOLLOWING_MUTATION,
  ALL_FOLLOWING_COMPANIES_QUERY,
  ALL_FOLLOWING_KEYWORDS_QUERY,
} from '../../queries/interview/following';
import { writeFilterData } from '../../actions/filterActions';
import useMe from '../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    color: theme.palette.secondary.main,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    padding: '1px 0',
    minWidth: '10px',
    '&:hover': {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      color: theme.palette.secondary.light,
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiButton-label': {
      textTransform: 'initial',
    },
  },
  item: {
    fontWeight: 'bold',
    lineHeight: '18px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&:active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const Keywords = ({
  keywordId, uuid, tickerUUID, tickerId, tickerTitle, title, interviewId, isAddedToWatchList,
  handleWriteFilterData,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const location = useLocation();
  // const { trackEvent } = useTracking();
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useMe();

  const history = useHistory();

  const matchInterview = useRouteMatch({ path: '/:interviewId', exact: true });
  const matchHome = useRouteMatch({ path: '/', exact: true });

  const isInterviewPage = !isEmpty(matchInterview) || !isEmpty(matchHome);

  const [addKeyword] = useMutation(ADD_KEYWORD_TO_FOLLOWING_MUTATION, {
    refetchQueries: [
      { query: ALL_FOLLOWING_COMPANIES_QUERY },
      { query: ALL_FOLLOWING_KEYWORDS_QUERY },
      { query: INTERVIEW_QUERY, variables: { interviewId } },
    ],
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddCompany = () => {
    addKeyword({
      variables: { interviewId, title },
    }).then(({ data: { addKeywordToFollowingMutation = {} } = {} }) => {
      if (!isEmpty(addKeywordToFollowingMutation.errors)) {
        enqueueSnackbar(addKeywordToFollowingMutation.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        enqueueSnackbar('Added to Watchlist.',
          { variant: 'success', autoHideDuration: 4000 });
      }
    });
    handleClose();
  };

  const handleFilter = (key, value) => {
    if (!isInterviewPage) {
      history.push('/');
    }
    handleWriteFilterData({ [key]: value });
    handleClose();
  };

  const renderAddToFollowingBtn = () => {
    if (isEmpty(me) || COMPLIANCE_ROLES.includes(me.role) || isAddedToWatchList) return null;

    return (
      <MenuItem
        onClick={handleAddCompany}
        disableRipple
        className={classes.item}
      >
        Add to Watchlist
      </MenuItem>
    );
  };

  return (
    <Box mb="8px" mr="10px">
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple
        className={classes.button}
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderAddToFollowingBtn()}

        {tickerId
          ? (
            <MenuItem
              disableRipple
              onClick={() => handleFilter('tickers', [{ id: tickerId, uuid: tickerUUID, title: tickerTitle }])}
              className={classes.item}
            >
              Filter by this Company
            </MenuItem>
          )
          : (
            <MenuItem
              disableRipple
              onClick={() => handleFilter('keywords', [{ id: keywordId, title, uuid }])}
              className={classes.item}
            >
              Filter by this Keyword
            </MenuItem>
          )}
      </Menu>
    </Box>
  );
};

Keywords.defaultProps = {
  tickerTitle: '',
  tickerId: '',
  isAddedToWatchList: false,
  tickerUUID: '',
};

Keywords.propTypes = {
  tickerTitle: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  tickerUUID: PropTypes.string,
  tickerId: PropTypes.string,
  keywordId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  isAddedToWatchList: PropTypes.bool,
  handleWriteFilterData: PropTypes.func.isRequired,
};

export default connect(
  null,
  { handleWriteFilterData: writeFilterData },
)(Keywords);
