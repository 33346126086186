import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexGrow: '1',
  },
  errorBlock: {
    position: 'relative',
    maxWidth: '540px',
    width: '100%',
    padding: '20px',
    textAlign: 'center',
  },
  errorBlockImage: {
    width: '100%',
    marginBottom: '20px',
    '& svg': {
      width: '100%',
      height: 'auto',
    },
  },
  errorMessage: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '30px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom: '20px',
  },
  btn: {
    display: 'inline-block',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '11px 20px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <Box className={classes.errorBlock}>
        <Box className={classes.errorBlockImage}>
          <img alt="page not found" src="/images/page_not_found.svg" />
        </Box>
        <Box className={classes.errorMessage}>
          Oops! Sorry page does not found...
        </Box>
        <Link to="/" className={classes.btn}>
          Return to Interviews
        </Link>
      </Box>
    </Box>
  );
};

export default NotFound;
