import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { INTERVIEW_QUERY } from '../../queries/interview/interview';
import { COMPLIANCE_ROLES } from '../../config';
import {
  ADD_COMPANY_TO_FOLLOWING_MUTATION,
  ALL_FOLLOWING_COMPANIES_QUERY,
} from '../../queries/interview/following';
import { writeFilterData } from '../../actions/filterActions';
import useMe from '../../hooks/useMe';
// import { mostPopular } from '../../constants/orderingOptions';

const useStyles = makeStyles((theme) => ({
  ticker: {
    display: 'block',
    height: '22px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    textAlign: 'center',
    padding: '0 8px',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      backgroundColor: theme.palette.info.main,
    },
  },
  item: {
    fontWeight: 'bold',
    lineHeight: '18px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&:active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const Tickers = ({
  uuid, tickerId, title, interviewId, isAddedToWatchList, handleWriteFilterData, company,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const matchInterview = useRouteMatch({ path: '/:interviewId', exact: true });
  const matchHome = useRouteMatch({ path: '/', exact: true });

  const isInterviewPage = !isEmpty(matchInterview) || !isEmpty(matchHome);

  const { me } = useMe();

  const [addCompany] = useMutation(ADD_COMPANY_TO_FOLLOWING_MUTATION, {
    refetchQueries: [
      { query: ALL_FOLLOWING_COMPANIES_QUERY },
      { query: INTERVIEW_QUERY, variables: { interviewId } },
    ],
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddCompany = () => {
    if (company) {
      addCompany({
        variables: { companyId: company },
      }).then(({ data: { addCompanyToFollowingMutation = {} } = {} }) => {
        if (!isEmpty(addCompanyToFollowingMutation.errors)) {
          enqueueSnackbar(addCompanyToFollowingMutation.errors.map((err) => (
            `${err.field}: ${err.messages.join(', ')}`
          )),
          { variant: 'error', autoHideDuration: 4000 });
        } else {
          enqueueSnackbar('Added to Watchlist.',
            { variant: 'success', autoHideDuration: 4000 });
        }
      });
      handleClose();
    }
  };

  const handleFilter = () => {
    if (!isInterviewPage) {
      history.push('/');
    }
    const extraData = {};
    // if (filterData.o === null) {
    //   extraData.o = mostPopular;
    // }
    handleWriteFilterData({
      tickers: [{ id: tickerId, title, uuid }],
      ...extraData,
    });
    handleClose();
  };

  const renderAddToFollowingBtn = () => {
    if (isEmpty(me) || COMPLIANCE_ROLES.includes(me.role) || isAddedToWatchList) return null;

    return (
      <MenuItem
        onClick={handleAddCompany}
        disableRipple
        className={classes.item}
      >
        Add to Watchlist
      </MenuItem>
    );
  };

  return (
    <Box mr="10px" mb="8px">
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disableRipple
        className={classes.ticker}
      >
        {title}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderAddToFollowingBtn()}

        <MenuItem
          disableRipple
          className={classes.item}
          onClick={handleFilter}
        >
          Filter by this Company
        </MenuItem>
      </Menu>
    </Box>
  );
};

Tickers.defaultProps = {
  isAddedToWatchList: false,
  // company: '',
};

Tickers.propTypes = {
  uuid: PropTypes.string.isRequired,
  tickerId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  isAddedToWatchList: PropTypes.bool,
  handleWriteFilterData: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired,
};

export default connect(
  null,
  { handleWriteFilterData: writeFilterData },
)(Tickers);
