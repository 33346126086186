import { gql } from '@apollo/client';

export const ALL_FOLLOWING_TICKERS_QUERY = gql`
{
  allTickerWatchList {
    id
    notSeenInterviewsCount
    notify
    emailAlerts
    ticker {
      id
      uuid
      title
      description
    }
  }
}
`;

export const ALL_FOLLOWING_COMPANIES_QUERY = gql`
{
  allCompanyWatchList {
    id
    notSeenInterviewsCount
    notify
    emailAlerts
    company {
      id
      title
      tickers {
        title
        id
      }
    }
  }
}
`;

export const ALL_FOLLOWING_KEYWORDS_QUERY = gql`
{
  allKeywordWatchList {
    id
    notSeenInterviewsCount
    notify
    emailAlerts
    keyword {
      id
      uuid
      title
    }
  }
}
`;

export const ALL_FOLLOWING_INDUSTRIES_QUERY = gql`
{
  allIndustriesWatchList {
    id
    notSeenInterviewsCount
    notify
    emailAlerts
    industry{
      id
      uuid
      title
    }
  }
}
`;

export const ALL_FOLLOWING_SECTORS_QUERY = gql`
{
  allSectorsWatchList {
    id
    notSeenInterviewsCount
    notify
    emailAlerts
    sector {
      id
      uuid
      title
    }
  }
}
`;

export const ADD_COMPANY_TO_FOLLOWING_MUTATION = gql`
mutation addCompanyToFollowing($tickerTitle: String, $companyId: Int) {
  addCompanyToFollowing(tickerTitle: $tickerTitle, companyId: $companyId) {
    companyWatch {
      id
      company {
        id
      }
    }
  }
}
`;

export const ADD_KEYWORD_TO_FOLLOWING_MUTATION = gql`
mutation addKeywordToFollowing($title: String) {
  addKeywordToFollowing(title: $title) {
    keywordWatch {
      id
    }
  }
}
`;

export const ADD_INDUSTRY_TO_FOLLOWING_MUTATION = gql`
mutation addIndustryToFollowing($itemId: String) {
  addIndustryToFollowing(itemId: $itemId) {
    industryWatch{
      id
    }
  }
}
`;

export const ADD_SECTOR_TO_FOLLOWING_MUTATION = gql`
mutation addSectorToFollowing($itemId: String) {
  addSectorToFollowing(itemId: $itemId) {
    sectorWatch{
      id
    }
  }
}
`;

export const DELETE_COMPANY_FOLLOWING = gql`
mutation deleteCompanyFollowing($followingId: Int!){
  deleteCompanyFollowing(followingId: $followingId){
    deleted
    companyWatch{
      company{
        id
      }
    }
  }
}
`;

export const DELETE_KEYWORD_FOLLOWING = gql`
mutation deleteKeywordFollowing($followingId: Int!){
  deleteKeywordFollowing(followingId: $followingId){
    deleted
  }
}
`;

export const DELETE_INDUSTRY_FOLLOWING = gql`
mutation deleteIndustryFollowing($followingId: Int!){
  deleteIndustryFollowing(followingId: $followingId){
    deleted
  }
}
`;

export const DELETE_SECTOR_FOLLOWING = gql`
mutation deleteSectorFollowing($followingId: Int!){
  deleteSectorFollowing(followingId: $followingId){
    deleted
  }
}
`;

export const UPDATE_COMPANY_MUTATION = gql`
mutation updateCompanyMutation($id: ID, $company: ID!, $notify: String!, $emailAlerts: Boolean) {
  updateCompanyMutation(input: {id: $id, company: $company, notify: $notify, emailAlerts: $emailAlerts}) {
    companyWatchList {
      id
      emailAlerts
      company {
        id
      }
    }    
    errors{
      field
      messages
    }
  }      
}        
`;

export const UPDATE_KEYWORD_MUTATION = gql`
mutation updateKeywordMutation($id: ID, $keyword: ID!, $notify: String!, $emailAlerts: Boolean) {
  updateKeywordMutation(input: {id: $id, keyword: $keyword, notify: $notify, emailAlerts: $emailAlerts}) {
    keywordWatchList {
      id
      emailAlerts
      keyword {
        id
      }
    }    
    errors{
      field
      messages
    }
  }      
}        
`;

export const UPDATE_INDUSTRY_MUTATION = gql`
mutation updateIndustryMutation($input: IndustryFollowingMutationInput!) {
  updateIndustryMutation(input: $input) {
    industryWatchList {
      id
      emailAlerts
      industry {
        id
      }
    }    
    errors{
      field
      messages
    }
  }      
}        
`;

export const UPDATE_SECTOR_MUTATION = gql`
mutation updateSectorMutation($input: SectorFollowingMutationInput!) {
  updateSectorMutation(input: $input) {
    sectorWatchList {
      id
      emailAlerts
      sector {
        id
      }
    }
    errors {
      field
      messages
    }
  }
}
`;
