/* eslint-disable max-len */
/* eslint-disable no-irregular-whitespace */
import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    marginBottom: '20px',
  },
  shortText: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
  },
  button: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.secondary.main,
    padding: '0',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.dark,
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.light,
    },
  },
}));

const LegalDisclaimer = ({
  isExpanded,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(isExpanded);

  const toggleLines = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      pb="10px"
      id="legalDisclaimer"
    >
      {(!expanded)
        ? (
          <>
            <HTMLEllipsis
              unsafeHTML="The content of this research transcript is distributed by Mosaic Research Management (MRM)
                for informational purposes only. It is neither investment advice nor a recommendation of any particular
                security, strategy or investment product. The content of this research transcript reflects the views,
                opinions, analysis and projections of the Consultant. MRM has not verified the accuracy of the
                Consultant’s views, opinions, analysis or projections. The information is provided “AS IS” and without
                warranties of any kind either expressed or implied. To the fullest extent permissible pursuant to
                applicable law, MRM disclaims all warranties, including, but not limited to, implied warranties of
                merchantability and fitness for a particular purpose. MRM expressly disclaims all liability for errors
                and omissions in the content of this research transcript."
              maxLine="2"
              ellipsis="..."
              basedOn="letters"
              className={classes.shortText}
            />
            <Box>
              <Button
                onClick={toggleLines}
                disableRipple
                className={classes.button}
              >
                Read more
              </Button>
            </Box>
          </>
        )
        : (
          <>
            <Box className={classes.text}>
              The content of this research transcript is distributed by Mosaic Research Management (MRM) for informational
              purposes only. It is neither investment advice nor a recommendation of any particular security, strategy or
              investment product. The content of this research transcript reflects the views, opinions, analysis and
              projections of the Consultant. MRM has not verified the accuracy of the Consultant’s views, opinions,
              analysis or projections. The information is provided “AS IS” and without warranties of any kind either
              expressed or implied. To the fullest extent permissible pursuant to applicable law, MRM disclaims all
              warranties, including, but not limited to, implied warranties of merchantability and fitness for a particular
              purpose. MRM expressly disclaims all liability for errors and omissions in the content of this research
              transcript. Except as otherwise permitted by MRM in writing or shared using the functionality within the Stream
              user interface, neither the research transcript nor any portion of the research transcript may be copied,
              reproduced, republished, uploaded, posted, transmitted, or distributed in any way. While MRM has taken
              precautions (as described below in the Process Summary) to ensure that Consultants do not share confidential
              or material non-public information, MRM does not guarantee that the research transcript does not contain
              confidential or material non-public information, and disclaims responsibility for trading on the basis of
              the research transcript.
            </Box>
            <Box className={classes.text}>
              MRM Process Summary:
            </Box>
            <Box className={classes.shortText}>
              This research transcript was produced pursuant to Mosaic Research Management’s (MRM) procedures for research
              transcripts. MRM’s procedures are described more fully in MRM’s Compliance Process Description: Research
              Transcript Publication, which is available upon request. In summary, consultants are permitted to consult,
              including consulting for the purpose of producing this transcript, only where the consultant does not have
              a duty or obligation that conflict with the production of this transcript. The consultant is required to
              complete the MRM Consultant Compliance Agreement at the inception of the relationship with MRM. Thereafter,
              prior to participating in any consultation including this consultation, MRM administers the written MRM
              Pre-Consultation Compliance Questionnaire. After the completion of the recorded consultation, the recording
              is transcribed and copy-edited. Prior to publication of this research transcript, the content is reviewed for
              compliance purposes. The compliance review consists of a review to identify a multitude of potential regulatory
              or contractual issues. For example, the review seeks to identify instances where the consultant reveals
              confidential information or potentially material non-public information, indications that the consultant has
              obtained information from sources that are prohibited by a duty or obligation from disclosing such information,
              or indications that the consultant’s responses to the MRM Pre-Consultation Compliance Questionnaire are not
              accurate. Nevertheless, MRM cannot guarantee that the research transcript does not contain confidential
              or material non-public information.
            </Box>
          </>
        )}
      {(expanded)
      && (
        <Box>
          <Button
            onClick={toggleLines}
            disableRipple
            className={classes.button}
          >
            Show less
          </Button>
        </Box>
      )}
    </Box>
  );
};

LegalDisclaimer.defaultProps = {
  isExpanded: false,
};

LegalDisclaimer.propTypes = {
  isExpanded: PropTypes.bool,
};

export default LegalDisclaimer;
