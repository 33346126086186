import React from 'react';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';

const OrderingSelect = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      height: '30px',
      '& .MuiSelect-root': {
        backgroundColor: 'transparent',
        padding: '6px 24px 6px 0!important',
        minHeight: '18px',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        color: theme.palette.primary.main,
      },
      '& svg': {
        position: 'absolute',
        right: '7px',
        pointerEvents: 'none',
      },
      '& fieldset': {
        border: 'none',
      },
      '&:hover': {
        '& .MuiSelect-root': {
          color: theme.palette.primary.dark,
        },
        '& svg path': {
          stroke: theme.palette.text.secondary,
        },
      },
      '&.Mui-focused': {
        '& svg path': {
          stroke: theme.palette.primary.main,
        },
      },
    },
  },
}))(FormControl);

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginRight: '6px',
  },
  item: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.common.white,
        },
      },
    },
  },
}));

const selectIcon = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.16634 0.857141L4.99967 5.14285L0.833008 0.85714" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const CompanyOrdering = ({ options, handleChange }) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" flexGrow="1">
      <Box className={classes.label}>Sort by:</Box>
      <OrderingSelect>
        <Select
          defaultValue={options[0]}
          variant="outlined"
          IconComponent={selectIcon}
          onChange={(event) => handleChange(event.target.value.value)}
        >
          {options.map((option) => (
            <MenuItem
              className={classes.item}
              key={option.value}
              value={option}
              disableRipple
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </OrderingSelect>
    </Box>
  );
};

CompanyOrdering.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default CompanyOrdering;
