import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { useRouteMatch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Sidebar, Topbar } from './components';
import { closeSideBar, hideSideBar } from '../../actions/sidebarActions';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '71px',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  intContent: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
  },
}));

const Main = (props) => {
  const { children } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });

  const isFullScreen = !isEmpty(useRouteMatch({ path: '/:interviewId/fullscreen' }));
  const isProfilePage = !isEmpty(useRouteMatch({ path: '/accounts/profile' }));
  const isDiscoveryPage = !isEmpty(useRouteMatch({ path: '/discovery' }));
  const dispatch = useDispatch();
  const handleSidebarTabClose = () => dispatch(closeSideBar());
  const handleHideSideBar = () => dispatch(hideSideBar());
  const matchInterview = useRouteMatch({ path: '/:interviewId', exact: true });
  const matchHome = useRouteMatch({ path: '/', exact: true });
  const isInterviewPage = !isEmpty(matchInterview) || !isEmpty(matchHome);

  useEffect(() => {
    if (isFullScreen || isProfilePage || isDiscoveryPage) {
      handleSidebarTabClose();
    }
    if (isMobile) {
      handleHideSideBar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isFullScreen]);

  return (
    <Box
      className={clsx({
        [classes.root]: true,
      })}
    >
      {isMobile
        && <Topbar /> }
      <Sidebar />
      <main
        className={clsx({
          [classes.content]: true,
          [classes.intContent]: isInterviewPage && !isDiscoveryPage,
        })}
      >
        {children}
      </main>
    </Box>
  );
};

Main.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export default Main;
