import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Link, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { makeStyles } from '@material-ui/styles';
import isEmpty from 'lodash/isEmpty';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CollapseInfo from './CollapseInfo';
import Perspective from './Perspective';
import Tickers from './Tickers';
import useMe from '../../hooks/useMe';
import { searchToObject } from '../../helpers/locationSearchToJson';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: '0',
    marginBottom: '12px',
  },
  fDivider: {
    marginTop: '4px',
    marginBottom: '12px',
  },
  vDivider: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.info.dark,
    paddingRight: '10px',
    marginRight: '10px',
  },
  nDivider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  tickerDesc: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '22px',
    color: theme.palette.text.primary,
    marginRight: '15px',
    marginBottom: '8px',
  },
  headline: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
      lineHeight: '28px',
    },
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
  },
  mostProminent: {
    backgroundColor: '#F9FBFF',
    borderLeft: '2px solid',
    borderLeftColor: theme.palette.primary.main,
    marginLeft: 0,
    padding: '2px 0 4px 15px',
    '& ul': {
      listStyleType: 'none',
      '& li:before': {
        content: '"-"',
        marginLeft: '-20px',
        marginRight: '10px',
      },
    },
  },
  continueBtn: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    textAlign: 'center',
    padding: '5px 24px',
    '&:hover': {
      color: theme.palette.primary.dark,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.common.white,
    },
  },
  moreItem: {
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    backgroundColor: '#F9FBFF',
    padding: '16px 10px',
    marginBottom: '16px',
    [theme.breakpoints.up('md')]: {
      padding: '16px',
    },
  },
}));

const EndlessScrollPreview = ({ tickers, interviewId }) => {
  const classes = useStyles();
  const { me } = useMe();
  const location = useLocation();
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent({
      viewName: 'interview:endless_scroll_preview_link',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
      extraData: JSON.stringify({ interviewId }),
    });
  };

  return (
    <Box mt="75px" pb="5px" id="endlessScrollPreview">
      {tickers.map((ticker) => {
        const tickerInterviews = ticker.ticker.interviews.results.filter(
          (i) => i.interviewId !== interviewId,
        );

        return (
          tickerInterviews.map((interview) => (
            <Box
              key={interview.id}
              className={classes.moreItem}
            >
              {!isEmpty(interview.tickers)
              && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  {(!isEmpty(interview.tickers[0])
                    && !isEmpty(interview.tickers[0].ticker.description))
                  && (
                    <Box className={classes.tickerDesc}>
                      {interview.tickers[0].ticker.description}
                    </Box>
                  )}

                  {interview.tickers.map((t) => (
                    <Tickers
                      key={t.ticker.id}
                      uuid={t.ticker.uuid}
                      tickerId={t.ticker.id}
                      title={t.ticker.title}
                      interviewId={interview.id}
                      company={t.ticker?.company?.id}
                      isAddedToWatchList={t.ticker.isAddedToWatchList}
                    />
                  ))}
                </Box>
              )}

              <Box mb="8px">
                {!isEmpty(interview.perspective)
                && (
                  <Perspective>
                    {interview.perspective}
                  </Perspective>
                )}

                {(!isEmpty(interview.perspective) && me.role !== 'EXPERT')
                && (
                  <Box component="span" className={classes.vDivider} />
                )}

                {me.role !== 'EXPERT'
                && (
                  <Box component="span" className={classes.title}>
                    {interview.relevantRole}
                  </Box>
                )}
              </Box>

              <Divider className={classes.fDivider} />

              {!isEmpty(interview.headline)
              && (
                <Box mb="8px">
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.headline}
                  >
                    {interview.headline}
                  </Typography>
                </Box>
              )}

              <Box>
                {(!isEmpty(interview.summary) && interview.userCanViewInterview && me.hasPaidAccess)
                && (
                  <Box mb="8px" className={classes.subtitle}>
                    <Box mb="6px">
                      Call Summary:
                    </Box>
                    <CollapseInfo className={classes.mostProminent}>
                      {interview.summary || 'No Information'}
                    </CollapseInfo>
                  </Box>
                )}

                {(!isEmpty(interview.expert) && me.role !== 'EXPERT')
                && (
                  <Box mb="8px" className={classes.subtitle}>
                    <Box mb="6px">
                      Expert Bio:
                    </Box>
                    <CollapseInfo>
                      {interview.expert.bio || 'No Information'}
                    </CollapseInfo>
                  </Box>
                )}

                <Box display="flex" justifyContent="center">
                  <Link
                    className={classes.continueBtn}
                    to={`/${interview.interviewId}`}
                    onClick={handleClick}
                  >
                    Continue Reading
                  </Link>
                </Box>
              </Box>
            </Box>
          ))
        );
      })}
    </Box>
  );
};

EndlessScrollPreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tickers: PropTypes.arrayOf(PropTypes.object).isRequired,
  interviewId: PropTypes.string.isRequired,
};

export default EndlessScrollPreview;
