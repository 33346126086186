import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import sidebarTabs from '../../constants/sidebarTabs';
import {
  openCompanies,
  openFollowing,
  openReading,
} from '../../actions/sidebarActions';

const SidebarTabsInitial = ({
  handleOpenCompanies,
  handleOpenWatchlist,
  handleOpenReadingList,
}) => {
  const { tabname } = useParams();
  const history = useHistory();

  const tabHandlers = {
    [sidebarTabs.companies]: handleOpenCompanies,
    [sidebarTabs.following]: handleOpenWatchlist,
    [sidebarTabs.reading]: handleOpenReadingList,
  };
  useEffect(() => {
    if (tabname) {
      tabHandlers[tabname]();
      history.push('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabname]);
  return null;
};

export default connect(
  null,
  {
    handleOpenCompanies: openCompanies,
    handleOpenWatchlist: openFollowing,
    handleOpenReadingList: openReading,
  },
)(SidebarTabsInitial);
