import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { useMediaQuery } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import {
  useHistory, useLocation, useParams,
} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTracking } from 'react-tracking';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
// import { LinearProgressMosaic } from '../../components';
import isEqual from 'lodash/isEqual';
import { ALL_COMPANIES_PARENTS_QUERY } from '../../queries/interview/interview';
import { getFiltersData } from '../../helpers/filterHelpers';
import InterviewDesktop from './InterviewDesktop';
import InterviewMobile from './InterviewMobile';
import { tokenName } from '../../config';
import { searchToObject } from '../../helpers/locationSearchToJson';
import sidebarTabs from '../../constants/sidebarTabs';
// import { openCompanies, showSideBar } from '../../actions/sidebarActions';
import { showSideBar } from '../../actions/sidebarActions';
import { writeFilterData } from '../../actions/filterActions';
import useAllIntervies from '../../hooks/useAllIntervies';
import useMe from '../../hooks/useMe';
import { clearAll } from '../../actions/bulkSelectActions';
// import TopContext from '../../context/topContext';
// ogurets

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  shiftContent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '280px',
    },
  },
}));

const InterviewContent = ({
  userIsNotEmpty, role, activeTab, children,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: Boolean(activeTab) && userIsNotEmpty && role !== 'COMPLIANCE',
      })}
    >
      {/* <LinearProgressMosaic loading={loading} /> */}
      {children}
    </Box>
  );
};

InterviewContent.propTypes = {
  userIsNotEmpty: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const Interview = () => {
  // ogurets
  // const [loading, setLoading] = useState(false);
  const { interviewId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const [oldRlSelected, setOldRlSelected] = useState(null);
  // TODO need for refactoring
  const [oldState, setOldState] = useState(null);
  // const context = useContext(TopContext);

  const activeTab = useSelector((state) => state.sideBar.activeTab, isEqual);
  const filterData = useSelector((state) => state.filterState.filterData, isEqual);
  const dispatch = useDispatch();

  const handleSidebarOpen = () => dispatch(showSideBar());
  const handleWriteFilterData = (data) => dispatch(writeFilterData(data));

  const query = new URLSearchParams(location.search);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });

  const { me, refetch: refetchMe } = useMe();

  useEffect(() => {
    query.delete('first');
    query.delete('skip');
    history.push({ search: query.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  const interviewAutoDisplayed = (path) => {
    // redirects user to interview  by passed ID as parameter, sets state as displayed automatically
    history.push({
      pathname: path,
      state: { auto_displayed: true },
    });
  };

  const {
    loading, allInterviews, called,
  } = useAllIntervies(activeTab, filterData);

  const selected = useSelector((state) => state.bulkSelectedState.selected, isEqual);

  const [getAllCompanies, {
    data: { allCompanies = {} } = {},
    loading: filtersLoading,
  }] = useLazyQuery(ALL_COMPANIES_PARENTS_QUERY, {
    variables: {
      first: 30,
      skip: 0,
      search: filterData.search,
    },
  });

  useEffect(() => {
    if (!filtersLoading) {
      getAllCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData.search]);

  const isSelected = (intId) => selected.includes(intId);

  // why doesnt is work ??????
  useEffect(() => {
    if (allInterviews && called) {
      if (!isMobile) {
        // open first interview if no interviewId in url
        if (!interviewId && allInterviews.results && allInterviews.results.length > 0) {
          return (interviewAutoDisplayed(`/${allInterviews.results[0].interviewId}`));
        }
      }
      trackEvent({
        viewName: 'interview:interviews_list',
        path: location.pathname,
        getDict: JSON.stringify(searchToObject(location.search)),
        postDict: JSON.stringify(getFiltersData(filterData)),
        token: localStorage.getItem(tokenName) || '', // token is not tracked after redirect from login page
      });

      if (activeTab === sidebarTabs.following) {
        // we need to update Me to have correct number of unread interviews
        refetchMe();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allInterviews, called]);

  useEffect(() => {
    if (selected.length > 0) {
      dispatch(clearAll());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, filterData]);

  const handleFilter = (key, value) => {
    handleWriteFilterData({ [key]: value });
  };

  // select previously used reading list
  useEffect(() => {
    setOldState(activeTab);
    if (filterData.readingLists.length > 0) {
      setOldRlSelected(filterData.readingLists[0]);
    }
    if (!isEmpty(oldRlSelected) && activeTab === 'reading' && oldState !== 'reading') {
      handleFilter('readingLists', [oldRlSelected]);
    }
  // eslint-disable-next-line
  }, [activeTab, oldState, filterData.readingLists]);

  const isReadingList = filterData.readingLists.length > 0;
  const [locationKeys, setLocationKeys] = useState([]);

  useEffect(() => history.listen((path) => {
    if (history.action === 'PUSH') {
      setLocationKeys([path.key]);
    }

    if (history.action === 'POP') {
      if (locationKeys[1] === path.key) {
        // setLocationKeys(([_, ...keys]) => keys);
        console.log('forvard pressed');
        // Handle forward event
      } else {
        setLocationKeys((keys) => [path.key, ...keys]);
        // Handle back event
        document.location.reload();
      }
    }
  })
  // eslint-disable-next-line
  , [ locationKeys, ])

  if (isMobile) {
    return (
      <InterviewContent
        me={me}
        loading={loading}
        activeTab={activeTab}
        userIsNotEmpty={!isEmpty(me)}
        role={me.role}
      >
        <InterviewMobile
          interviewId={interviewId}
          loading={loading}
          handleSidebarOpen={handleSidebarOpen}
          showSearchCounts={Boolean(filterData.search)}
          companiesCount={allCompanies.totalCount}
          allInterviews={allInterviews || {}}
          selected={selected}
          isSelected={isSelected}
          isReadingList={isReadingList}
          handleWriteFilterData={handleWriteFilterData}
          me={me}
        />
      </InterviewContent>
    );
  }

  return (
    <InterviewContent
      loading={loading}
      activeTab={activeTab}
      userIsNotEmpty={!isEmpty(me)}
      role={me.role || ''}
    >
      <InterviewDesktop
        interviewId={interviewId}
        loading={loading}
        showSearchCounts={Boolean(filterData.search)}
        companiesCount={allCompanies.totalCount}
        allInterviews={allInterviews || {}}
        selected={selected}
        isSelected={isSelected}
        isReadingList={isReadingList}
        handleWriteFilterData={handleWriteFilterData}
      />
    </InterviewContent>
  );
};

export default Interview;
