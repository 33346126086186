import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { REJECT_FEEDBACK_MUTATION } from '../../queries/outreachIntergration';

const TextareaField = withStyles((theme) => ({
  root: {
    marginBottom: '20px',
    '& .MuiInputBase-root': {
      borderRadius: '5px',
      padding: '10px 16px',
      backgroundColor: theme.palette.background.default,
      minHeight: '120px',
      '& textarea': {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        color: theme.palette.text.primary,
      },
      '& fieldset': {
        borderColor: theme.palette.info.main,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.primary.main,
          },
        },
      },
      '&.Mui-disabled': {
        '& input': {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.default,
        },
        '& fieldset': {
          borderColor: theme.palette.info.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.info.main,
          },
        },
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  submitBtn: {
    height: '40px',
    padding: '10px 50px',
    color: theme.palette.common.white,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const RejectionFeedbackForm = ({ placeholder, reason }) => {
  const [value, setValue] = useState('');
  const { hashStr } = useParams();
  const classes = useStyles();
  const [rejectFeedbackFunc] = useMutation(REJECT_FEEDBACK_MUTATION);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    rejectFeedbackFunc({
      variables: {
        hashStr,
        feedback: value,
        reason,
      },
    }).then(({ data: { rejectFeedback = {} } = {} }) => {
      if (!isEmpty(rejectFeedback.errors)) {
        enqueueSnackbar(rejectFeedback.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        history.push(`/not-interested-in-trial-done/${hashStr}`);
      }
    });
  };

  return (
    <>
      <TextareaField
        multiline
        value={value}
        onChange={handleValueChange}
        rows={7}
        placeholder={placeholder}
        variant="outlined"
      />
      <Box>
        <Button
          className={classes.submitBtn}
          disableRipple
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

RejectionFeedbackForm.propTypes = {
  placeholder: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
};

export default RejectionFeedbackForm;
