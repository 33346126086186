import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { showSideBar } from '../../../../actions/sidebarActions';

const useStyles = makeStyles((theme) => ({
  burger: {
    height: '40px',
    padding: '10px',
    minWidth: '40px',
    backgroundColor: theme.palette.common.white,
    marginRight: '10px',
  },
}));

const BurgerButton = ({ handleShowSideBar }) => {
  const classes = useStyles();

  return (
    <>
      <Button
        color="inherit"
        onClick={handleShowSideBar}
        disableRipple
        className={classes.burger}
      >
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.14258 6H14.8569" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.14258 11H14.8569" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.14258 1H14.8569" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </Button>
    </>
  );
};

BurgerButton.propTypes = {
  handleShowSideBar: PropTypes.func.isRequired,
};

export default connect(
  null,
  {
    handleShowSideBar: showSideBar,
  },
)(BurgerButton);
