import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import InterviewActionsMenu from './InterviewActionsMenu';
import InterviewReadingList from './InterviewReadingList';
import FullScreenBtn from '../../../../../../components/InterviewPreviewComponents/FullScreenBtn';
import PrintBtn from '../../../../../../components/InterviewPreviewComponents/PrintBtn';
import ShareSocial from '../../../../../../components/InterviewPreviewComponents/ShareSocial';
import useMe from '../../../../../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  actionsBox: {
    width: '100%',
    height: '40px',
  },
  actionsChild: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    paddingTop: '10px',
    paddingBottom: '5px',
  },
  iconBtn: {
    display: 'flex',
    height: '30px',
    padding: '4px 5px',
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.palette.info.main,
    backgroundColor: theme.palette.common.white,
    marginRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.common.white,
      },
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  shareBtn: {
    padding: '4px',
  },
}));

const InterviewActions = ({
  interviewId, interviewPK, readingListItem, isFullScreen, isDesktop,
  isPrintAllowed, isMarkUnreadAllowed, isExpert, isCallRequestAllowed,
  isInviteGuestsAllowed, expertPk,
}) => {
  const classes = useStyles();
  const { me } = useMe();

  return (
    <Box>
      <Box
        mb="10px"
        id="interviewActions"
        className={classes.actionsBox}
      >
        <Box
          display="flex"
          justifyContent={isExpert ? 'flex-end' : 'space-between'}
          alignItems="center"
          className={classes.actionsChild}
        >
          {!isExpert
          && (
            <InterviewActionsMenu
              isCallRequestAllowed={isCallRequestAllowed}
              isMarkUnreadAllowed={isMarkUnreadAllowed}
              isInviteGuestsAllowed={isInviteGuestsAllowed}
              expertPk={expertPk}
              interviewPk={interviewPK}
              interviewId={interviewId}
            />
          )}
          <Box display="flex">
            <ShareSocial
              interviewId={interviewId}
              className={clsx(
                classes.iconBtn,
                classes.shareBtn,
              )}
            />
            {isPrintAllowed
            && (
              <PrintBtn
                id={interviewPK}
                selected={[interviewId]}
                className={classes.iconBtn}
              >
                Export to PDF
              </PrintBtn>
            )}
            {me.role !== 'COMPLIANCE'
            && (
              <InterviewReadingList
                readingListItem={readingListItem}
                interviewIds={[interviewId]}
                isButton
              />
            )}
            {(isDesktop && !isFullScreen)
            && (
              <FullScreenBtn interviewId={interviewId} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

InterviewActions.defaultProps = {
  isPrintAllowed: false,
  isMarkUnreadAllowed: false,
  isInviteGuestsAllowed: false,
  isExpert: false,
  readingListItem: {},
};

InterviewActions.propTypes = {
  interviewId: PropTypes.string.isRequired,
  interviewPK: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  readingListItem: PropTypes.object,
  isFullScreen: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isPrintAllowed: PropTypes.bool,
  isMarkUnreadAllowed: PropTypes.bool,
  isInviteGuestsAllowed: PropTypes.bool,
  isExpert: PropTypes.bool,
  isCallRequestAllowed: PropTypes.bool.isRequired,
  expertPk: PropTypes.string.isRequired,
};

export default InterviewActions;
