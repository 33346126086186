import { useState, useEffect } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { singletonHook } from 'react-singleton-hook';

const INITIAL_VALUE = 'Reading List';
const FEATURE_KEY = 'reading_list_title';

const useReadingListTitle = () => {
  const [readingListTitle, setReadingListTitle] = useState(INITIAL_VALUE);
  const [decision] = useDecision(FEATURE_KEY);

  useEffect(() => {
    if (decision.enabled && decision.variables.title) {
      setReadingListTitle(decision.variables.title);
    } else {
      setReadingListTitle(INITIAL_VALUE);
    }
  }, [decision]);

  return readingListTitle;
};

export default singletonHook(INITIAL_VALUE, useReadingListTitle);
