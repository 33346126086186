/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useWindowSize } from '../../hooks';
import { UPDATE_USER_SETTINGS_MUTATION } from '../../queries/account';
import useMe from '../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  playerBox: {
    '& > div': {
      width: '100%',
      height: '52px',
    },
    '& .plyr--audio': {
      zIndex: '3',
      height: '50px',
    },
    '& .plyr__controls': {
      border: '1px solid',
      borderColor: theme.palette.info.main,
      borderRadius: '5px',
      backgroundColor: theme.palette.background.default,
      padding: '8px 10px',
      color: theme.palette.text.primary,
    },
    '& .plyr--audio .plyr__control.plyr__tab-focus': {
      backgroundColor: theme.palette.text.primary,
    },
    '& .plyr--audio .plyr__control:hover': {
      backgroundColor: theme.palette.text.primary,
    },
    '& .plyr--audio .plyr__control[aria-expanded=true]': {
      backgroundColor: theme.palette.text.primary,
    },
    '& .plyr--full-ui input[type=range]': {
      color: theme.palette.primary.main,
    },
    '& .plyr__controls .plyr__controls__item.plyr__time': {
      color: theme.palette.text.primary,
      boxShadow: 'none',
      outline: 'none',
    },
    '& .plyr__control.plyr__tab-focus': {
      boxShadow: 'none',
      outline: 'none',
    },
    '& .plyr__controls__item.plyr__progress__container': {
      boxShadow: 'none',
      outline: 'none',
    },
    '& .plyr__progress': {
      boxShadow: 'none',
      outline: 'none',
    },
    '& .plyr__progress input.plyr__tab-focus': {
      boxShadow: 'none',
      outline: 'none',
    },
    '& .plyr__controls .plyr__controls__item': {
      boxShadow: 'none',
      outline: 'none',
    },
    '& .plyr__menu__container': {
      backgroundColor: theme.palette.common.white,
      top: '100%',
      bottom: 'initial',
      marginBottom: '0',
      marginTop: '10px',
    },
    '& .plyr__menu__container::after': {
      display: 'none',
    },
    '& .plyr__menu__container .plyr__control.plyr__tab-focus': {
      backgroundColor: theme.palette.common.white,
    },
    '& .plyr__menu__container .plyr__control': {
      color: theme.palette.text.primary,
    },
    '& .plyr__menu__container .plyr__control:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    '& .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .plyr__time+.plyr__time': {
      display: 'block!important',
    },
  },
  playerSize: {
    height: '52px',
    width: '100%',
  },
}));

const Player = ({
  url, playerSpeed, playerSpeedsList, playerId,
}) => {
  const classes = useStyles();
  const size = useWindowSize();
  const playerEl = useRef(null);

  const { me } = useMe();
  const [updateSettings] = useMutation(UPDATE_USER_SETTINGS_MUTATION);

  let controls = [
    'play', // Play/pause playback
    'progress', // The progress bar and scrubber for playback and buffering
    'current-time', // The current time of playback
    'duration', // The full duration of the media
    'settings', // Settings menu
  ];

  if (size.width > 767) {
    controls = [
      'rewind', // Rewind by the seek time (default 10 seconds)
      'play', // Play/pause playback
      'fast-forward', // Fast forward by the seek time (default 10 seconds)
      'restart', // Restart playback
      'progress', // The progress bar and scrubber for playback and buffering
      'current-time', // The current time of playback
      'duration', // The full duration of the media
      'mute', // Toggle mute
      'volume', // Volume control
      'settings', // Settings menu
    ];
  } else if (size.width > 413) {
    controls = [
      'rewind', // Rewind by the seek time (default 10 seconds)
      'play', // Play/pause playback
      'fast-forward', // Fast forward by the seek time (default 10 seconds)
      'progress', // The progress bar and scrubber for playback and buffering
      'current-time', // The current time of playback
      'duration', // The full duration of the media
      'settings', // Settings menu
    ];
  }
  const options = {
    iconUrl: '/images/plyr.svg',
    speed: {
      selected: playerSpeed,
      options: playerSpeedsList,
    },
    tooltips: {
      controls: false,
      seek: true,
    },
    invertTime: false,
    controls,
  };

  const history = useHistory();

  const scrollToTimeStamp = (htmlEl) => {
    const tocStamp = htmlEl.textContent;
    const ttsStamps = document.getElementsByClassName('tts');
    ttsStamps.forEach((el) => {
      if (tocStamp === el.textContent) {
        el.scrollIntoView({ block: 'center' });
        const stampRow = el.parentNode.parentNode;
        stampRow.classList.add('flash');
        setTimeout(() => {
          stampRow.classList.remove('flash');
        }, 1100);
      }
    });
  };

  useEffect(() => {
    // Internet Explorer 6-11
    const isIE = !!document.documentMode;
    let player = null;

    if (!isIE) {
      player = new Plyr(playerEl.current, options);
      if (player !== null) {
        document.onclick = (event) => {
          if (event.target.classList.contains('toc-link') || event.target.classList.contains('tts')) {
            const timeStamp = event.target.textContent.replace(/[[\]]/g, '').split(':');
            player.currentTime = (+timeStamp[0]) * 60 * 60 + (+timeStamp[1]) * 60 + (+timeStamp[2]);
            if (me.hasPaidAccess) {
              player.play();
            } else {
              history.push('/accounts/subscribe');
            }
          }
          if (event.target.classList.contains('toc-link')) {
            scrollToTimeStamp(event.target);
          }
        };

        if (!isEmpty(me)) {
          player.on('ratechange', (event) => {
            const plyrSpeed = event.detail.plyr.speed;
            if (plyrSpeed !== me.settings.playerSpeed) {
              updateSettings({
                variables: {
                  settingsId: me.settings.id,
                  playerSpeed: plyrSpeed,
                },
              });
            }
          });
        }
      }
    }

    return () => {
      if (player !== null && !isIE) {
        player.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      displayPrint="none"
      mb="12px"
      id={playerId || 'audioPlayer'}
      className={clsx(classes.playerSize, classes.playerBox)}
    >
      <audio ref={playerEl} controls controlsList="nodownload" className={classes.playerSize}>
        <source src={url} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </Box>
  );
};

Player.defaultProps = {
  url: '',
  playerSpeed: 1,
  playerSpeedsList: [0.5, 1, 1.2, 1.5, 2],
  playerId: '',
};

Player.propTypes = {
  url: PropTypes.string,
  playerSpeed: PropTypes.number,
  playerSpeedsList: PropTypes.arrayOf(PropTypes.number),
  playerId: PropTypes.string,
};

export default Player;
