import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Slide from '@material-ui/core/Slide';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { PaginationLink } from '../../components';
import Ordering from './components/Ordering/Ordering';
import { InterviewItem, SearchInput, SelectedFilters } from './components';
import InterviewPreview from './components/InterviewPreview';
import InterviewsNotFound from './components/InterviewsNotFound/InterviewsNotFound';
import { Footer } from '../../layouts/Main/components';
import { searchToObject } from '../../helpers/locationSearchToJson';
import InterviewItemSkeleton from './components/InterviewItem/InterviewItemSkeleton';
import { INTERVIEW_PAGINATION_FIRST } from '../../constants/pagination';
import BulkActions from './components/BulkActions/BulkActions';
import SelectAllBtn from './components/SelectAllBtn/SelectAllBtn';

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    height: '100%',
    overflowY: 'scroll',
  },
  content: {
    padding: '10px 20px',
    minHeight: 'calc(100vh - 131px)',
  },
  appBar: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  button: {
    width: '20px',
    height: '20px',
    minWidth: '20px',
    padding: '1px',
    borderRadius: '0',
    backgroundColor: theme.palette.background.default,
    marginLeft: '12px',
    transition: 'all .25s',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      '& svg path': {
        fill: theme.palette.primary.dark,
      },
    },
    '&:active': {
      backgroundColor: theme.palette.background.default,
      '& svg path': {
        fill: theme.palette.primary.light,
      },
    },
    '&.Mui-disabled svg path': {
      fill: theme.palette.info.main,
    },
  },
  backBtn: {
    textTransform: 'capitalize',
  },
  previewFooter: {
    padding: '12px 0',
    '& > div': {
      flexDirection: 'column',
      maxWidth: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  showCount: {
    width: '100%',
    height: '30px',
    padding: '6px 10px',
    marginBottom: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.primary,
    borderRadius: '5px',
  },
  companiesLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

const InterviewMobile = ({
  allInterviews, loading, interviewId,
  showSearchCounts, companiesCount,
  selected, isSelected, isReadingList,
  handleSidebarOpen, handleWriteFilterData, me,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const { trackEvent } = useTracking();

  const bulkActionRef = React.createRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (interviewId) {
      handleClickOpen();
    }
  }, [interviewId]);

  function handleRowWidth(ratingBoxShouldSticky) {
    const actionsBox = document.getElementById('interviewActions');
    const player = document.getElementById('audioPlayer');
    const ratingBox = document.getElementById('transcriptRating');
    const bulkAction = document.getElementById('bulk-actions');

    if (actionsBox) {
      const { width } = actionsBox.getBoundingClientRect();
      actionsBox.firstChild.style.width = `${width}px`;
    }
    if (player) {
      const { width } = player.getBoundingClientRect();
      player.firstChild.style.width = `${width}px`;
    }
    if (ratingBox) {
      const { width } = ratingBox.getBoundingClientRect();
      const intercomWidth = 90;
      if (ratingBoxShouldSticky) {
        ratingBox.firstChild.style.width = `${width - intercomWidth}px`;
      } else {
        ratingBox.firstChild.style.width = `${width}px`;
      }
    }
    if (bulkAction) {
      const { width } = bulkAction.getBoundingClientRect();
      bulkAction.firstChild.style.width = `${width}px`;
    }
  }

  const handleScroll = ({ actionsBoxShouldSticky, playerShouldSticky, ratingBoxShouldSticky }) => {
    if (actionsBoxShouldSticky || playerShouldSticky || ratingBoxShouldSticky) {
      handleRowWidth(ratingBoxShouldSticky);
    }
  };

  const handleBtnSticky = () => {
    const headerHeight = 71;
    const bulkAction = document.getElementById('bulk-actions');
    if (bulkAction) {
      if (bulkAction.getBoundingClientRect().top <= headerHeight) {
        bulkAction.firstChild.style.position = 'fixed';
        bulkAction.firstChild.style.top = `${headerHeight}px`;
        handleRowWidth();
      } else {
        bulkAction.firstChild.style.position = 'static';
      }
    }
  };

  const handlePaginationClick = (page) => {
    const interviewIds = allInterviews.results.map((el) => el.id);
    trackEvent({
      viewName: 'interview:interview_list',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
      extraData: JSON.stringify({
        page,
        interviewIds,
      }),
    });
  };

  return (
    <Box
      className={classes.scrollContainer}
      onScroll={handleBtnSticky}
    >
      <Box
        className={classes.content}
      >
        <SearchInput loading={loading} />
        <SelectedFilters />

        {showSearchCounts
        && (
          <Box className={classes.showCount}>
            {!loading
            && (
              <>
                <Box component="span">
                  {
                    allInterviews.totalCount !== 1
                      ? `${allInterviews.totalCount} interviews and `
                      : '1 interview and '
                  }
                </Box>
                <Box
                  component="span"
                  onClick={handleSidebarOpen}
                  className={classes.companiesLink}
                >
                  {
                    companiesCount !== 1
                      ? `${companiesCount} companies`
                      : '1 company'
                  }
                </Box>
                <Box component="span">
                  {' found'}
                </Box>
              </>
            )}
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="10px"
        >
          <Ordering />
          <Box
            display="flex"
            alignItems="center"
          >
            <Box display="none">
              <IconButton
                color="inherit"
                aria-label="checkList"
                size="small"
                disableRipple
                disabled
                className={classes.button}
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 0C0.895 0 0 0.895 0 2V13C0 14.105 0.895 15 2 15H13C14.105 15 15 14.105 15 13V2C15 0.895 14.105 0 13 0H2ZM11 4C11.256 4 11.512 4.09797 11.707 4.29297C12.097 4.68297 12.097 5.31703 11.707 5.70703L7.70703 10.707C7.31703 11.098 6.68297 11.098 6.29297 10.707L3.29297 7.70703C2.90297 7.31703 2.90297 6.68297 3.29297 6.29297C3.68297 5.90297 4.31703 5.90297 4.70703 6.29297L7 8.58594L10.2 4.5C10.395 4.305 10.744 4 11 4ZM17 4C16.448 4 16 4.448 16 5V16H5C4.448 16 4 16.448 4 17C4 17.552 4.448 18 5 18H16C17.105 18 18 17.105 18 16V5C18 4.448 17.552 4 17 4Z" fill="#1168A7" />
                </svg>
              </IconButton>
            </Box>
            <Box display="none">
              <IconButton
                color="inherit"
                aria-label="menu"
                size="small"
                disableRipple
                disabled
                className={classes.button}
              >
                <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 0C1.46957 0 0.96086 0.210714 0.585787 0.585786C0.210714 0.960859 0 1.46957 0 2C0 2.53043 0.210714 3.03914 0.585787 3.41421C0.96086 3.78929 1.46957 4 2 4C2.53043 4 3.03914 3.78929 3.41421 3.41421C3.78929 3.03914 4 2.53043 4 2C4 1.46957 3.78929 0.960859 3.41421 0.585786C3.03914 0.210714 2.53043 0 2 0ZM2 7C1.46957 7 0.96086 7.21071 0.585787 7.58579C0.210714 7.96086 0 8.46957 0 9C0 9.53043 0.210714 10.0391 0.585787 10.4142C0.96086 10.7893 1.46957 11 2 11C2.53043 11 3.03914 10.7893 3.41421 10.4142C3.78929 10.0391 4 9.53043 4 9C4 8.46957 3.78929 7.96086 3.41421 7.58579C3.03914 7.21071 2.53043 7 2 7ZM2 14C1.46957 14 0.96086 14.2107 0.585787 14.5858C0.210714 14.9609 0 15.4696 0 16C0 16.5304 0.210714 17.0391 0.585787 17.4142C0.96086 17.7893 1.46957 18 2 18C2.53043 18 3.03914 17.7893 3.41421 17.4142C3.78929 17.0391 4 16.5304 4 16C4 15.4696 3.78929 14.9609 3.41421 14.5858C3.03914 14.2107 2.53043 14 2 14Z" fill="#1168A7" />
                </svg>
              </IconButton>
            </Box>
          </Box>
        </Box>

        <BulkActions
          ref={bulkActionRef}
          selected={selected}
          isReadingList={isReadingList}
        />

        <Fade in={allInterviews.totalCount > 0 && !loading}>
          <Box>
            {(allInterviews.totalCount > 0 && !loading)
            && (
            <>
              {allInterviews.results.map((interview) => (
                <InterviewItem
                  key={interview.interviewId}
                  id={interview.id}
                  uuid={interview.uuid}
                  keywords={interview.keywords}
                  headline={interview.headline}
                  callDate={interview.callDate}
                  interviewId={interview.interviewId}
                  tickers={interview.tickers}
                  relevantRole={interview.relevantRole}
                  perspective={interview.perspective}
                  publishedAt={interview.publishedAt}
                  readingTime={interview.readingTime}
                  isActive={interview.interviewId === interviewId}
                  isUnread={interview.isUnread}
                  isSeenInRl={interview.isSeenInRl}
                  isSelected={isSelected(interview.interviewId)}
                  accessRequest={interview.accessRequest || {}}
                  accessRequests={interview.accessRequests || {}}
                  readingListItem={interview.readingListItem}
                  userCanViewInterview={interview.userCanViewInterview}
                  transcriptionStatus={interview.transcriptionStatus}
                  percentsRead={interview.percentsRead}
                  me={me}
                  handleWriteFilterData={handleWriteFilterData}
                />
              ))}
              <PaginationLink
                totalCount={allInterviews.totalCount}
                initialFirst={INTERVIEW_PAGINATION_FIRST}
                onClick={handlePaginationClick}
              />
            </>
            )}
          </Box>
        </Fade>

        {loading
        && (
          <Fade in={loading}>
            <InterviewItemSkeleton />
          </Fade>
        )}

        <Fade in={allInterviews.totalCount === 0 && !loading}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexGrow="1"
          >
            {allInterviews.totalCount === 0 ? <InterviewsNotFound /> : ''}
          </Box>
        </Fade>

        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Button
                autoFocus
                color="inherit"
                onClick={handleClose}
                disableRipple
                className={classes.backBtn}
                startIcon={
                  <ArrowBackIcon />
                }
              >
                Back to interview list
              </Button>
            </Toolbar>
          </AppBar>
          <InterviewPreview
            interviewId={interviewId}
            onScroll={handleScroll}
          />
        </Dialog>
      </Box>
      <Fade in={!loading}>
        <SelectAllBtn
          interviews={allInterviews.results}
          selected={selected}
          isSelected={isSelected}
          isReadingList={isReadingList}
        />
      </Fade>

      <Fade in={!loading}>
        <Footer className={classes.previewFooter} />
      </Fade>
    </Box>
  );
};

InterviewMobile.defaultProps = {
  showSearchCounts: false,
  companiesCount: 0,
};

InterviewMobile.propTypes = {
  allInterviews: PropTypes.shape({
    totalCount: PropTypes.number,
    results: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  interviewId: PropTypes.string.isRequired,
  showSearchCounts: PropTypes.bool,
  companiesCount: PropTypes.number,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSelected: PropTypes.func.isRequired,
  isReadingList: PropTypes.bool.isRequired,
  handleSidebarOpen: PropTypes.func.isRequired,
  handleWriteFilterData: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  me: PropTypes.object.isRequired,
};

export default InterviewMobile;
