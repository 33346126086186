import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import FollowingItem from '../FollowingItem';
import {
  ALL_FOLLOWING_KEYWORDS_QUERY,
  DELETE_KEYWORD_FOLLOWING,
  UPDATE_KEYWORD_MUTATION,
} from '../../../../../../../../../../queries/interview/following';
import ItemsSkeleton from '../../../ItemsSkeleton';
import FollowingPlaceholder from '../FollowingPlaceholder';

const FollowingKeywords = ({ filterDataKeywords, handleFilterSelecion }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: {
      allKeywordWatchList = [],
    } = {},
    loading,
    updateQuery,
  } = useQuery(ALL_FOLLOWING_KEYWORDS_QUERY);

  const [deleteKeyword] = useMutation(DELETE_KEYWORD_FOLLOWING, {
    refetchQueries: [{ query: ALL_FOLLOWING_KEYWORDS_QUERY }],
  });

  const [updateKeyword] = useMutation(UPDATE_KEYWORD_MUTATION, {
    refetchQueries: [{ query: ALL_FOLLOWING_KEYWORDS_QUERY }],
  });

  const handleKeywordDelete = (followingId) => {
    deleteKeyword({ variables: { followingId } });
  };

  const handleKeywordUpdate = (followingId, keywordId) => (params) => {
    updateKeyword({
      variables: {
        id: followingId,
        keyword: keywordId,
        ...params,
      },
    }).then(({ data: { updateKeywordMutation = {} } = {} }) => {
      if (!isEmpty(updateKeywordMutation.errors)) {
        enqueueSnackbar(updateKeywordMutation.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        enqueueSnackbar('Saved.',
          { variant: 'success', autoHideDuration: 4000 });
      }
    });
  };
  const isKeywordActive = (id) => {
    if (filterDataKeywords) {
      const active = filterDataKeywords.filter((el) => el.id === id);
      return active.length > 0;
    }
    return false;
  };

  const handleSelecion = (item) => () => {
    handleFilterSelecion({
      keywords: [{
        id: item.keyword.id,
        title: item.keyword.title,
        uuid: item.keyword.uuid,
      }],
    });
    updateQuery((previousQueryResult) => {
      const result = previousQueryResult.allKeywordWatchList.map((el) => {
        if (el.id === item.id) {
          return { ...el, notSeenInterviewsCount: 0 };
        }
        return el;
      });
      return {
        allKeywordWatchList: result,
      };
    });
  };

  return (
    <>
      <Fade in={loading}>
        <Box>
          {loading && <ItemsSkeleton />}
        </Box>
      </Fade>
      <Fade in={!loading}>
        <Box>
          {allKeywordWatchList.length > 0
            ? allKeywordWatchList.map((item) => (
              <FollowingItem
                key={item.id}
                data-xlink-keyword-id={item.keyword.uuid}
                itemName={item.keyword.title}
                isItemActive={isKeywordActive(item.keyword.id)}
                unreadCount={item.notSeenInterviewsCount}
                onDelete={() => handleKeywordDelete(item.id)}
                onUpdate={handleKeywordUpdate(item.id, item.keyword.id)}
                onClick={handleSelecion(item)}
                initials={{
                  notify: item.notify,
                  emailAlerts: item.emailAlerts,
                }}
              />
            ))
            : (
              <FollowingPlaceholder>
                Add keywords to your watchlist to know first about new interviews
              </FollowingPlaceholder>
            )}
        </Box>
      </Fade>
    </>
  );
};

FollowingKeywords.propTypes = {
  filterDataKeywords: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleFilterSelecion: PropTypes.func.isRequired,
};

export default FollowingKeywords;
