import { gql } from '@apollo/client';

export const VARIFY_TOKEN_MUTATION = gql`
mutation varifyToken($token: String) {
  verifyToken(token: $token) {
    payload
  }
}
`;

export const ME_QUERY = gql`
{
  me {
    id
    uuid
    isPaid
    isBonus
    isStaff
    isSuperuser
    slug
    keepAnonymous
    nickname
    email
    isConsentToReceiveInfo
    realShortName
    realFullName
    firstName
    lastName
    userTimezone
    avatar
    role
    roleDisplay
    hasPaidAccess
    createdAt
    trialStartDate
    trialExpireDate
    upgradeRequestedDate
    becomePaid
    sectorWatchlistCount
    companyWatchlistCount
    industryWatchlistCount
    keywordWatchlistCount
    sectorWatchlistUnreadCount
    companyWatchlistUnreadCount
    keywordWatchlistUnreadCount
    industryWatchlistUnreadCount
    accountMembersCount
    cachedFilters
    compliance {
      id
      fullName
    }
    timezoneOptions {
      name
      description
    }
    settings{
      id
      emailAlerts
      playerSpeed
      playerSpeedOptions{
        name
        description
      }
      notifyPeriod
      notifyPeriodsOptions{
        name
        description
      }
    }
  }
}
`;

export const LOGIN_MUTATION = gql`
mutation tokenAuth($email: String!, $password: String!){
    tokenAuth(email: $email, password: $password){
        payload,
        token,
        refreshExpiresIn
    }
}
`;

export const RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($email: String!){
  resetPassword(input: {email: $email}){
    email
    errors{
      field
      messages
    }
  }
}
`;

export const VALIDATE_TOKEN_MUTATION = gql`
mutation validateToken($token: String!, $uidb: String!){
  validateToken(token: $token, uidb: $uidb){
    valid
  }
}
`;

export const VALIDATE_FIRST_LOGIN_HASH_MUTATION = gql`
mutation ValidateFirstLoginHash($hashStr: String!){
  validateFirstLoginHash(hashStr: $hashStr){
    valid
    user {
      firstName
      lastName
      email
    }
  }
}
`;

export const SET_PASSWORD_MUTATION = gql`
mutation setPassword($token: String!, $uidb: String!, $new_password1: String!, $new_password2: String!) {
  setPassword(token: $token, uidb: $uidb, newPassword1: $new_password1, newPassword2: $new_password2) {
    errors{
      field
      messages
    }
    user {
      email
    }
  }
}
`;

export const SET_FIRST_LOGIN_PASSWORD = gql`
mutation setFirstLoginPassword($hashStr: String!, $password: String!, $receiveInfo: Boolean, $termsAndConditions: Boolean!) {
  setFirstLoginPassword(hashStr: $hashStr, password: $password, receiveInfo: $receiveInfo, termsAndConditions: $termsAndConditions) {
    token
    refreshExpiresIn
    errors {
      field
      messages
    }
  }
}
`;

export const UPDATE_USER_SETTINGS_MUTATION = gql`
mutation updateUserSettings($emailAlerts: Boolean, $notifyPeriod: String, $playerSpeed: Float, $settingsId: Int!){
  updateUserSettings(emailAlerts: $emailAlerts, notifyPeriod: $notifyPeriod, playerSpeed: $playerSpeed, settingsId: $settingsId){
    success
  }
}
`;

export const UPDATE_USER_PROFILE_MUTATION = gql`
mutation ($firstName: String, $lastName: String, $avatar: Upload, $keepAnonymous: Boolean, $email: String, $userTimezone: String!, $oldPassword: String, $newPassword: String, $isConsentToReceiveInfo: Boolean) {
  updateUserProfile(firstName: $firstName, lastName: $lastName, avatar: $avatar, keepAnonymous: $keepAnonymous, email: $email, userTimezone: $userTimezone, oldPassword: $oldPassword, newPassword: $newPassword, isConsentToReceiveInfo: $isConsentToReceiveInfo) {
    errors {
      field
      messages
    }
    token
    refreshExpiresIn
    user {
      id
    }
  }
}
`;
