import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { tokenName } from '../../config';

const FakeLayout = ({ children }) => (<>{children}</>);

FakeLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const RouteWithLayout = (props) => {
  const {
    layout: Layout, component: Component, loginRequired, ...rest
  } = props;

  // we use this to allow superuser to login as any user in the system
  const parsed = queryString.parse(window.location.search);
  if (parsed.token) {
    localStorage.setItem(tokenName, parsed.token);
  }
  // end

  const token = localStorage.getItem(tokenName) || parsed.token;

  if (!token && loginRequired) {
    const locationParams = `${window.location.pathname}${window.location.search}`;
    window.location.href = `/accounts/login/?next=${locationParams}`;
    return null;
  }

  const CompnentLayout = Layout || FakeLayout;
  // TODO: ogurets
  // this change reduced rerenders by three times.
  // need to figure out what we are loosing using this approach.
  return (
    <Route
      {...rest}
    >
      <CompnentLayout>
        <Component />
      </CompnentLayout>
    </Route>
  );
  // return (
  //   <Route
  //     {...rest}
  //     render={(matchProps) => (
  //       <Layout>
  //         <Component {...matchProps} />
  //       </Layout>
  //     )}
  //   />
  // );
};

RouteWithLayout.defaultProps = {
  loginRequired: true,
};

RouteWithLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  layout: PropTypes.any.isRequired,
  // eslint-disable-next-line react/require-default-props
  path: PropTypes.string,
  loginRequired: PropTypes.bool,
};

export default RouteWithLayout;
