/* eslint-disable max-len */
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    maxWidth: '940px',
    width: '100%',
    padding: '20px 20px 30px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  document: {
    background: theme.palette.background.paper,
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      padding: '50px',
    },
  },
  ul: {
    paddingLeft: '30px',
    marginBottom: '16px',
    '& li': {
      color: '#182341',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      letterSpacing: '-0.05px',
    },
  },
}));

const TermsAndConditions = () => {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.container}>
      <Box className={classes.document}>
        <Typography variant="h1" align="center" gutterBottom>STREAM MEDIA GROUP INC.</Typography>
        <Typography variant="h2" align="center" gutterBottom>TERMS OF USE</Typography>

        <Typography paragraph gutterBottom>
          BEFORE YOU CHECK THE BOX AND CLICK ON THE “I ACCEPT” OR “I AGREE” BUTTON, CAREFULLY READ THE TERMS AND CONDITIONS OF THIS AGREEMENT. BY CLICKING ON THE “I ACCEPT” OR “I AGREE” BUTTON OR USING THIS WEBSITE IN ANY MANNER (INCLUDING BY CREATING A USER ACCOUNT), YOU ARE AGREEING TO BE BOUND BY AND ARE BECOMING A PARTY TO THIS AGREEMENT. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, PLEASE DO NOT USE OUR SERVICES. WE RESERVE THE RIGHT TO CHANGE THE TERMS OF THIS AGREEMENT IN THE FUTURE AND ANY CHANGES WILL APPLY TO YOUR USE OF THE WEBSITE AFTER THE DATE OF SUCH CHANGE.
        </Typography>

        <Typography variant="h3" gutterBottom>1. ACCEPTANCE OF TERMS</Typography>
        <Typography paragraph gutterBottom>STREAM MEDIA GROUP INC., a Delaware corporation (hereinafter referred to as “SMG”, “we”, “our” or “us”), provides the this website located at stream.mosaicrm.com (the “Site”) and all related transcripts, audio recordings, software, webpages, information, files, products and services available through the Site (collectively, the “Materials”) and all services operated by us and third parties through the Site, including making available transcripts and audio recordings of industry expert interviews (collectively, the “Services”), available for your use subject to the terms and conditions set forth in this document and any changes to this document that we may publish from time to time (collectively, the “Terms of Use” or “Agreement”).</Typography>

        <Typography paragraph gutterBottom>We reserve the right to change the Terms of Use and other guidelines or rules from time to time at our sole discretion. Your continued use of the Site, or any Materials or Services accessible through them, after such notice has been posted constitutes your acceptance of the changes. Your use of the Site will be subject to the most current version of the Terms of Use, rules and guidelines. If you breach any of the Terms of Use, your authorization to use the Site may automatically be terminated by SMG.</Typography>

        <Typography paragraph gutterBottom>We further reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Materials and/or Services (or any part thereof) with or without notice. You agree that our maximum liability to you for the discontinuance of the Materials or Services shall be equal to your pre-paid subscription fee reflecting that part of the subscription term for which you have paid but for which the Materials and Services will not be provided, prorated for partial terms.</Typography>

        <Typography paragraph gutterBottom>Please note that we do not guarantee the continued access to any particular Materials, and we may remove transcripts and recordings at our discretion.</Typography>

        <Typography variant="h3" gutterBottom>2. USER ACCOUNTS; SUBSCRIPTIONS</Typography>
        <Typography paragraph gutterBottom>In order to access or to make full use of the Site, Materials and/or Services you will need to register and create a user account (an “Account”). You will be required to select your subscription package and you grant us the authority to charge your credit card accordingly. Unless you terminate our services, your subscription will continue renewing. For example, if you select the monthly subscription, we will continue charging your card on a monthly basis until you terminate the services. The same is true with the annual subscription; if you fail to terminate before the expiration of the current subscription term, we will automatically charge you for successive one-year terms until you terminate. No refunds will be issued if you fail to terminate before the automatic renewal takes effect.</Typography>

        <Typography variant="subtitle1">By using the Site or setting up an Account you agree:</Typography>
        <ul className={classes.ul}>
          <li>that you will not use an Account that is created by another user;</li>
          <li>that you will supply only complete and accurate information in registering for an Account;</li>
          <li>that you are responsible for the activity that occurs on and through your Account, that you will keep your Account password secure at all times, and that we will not be liable for your losses caused by any unauthorized use of your Account; and</li>
          <li>that if your Account has been compromised for any reason, you will immediately notify us of the security breach or unauthorized use of your Account.</li>
          <li>We reserve the right to (a) take appropriate legal action against anyone who violates these Terms of Use or applicable law; (b) in our sole discretion without notice and without limitation or liability, refuse, restrict access to or availability of, or terminate any user’s Account for any reason, provided that if such a termination by SMG is not for cause, then SMG will refund the unused portion of the prepaid subscription fee for the unused part of the paid-for subscription term.</li>
        </ul>

        <Typography variant="h3" gutterBottom>3. COMPLIANCE WITH THE LAW</Typography>
        <Typography paragraph gutterBottom>You understand and agree that we merely make available on the Site the Materials (including the interview transcripts and audio recordings). You understand that we may be obligate to redact portions of the materials for contractual and legal reasons. You further agree that by making the Materials available to you, we are not making any representations regarding the legality of your particular use of the Materials. You agree that it is solely your obligation and duty to ensure that your contemplated use of the Site and Materials are and will remain compliant with all applicable laws and regulations, including securities laws. You represent that you are familiar with the relevant laws and regulations and that your access to the Site and Materials, and your use of the Materials is legally compliant in all respects.</Typography>

        <Typography variant="h3" gutterBottom>4. INTELLECTUAL PROPERTY; LIMITED LICENSE</Typography>
        <Typography paragraph gutterBottom>The Materials and Services on the Site, as well as their selection and arrangement, are protected by copyright, trademark, patent, and/or other intellectual property laws, and any unauthorized use of the Materials or Services on the Site may violate such laws and these Terms of Use. Except as expressly provided herein, we do not grant any express or implied rights to use the Materials and Services. You agree not to copy, share, distribute, republish, frame, download, transmit, modify, assign, or create derivative works based on the Materials, except as authorized herein. In addition, you agree not to use any data mining, robots, or similar data gathering and extraction methods in connection with the Site. You understand and agree that the copyrights in all of the transcripts and recordings remain the property of SMG.</Typography>

        <Typography paragraph gutterBottom>In addition to the Materials and Services offered by us, the Site may make available products and services provided by third parties (collectively, the “Third Party Products and Services”). We offer no guarantees and assume no responsibility or liability of any type with respect to the Third Party Products and Services. You agree that you will not hold us responsible or liable with respect to the Third Party Products and Services or seek to do so, even if we happen to recommend a particular provider.</Typography>

        Except as expressly indicated to the contrary elsewhere on the Site, you may use and view the Materials subject to the following conditions:
        <ul>
          <li>The Materials may be used solely for internal purposes.</li>
          <li>You may not distribute or share the Materials with another party, except to the extent that such other party also has a subscription to the Services.</li>
          <li>The Materials may not be modified or altered in any way.</li>
          <li>You may not remove any copyright or other proprietary notices contained on the Site or in connection with the Material.</li>
          <li>We reserve the right to revoke the authorization to view and use the Material available on the Site at any time, and any such use shall be discontinued immediately upon notice from us.</li>
          <li>The rights granted to you constitute a license and not a transfer of title.</li>
        </ul>

        <Typography variant="h3" gutterBottom>5. TRADEMARK INFORMATION</Typography>
        <Typography paragraph gutterBottom>The trademarks, logos, and service marks, including the “Stream Media Group” trademark (the “Mark”) displayed on the Site belong to us. You are not permitted to use the Marks without our prior written consent.</Typography>

        <Typography variant="h3" gutterBottom>6. USER CONDUCT & PROHIBITED ACTIVITIES</Typography>
        You may not access or use the Site for any other purpose other than that for which we make them available. In using the Site, including all Services and Materials available through them, you agree to not engage in any prohibited activities. Prohibited activity includes, but is not limited to:
        <ul>
          <li>criminal or tortious activity;</li>
          <li>activities relating to the purchase or use of the Materials or or Services in violation of applicable law;</li>
          <li>disrupting or interfering with the security of, or otherwise causing harm to, the Site, or any Services, Materials, system resources, accounts, passwords, servers, or networks connected to or accessible through the Site; and</li>
          <li>using the Site or any Materials available on or through it in a manner inconsistent with any and all applicable laws and regulations.</li>
        </ul>

        <Typography variant="h3" gutterBottom>7. WARRANTIES AND DISCLAIMERS</Typography>
        <Typography paragraph gutterBottom>EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN AGREEMENT BETWEEN YOU AND SMG, THE SITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH IT ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (I) THE SERVICES AND MATERIALS WILL MEET YOUR REQUIREMENTS; (II) THE SERVICES AND MATERIALS WILL BE UNINTERRUPTED, TIMELY, SECURE, ACCURATE, UPDATED OR ERROR-FREE; (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR MATERIALS WILL BE EFFECTIVE, ACCURATE, OR RELIABLE; AND (IV) ANY ERRORS IN THE SERVICES OR MATERIALS, OR ANY DEFECTS IN THE SITE, ITS SERVICES OR MATERIALS, WILL BE CORRECTED.</Typography>

        <Typography paragraph gutterBottom>The use of the Services and the Materials is done at your own discretion and risk and with your agreement that you will be solely responsible for the outcome of your reliance upon or use of the Services or Materials. Without limiting the foregoing, we assume no liability for any information presented on the interview transcripts or audio recordings.</Typography>

        <Typography variant="h3" gutterBottom>8. PERSONAL INFORMATION AND PRIVACY</Typography>
        <Typography paragraph gutterBottom>You understand and agree that we may disclose information about you if we have a good faith belief that we are required to do so by law or legal process, to respond to claims, or to protect the rights, property, or safety of SMG or others. Please refer to our Privacy Policy for more information about the manner in which we protect and use your information.</Typography>

        <Typography variant="h3" gutterBottom>9. LIMITATION OF LIABILITY</Typography>
        <Typography paragraph gutterBottom>IN NO EVENT SHALL SMG OR ITS MEMBERS, SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES OR ATTORNEYS BE LIABLE TO YOU OR ANY THIRD PARTY UNDER ANY CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR UNDER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT DAMAGES ARISING FROM YOUR USE OF THE SITE OR ANY OF THE MATERIALS OR SERVICES AVAILABLE ON OR THROUGH THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT ACTUALLY PAID, IF ANY, BY YOU TO US DURING THE 12-MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO THE CLAIM.</Typography>

        <Typography variant="h3" gutterBottom>10. INDEMNITY AND LIABILITY</Typography>
        <Typography paragraph gutterBottom>You agree to indemnify and hold SMG, and its subsidiaries, affiliates, officers, directors, shareholders, members, agents, attorneys, partners and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of: (a) your use of the Site or any of the Materials or Services available on or through the Site, (b) your violation of these Terms of Use or any representations you make in this Agreement, (c) your violation of any rights of another person or entity, or (d) your violation of any applicable law, rule or regulation.</Typography>

        <Typography variant="h3" gutterBottom>11. GOVERNING LAW, JURISDICTION</Typography>
        <Typography paragraph gutterBottom>This Agreement is governed in all respects by the laws of the State of Delaware without regard to conflict of laws provisions. SUBJECT TO THE REQUIREMENT TO SUBMIT TO ARBITRATION, BY ACCEPTING THIS AGREEMENT AND USING THE SITE YOU ACCEPT AND CONSENT TO THE PERSONAL JURISDICTION AND VENUE OF THE FEDERAL AND STATE COURTS IN NEW YORK COUNTY, NEW YORK. THE PARTIES HEREBY WAIVE THEIR RIGHT TO A TRIAL BY JURY OF ANY CLAIMS OR CONTROVERSIES ARISING OUT OF THIS AGREEMENT OR THE USER’S USE OF THE SITE OR THE SERVICES/MATERIALS PROVIDED BY OR THROUGH SMG. IN ADDITION, ANY CONTROVERSY OR CLAIM SHALL BE LITIGATED ON AN INDIVIDUAL BASIS, AND SHALL NOT BE CONSOLIDATED WITH ANY CLAIM OR CONTROVERSY OF ANY OTHER PARTY (INCLUDING, BUT NOT LIMITED TO, BY MEANS OF CLASS ACTION LAW SUITS).</Typography>

        <Typography paragraph gutterBottom>NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, YOU AGREE THAT ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO YOUR USE OF THE SITE (INCLUDING THE MATERIALS OR SERVICES), THIS AGREEMENT OR THE BREACH, TERMINATION, ENFORCEMENT, INTERPRETATION OR VALIDITY THEREOF (COLLECTIVELY, “DISPUTES”) WILL BE SETTLED BY BINDING ARBITRATION BETWEEN YOU AND SMG, EXCEPT THAT EACH PARTY RETAINS THE RIGHT TO SEEK INJUNCTIVE OR OTHER EQUITABLE RELIEF IN A COURT OF COMPETENT JURISDICTION TO PREVENT THE ACTUAL OR THREATENED INFRINGEMENT, MISAPPROPRIATION OR VIOLATION OF A PARTY’S COPYRIGHTS, TRADEMARKS, TRADE SECRETS, PATENTS OR OTHER INTELLECTUAL PROPERTY RIGHTS. AS NOTED ABOVE, YOU ACKNOWLEDGE AND AGREE THAT YOU AND SMG ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING. FURTHER, UNLESS BOTH YOU AND SMG OTHERWISE AGREE IN WRITING, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF ANY CLASS OR REPRESENTATIVE PROCEEDING. THIS “DISPUTE RESOLUTION” SECTION WILL SURVIVE ANY TERMINATION OF THESE TERMS. THE ARBITRATION WILL BE ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION (“AAA”) IN ACCORDANCE WITH THE COMMERCIAL ARBITRATION RULES (THE “AAA RULES”) THEN IN EFFECT. THE ARBITRATION PROCEEDINGS SHALL TAKE PLACE IN NEW YORK COUNTY, NEW YORK.</Typography>

        <Typography paragraph gutterBottom>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR YOUR USE OF THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</Typography>

        <Typography variant="h3" gutterBottom>12. GENERAL</Typography>
        <Typography paragraph gutterBottom>The Terms of Use and the other rules, guidelines, licenses and disclaimers accessible through the Site constitute the entire agreement between SMG and you with respect to your use of the Site or the Services or Materials made available on or through it. If for any reason a court of competent jurisdiction finds any provision of these Terms of Use, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effect the intent of the parties as reflected by that provision, and the remainder of the Terms of Use shall continue in full force and effect. Any failure by SMG to enforce or exercise any provision of these Terms of Use or related right shall not constitute a waiver of that right or provision.</Typography>

        <Typography paragraph gutterBottom>Last Updated: February 26, 2020</Typography>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
