import React, { useEffect, useState } from 'react';
import {
  Link, Redirect, useHistory, useParams,
} from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import commonPasswordChecker from 'common-password-checker';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SET_FIRST_LOGIN_PASSWORD, VALIDATE_FIRST_LOGIN_HASH_MUTATION } from '../../queries/account';
import { LinearProgressMosaic } from '../../components';
import { ReactComponent as SetPasswordImg } from '../../assets/images/set_password.svg';
import { ReactComponent as ExitIcon } from '../../assets/icons/exit.svg';
import { ReactComponent as PasswordCheckIcon } from '../../assets/icons/password_check.svg';
import login from '../../helpers/login';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import useMe from '../../hooks/useMe';

const PasswordField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      borderRadius: '5px',
      height: '40px',
      '&.MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
      },
      '& input': {
        height: '100%',
        padding: '0 52px 0 14px',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
      },
      '& fieldset': {
        borderColor: theme.palette.info.main,
      },
      '& button': {
        position: 'absolute',
        right: 0,
        padding: '8px 14px',
        '&:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.text.secondary,
        },
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.primary.main,
          },
        },
      },
      '&.Mui-disabled': {
        '& input': {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.default,
        },
        '& fieldset': {
          borderColor: theme.palette.info.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.info.main,
          },
        },
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    // minHeight: 'calc(100vh - 118px)',
    overflow: 'hidden',
    flexGrow: '1',
    [theme.breakpoints.up('md')]: {
      // minHeight: 'calc(100vh - 100px)',
    },
  },
  logBox: {
    position: 'relative',
    maxWidth: '540px',
    width: '100%',
    padding: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '32px',
    color: theme.palette.text.primary,
    marginBottom: '24px',
  },
  text: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette.text.primary,
  },
  label: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    marginBottom: '4px',
  },
  listItem: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.primary,
  },
  submitBtn: {
    letterSpacing: 'normal',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    height: '40px',
    padding: '0 30px',
    width: '100%',
    marginBottom: '36px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.dark,
    },
  },
  labelLink: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginLeft: '5px',
      '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'none',
      },
      '&:active': {
        color: theme.palette.primary.light,
        textDecoration: 'none',
      },
    },
  },
  checkbox: {
    padding: '0 10px 0 0',
    color: theme.palette.info.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.paper,
      },
    },
  },
  errorMessageText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.error.main,
  },
}));

const errorMessages = {
  personal: 'Password can\'t be similar to your other personal information.',
  min: 'Password must contain at least 8 characters.',
  commonPassword: 'Password can\'t be a commonly used password.',
  notNumericOnly: 'Password can\'t be entirely numeric.',
};

const FirstLogin = () => {
  const classes = useStyles();

  const { hashStr } = useParams();
  const history = useHistory();
  const { refetch } = useMe();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const triggerPassword1Visibility = () => {
    setShowPassword1(!showPassword1);
  };

  const triggerPassword2Visibility = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [validateHash, {
    data: { validateFirstLoginHash = {} } = {},
    loading,
  }] = useMutation(VALIDATE_FIRST_LOGIN_HASH_MUTATION);

  const personalData = !isEmpty(validateFirstLoginHash)
    ? Object.values(validateFirstLoginHash.user)
    : [];

  const schema = yup.object().shape({
    password1: yup.string()
      .min(8, errorMessages.min)
      .test('commonPassword', errorMessages.commonPassword, (value) => !commonPasswordChecker(value || ''))
      // eslint-disable-next-line no-restricted-globals
      .test('notNumericOnly', errorMessages.notNumericOnly, (value) => isNaN(Number(value)))
      .test('personal', errorMessages.personal, (value) => {
        if (isEmpty(value)) return false;

        if (value.length > 3) {
          return !personalData.some((el) => el.toLowerCase().includes(String(value).toLowerCase()));
        }
        return true;
      })
      .required('Field is required'),
    password2: yup.string()
      .oneOf([yup.ref('password1'), null], 'Those passwords didn\'t match. Try again.')
      .required('Field is required'),
    termsAndConditions: yup.boolean().oneOf([true], 'Field must be checked'),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      termsAndConditions: true,
      // password1: '',
    },
  });

  const watchPassword1 = watch('password1');

  const [setPassword] = useMutation(SET_FIRST_LOGIN_PASSWORD);

  const isButtonDisabled = loading || !isDirty || !isValid;

  const onSubmit = async (values) => {
    const errorsBackend = {};
    const submittedValues = { ...values, password: values.password1 };

    delete submittedValues.password1;
    delete submittedValues.password2;

    await setPassword({
      variables: {
        hashStr, ...submittedValues,
      },
    }).then(({ data = {} }) => {
      const { token, refreshExpiresIn, errors: formErrors } = data.setFirstLoginPassword;
      if (token) {
        login(token, refreshExpiresIn);
        refetch();
        history.push('/');
      }
      if (formErrors) {
        formErrors.forEach((err) => {
          errorsBackend[err.field] = err.messages;
        });
      }
    });
    return errorsBackend;
  };

  useEffect(() => {
    validateHash({ variables: { hashStr } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LinearProgressMosaic />;

  if (!isEmpty(validateFirstLoginHash) && validateFirstLoginHash.valid !== true) {
    return <Redirect to="/accounts/login" />;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.logBox}>
        <Box
          p={{ xs: '20px', md: '50px' }}
          bgcolor="background.paper"
          borderRadius="5px"
        >
          <Box display="flex" justifyContent="center" mb="24px">
            <SetPasswordImg />
          </Box>
          <Box className={classes.title}>
            {validateFirstLoginHash?.user?.firstName
              ? `Welcome to Stream, ${validateFirstLoginHash?.user?.firstName}!`
              : 'Welcome to Stream!'}
          </Box>
          <Box className={classes.text} mb="36px">
            You are just a step away from getting access to a robust library of
            transcripts and one-on-one calls with former executives, customers,
            competitors, and channel participants.
          </Box>
          <Box className={classes.text} mb="24px">
            Please set your password to continue.
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mb="12px">
              <Box className={classes.label}>Password</Box>
              <PasswordField
                {...register('password1')}
                variant="outlined"
                fullWidth
                type={showPassword1 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={triggerPassword1Visibility}
                      onMouseDown={handleMouseDownPassword}
                      disableRipple
                    >
                      {showPassword1 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <Box mb="12px">
              {Object.entries(errorMessages).map(([errorType, errorMessage]) => (
                <Box className={classes.listItem} mb="4px">
                  <Box>
                    {
                      (
                        isEmpty(watchPassword1)
                          || (errors?.password1?.types && errors.password1.types[errorType])
                      )
                        ? <ExitIcon />
                        : <PasswordCheckIcon />
                  }
                  </Box>
                  <Box ml="8px">{errorMessage}</Box>
                </Box>
              ))}
            </Box>
            <Box mb="24px">
              <Box className={classes.label}>Retype Password</Box>
              <PasswordField
                {...register('password2')}
                variant="outlined"
                fullWidth
                type={showPassword2 ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={triggerPassword2Visibility}
                      onMouseDown={handleMouseDownPassword}
                      disableRipple
                    >
                      {showPassword2 ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
              <Box className={classes.errorMessageText}>
                {errors?.password2?.message}
              </Box>
            </Box>
            <SubmitButton
              loading={loading}
              submitting={isButtonDisabled}
              className={classes.submitBtn}
            >
              {isButtonDisabled
                ? 'Set a Password to Get Started'
                : 'Access our Robust Library of Expert Calls'}
            </SubmitButton>
            <Box
              display="flex"
              alignItems={{ xs: 'flex-start', md: 'center' }}
            >
              <Controller
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    innerRef={field.ref}
                    checked={field.value}
                    disableRipple
                    className={classes.checkbox}
                  />
                )}
                name="termsAndConditions"
              />
              <Box className={classes.labelLink}>
                By checking this box, you have read and agree to our
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
                , and
                <Link to="/privacy-policy">Privacy Policy</Link>
              </Box>
            </Box>
            <Box className={classes.errorMessageText}>
              {errors?.termsAndConditions?.message}
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

FirstLogin.propTypes = {
};

export default FirstLogin;
