import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import { useSelector } from 'react-redux';
import FollowingItem from '../FollowingItem';
import {
  ALL_FOLLOWING_COMPANIES_QUERY,
  DELETE_COMPANY_FOLLOWING,
  UPDATE_COMPANY_MUTATION,
} from '../../../../../../../../../../queries/interview/following';
import ItemsSkeleton from '../../../ItemsSkeleton';
import FollowingPlaceholder from '../FollowingPlaceholder';

const FollowingCompanies = ({ handleFilterSelecion }) => {
  const { enqueueSnackbar } = useSnackbar();
  const filterData = useSelector((state) => state.filterState.filterData, isEqual);

  const {
    data: {
      allCompanyWatchList = [],
    } = {},
    loading,
    updateQuery,
  } = useQuery(ALL_FOLLOWING_COMPANIES_QUERY);

  const [deleteCompany] = useMutation(DELETE_COMPANY_FOLLOWING, {
    refetchQueries: [{ query: ALL_FOLLOWING_COMPANIES_QUERY }],
  });

  const [updateCompany] = useMutation(UPDATE_COMPANY_MUTATION, {
    refetchQueries: [{ query: ALL_FOLLOWING_COMPANIES_QUERY }],
  });

  const handleCompanyDelete = (followingId) => {
    deleteCompany({ variables: { followingId } });
  };

  const handleCompanyUpdate = (followingId, companyId) => (params) => {
    updateCompany({
      variables: {
        id: followingId,
        company:
          companyId,
        ...params,
      },
    }).then(({ data: { updateCompanyMutation = {} } = {} }) => {
      if (!isEmpty(updateCompanyMutation.errors)) {
        enqueueSnackbar(updateCompanyMutation.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        enqueueSnackbar('Saved.',
          { variant: 'success', autoHideDuration: 4000 });
      }
    });
  };

  const isCompanyActive = (tickers) => {
    const ids = tickers.map((el) => el.id);
    const active = filterData.tickers?.map((el) => el.id);

    const res = ids.filter((el) => {
      if (active.includes(el)) {
        return true;
      }
      return false;
    });

    if (res.length > 0) {
      return true;
    }
    return false;
  };

  const handleClick = (company) => () => {
    handleFilterSelecion({
      tickers: company?.tickers,
    });
    updateQuery((previousQueryResult) => {
      const result = previousQueryResult.allCompanyWatchList.map((el) => {
        if (el.company.id === company.id) {
          return { ...el, notSeenInterviewsCount: 0 };
        }
        return el;
      });
      return {
        allCompanyWatchList: result,
      };
    });
  };

  return (
    <>
      <Fade in={loading}>
        <Box>
          {loading && <ItemsSkeleton />}
        </Box>
      </Fade>
      <Fade in={!loading}>
        <Box overflow="auto">
          {allCompanyWatchList.length > 0
            ? allCompanyWatchList?.map((item) => (
              <FollowingItem
                key={item.id}
                // data-xlink-company-id={item.ticker.uuid}
                itemName={item.company.title}
                isItemActive={isCompanyActive(item.company.tickers)}
                unreadCount={item.notSeenInterviewsCount}
                onDelete={() => handleCompanyDelete(item.id)}
                onUpdate={handleCompanyUpdate(item.id, item.company.id)}
                onClick={handleClick(item.company)}
                initials={{
                  notify: item.notify,
                  emailAlerts: item.emailAlerts,
                }}
              />
            ))
            : (
              <FollowingPlaceholder>
                Add companies to your watchlist to know first about new interviews
              </FollowingPlaceholder>
            )}
        </Box>
      </Fade>
    </>
  );
};

FollowingCompanies.propTypes = {
  // filterDataTickers: PropTypes.arrayOf(PropTypes.number).isRequired,
  handleFilterSelecion: PropTypes.func.isRequired,
};

export default FollowingCompanies;
