import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Link, Redirect, useLocation, withRouter,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import { useMutation } from '@apollo/client';
import { useTracking } from 'react-tracking';
import isEmpty from 'lodash/isEmpty';
import { LOGIN_MUTATION } from '../../queries/account';
import login from '../../helpers/login';
import { FormTextField } from '../../components/FormFields';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { searchToObject } from '../../helpers/locationSearchToJson';
import { tokenName } from '../../config';
import useMe from '../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  loginBox: {
    display: 'flex',
    minHeight: 'calc(100vh - 118px)',
    overflow: 'hidden',
    paddingTop: '71px',
    transition: 'padding-top .5s ease-in-out',
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 100px)',
      paddingTop: '0',
    },
  },
  leftBox: {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 189px)',
    backgroundColor: theme.palette.background.paper,
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      width: '500px',
      minHeight: 'calc(100vh - 100px)',
      float: 'left',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      padding: '50px',
    },
  },
  raleway24: {
    fontFamily: 'Raleway!important',
    color: '#2D2D2D',
    fontSize: '24px',
    fontWeight: '800',
    lineHeight: '28px',
    marginBottom: '20px',
  },
  errorMessage: {
    display: 'block',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    padding: '8px 16px',
    border: '1px solid',
    borderColor: '#E2BEBE',
    color: '#DA0000',
    marginTop: '0',
    marginBottom: '16px',
  },
  link: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&:active': {
      color: theme.palette.primary.light,
    },
  },
  linkLabel: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    marginRight: '5px',
  },
  submitBtn: {
    borderRadius: '5px',
    letterSpacing: 'normal',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    height: '40px',
    padding: '0 30px',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  rightBox: {
    display: 'none',
    width: 'calc(100vw - 500px)',
    minHeight: 'calc(100vh - 100px)',
    backgroundColor: theme.palette.background.paper,
    float: 'left',
    padding: '50px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  rightDisplay: {
    width: 'auto',
    height: '100%',
  },
  raleway38: {
    fontFamily: 'Raleway!important',
    color: '#2D2D2D',
    fontSize: '38px',
    fontWeight: '800',
    lineHeight: '45px',
    textAlign: 'center',
    marginBottom: '10px',
  },
  quicksand20: {
    fontFamily: 'Quicksand!important',
    maxWidth: '600px',
    color: '#474747',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '32px',
    letterSpacing: '0.11px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '20px',
  },
  learnMore: {
    fontFamily: 'Quicksand!important',
    display: 'block',
    maxWidth: '600px',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '32px',
    letterSpacing: '0.11px',
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '40px',
    textDecorationLine: 'underline',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&:active': {
      color: theme.palette.primary.light,
    },
  },
  startTrial: {
    width: 'auto',
    padding: '10px 30px',
    backgroundColor: theme.palette.warning.main,
    border: '1px solid',
    borderColor: theme.palette.warning.main,
    borderRadius: '5px',
    color: theme.palette.common.white,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    height: '40px',
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
      borderColor: theme.palette.warning.dark,
      color: theme.palette.common.white,
    },
    '&:active': {
      backgroundColor: theme.palette.warning.light,
      borderColor: theme.palette.warning.light,
      color: theme.palette.common.white,
    },
  },
}));

const LogIn = ({ history }) => {
  const classes = useStyles();
  const { me, refetch } = useMe();
  const { trackEvent } = useTracking();
  const location = useLocation();

  const [loginUser, {
    error, loading, // called,
  }] = useMutation(LOGIN_MUTATION, {
    onCompleted: (data) => {
      const { token, refreshExpiresIn } = data.tokenAuth;
      if (token) {
        const searchObj = searchToObject(location.search);
        let redirectUrl = '/';
        login(token, refreshExpiresIn);
        trackEvent({
          viewName: 'login',
          path: location.pathname,
          getDict: JSON.stringify(searchObj),
          token,
        });
        refetch();
        if (searchObj.next) {
          redirectUrl = searchObj.next;
        }
        history.push(redirectUrl);
      }
    },
    onError: () => {
      console.log('error');
      //   client.clearStore();
      //   window.localStorage.clear();
      //   // window.location.href = '/accounts/login';
    },
  });

  const onSubmit = (values) => {
    loginUser({ variables: values });
  };

  useEffect(() => {
    if (!isEmpty(me) && localStorage.getItem(tokenName) !== null) {
      const searchObj = searchToObject(location.search);
      if (searchObj.next) {
        return <Redirect to={searchObj.next} />;
      }
      return <Redirect to="/" />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return (
    <Box className={classes.loginBox}>
      <Box className={classes.leftBox}>
        <Box width="100%">
          <Box
            display={{ xs: 'none', md: 'block' }}
            textAlign="center"
            mb="20px"
          >
            <img src="/images/login_logo.svg" alt="Stream" />
          </Box>
          <Box
            display={{ xs: 'block', md: 'none' }}
            textAlign="center"
          >
            <Box
              width="50%"
              height="auto"
              mx="auto"
              mb="10px"
            >
              <img src="/images/monitor.png" width="100%" height="100%" alt="display" />
            </Box>
            <Box className={classes.raleway24}>
              Expert Calls, On Demand
            </Box>
          </Box>
          {error
          && error.graphQLErrors.map(
            (err, index) => <p className={classes.errorMessage} key={index}>{err.message}</p>,
          )}
          <Form
            onSubmit={onSubmit}
            // validate={(values) => {
            //   const errors = {};
            //   // if (!values.email) {
            //   //   errors.email = 'Required';
            //   // }
            //   // if (!values.password) {
            //   //   errors.password = 'Required';
            //   // }
            //   return errors;
            // }}
            render={({
              handleSubmit,
              submitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb="18px">
                  <FormTextField
                    fieldName="email"
                    fieldType="email"
                    label="Email Address"
                    variant="outlined"
                    size="small"
                    required
                  />
                </Box>
                <Box mb="20px">
                  <FormTextField
                    fieldName="password"
                    fieldType="password"
                    label="Password"
                    variant="outlined"
                    size="small"
                    required
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb="20px"
                >
                  <Link to="/accounts/password-reset" className={classes.link}>
                    Forgot your password?
                  </Link>
                  <Box
                    display={{ xs: 'none', md: 'block' }}
                  >
                    <Box component="span" className={classes.linkLabel}>
                      No account yet?
                    </Box>
                    <Link to="/accounts/create-account" className={classes.link}>
                      Sign up here
                    </Link>
                  </Box>
                </Box>
                <SubmitButton
                  loading={loading}
                  submitting={submitting}
                  className={classes.submitBtn}
                >
                  Login
                </SubmitButton>
                <Box
                  display={{ xs: 'block', md: 'none' }}
                  textAlign="center"
                  mt="20px"
                >
                  <Box component="span" className={classes.linkLabel}>
                    No account yet?
                  </Box>
                  <Link to="/accounts/create-account" className={classes.link}>
                    Sign up here
                  </Link>
                </Box>
              </form>
            )}
          />
        </Box>
      </Box>
      <Box className={classes.rightBox}>
        <Box width="100%">
          <Box
            width="100%"
            height="400px"
            textAlign="center"
            mb="20px"
          >
            <img src="/images/monitor.png" className={classes.rightDisplay} alt="display" />
          </Box>
          <Box className={classes.raleway38}>
            Expert Calls, On Demand
          </Box>
          <Box className={classes.quicksand20}>
            With Stream by Mosaic, you have instant access to a library of
            transcripts and in-depth interviews with former
            executives, customers, competitors and channel participants.
          </Box>
          <Box textAlign="center">
            <a href="https://www.streamrg.com/" className={classes.learnMore}>Learn more</a>
            <Link to="/accounts/create-account/" className={classes.startTrial}>Start Your FREE 21-Days Trial</Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

LogIn.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(LogIn);
