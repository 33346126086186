/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useMediaQuery } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../../../helpers/dateHelpers';
import {
  CollapseInfo,
  ComplianceAccessRequests,
  EndlessScrollPreview,
  IndustryItem,
  InterviewNotFound,
  KeywordsGroup,
  LegalDisclaimer,
  MemberAccessRequest,
  MemberAccessRequestStatus,
  MoreOn,
  OrderInterview,
  OrderInterviewStatus,
  Person,
  Perspective,
  RequestExpertCallBtn,
  Tickers,
  TranscriptRatingBanner,
  TrialBanner,
} from '../../../../components/InterviewPreviewComponents';
// import { LinearProgressMosaic } from '../../../../components';
import {
  INTERVIEW_QUERY,
  SET_INTERVIEW_PERCENTS_READ_MUTATION,
} from '../../../../queries/interview/interview';
import { COMPLIANCE_ROLES } from '../../../../config';
import InterviewPlayer from './components/InterviewPlayer';
import InterviewPreviewSkeleton from './components/InterviewPreviewSkeleton';
import { searchToObject } from '../../../../helpers/locationSearchToJson';
import InterviewActions from './components/InterviewActions';
import { ReactComponent as ExpertCallIcon } from '../../../../assets/icons/expertCallIcon.svg';
// import TopContext from '../../../../context/topContext';
import { writeFilterData } from '../../../../actions/filterActions';
import useAllIntervies from '../../../../hooks/useAllIntervies';
import useMe from '../../../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '56px 10px 20px',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      padding: '10px 30px 20px 20px',
    },
    overflowY: 'scroll',
  },
  headline: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
      lineHeight: '28px',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
  },
  date: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  disabled: {
    '& #audioPlayer': {
      pointerEvents: 'none',
      cursor: 'default',
    },
  },
  trialView: {
    position: 'relative',
    '&::before': {
      content: '""',
      width: '100%',
      height: '100px',
      position: 'absolute',
      left: '0',
      bottom: '0',
      top: 'auto',
      backgroundImage: 'linear-gradient(180deg,rgba(255,255,255,0) 0,rgba(255,255,255,.6) 30%,#fff 100%)',
    },
    '& .tts': {
      pointerEvents: 'none',
      cursor: 'default',
    },
  },
  expertView: {
    '& .tts': {
      display: 'none !important',
    },
  },
  notFoundBlock: {
    alignItems: 'center!important',
    justifyContent: 'center!important',
  },
  centerContent: {
    marginBottom: '1.5rem!important',
    marginTop: '5.5rem!important',
    justifyContent: 'center!important',
    flexDirection: 'column!important',
    display: 'flex!important',
  },
  errorBlockImage: {
    maxWidth: '40vw',
    overflow: 'hidden',
    marginBottom: '1.5rem!important',
    marginLeft: 'auto!important',
    marginRight: 'auto!important',
  },
  errorMessage: {
    fontFamily: 'PT Root UI',
    fontSize: '24px',
    // fontWeight: 'bold',
    lineHeight: '28px',
    color: '#182341',
    textAlign: 'center!important',
    marginBottom: '1.5rem!important',
  },
  link: {
    color: '#1168A7',
    marginLeft: '0.3em',
  },
  requestStatus: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '16px',
    // marginBottom: '10px',
    float: 'right',
    // alignItems: 'center!important',
    display: 'flex!important',
  },
  requestStatusSpan: {
    fontWeight: '500',
    color: '#8293A6',
    marginLeft: '3px',
    marginRight: '3px',
  },
  callDate: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginRight: '10px',
  },
  inData: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    padding: '0 10px',
    borderLeft: '1px solid',
    borderLeftColor: theme.palette.info.dark,
    marginBottom: '10px',
  },
  tickerDesc: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '22px',
    color: theme.palette.text.primary,
    marginRight: '15px',
    marginBottom: '8px',
  },
  vDivider: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.info.dark,
    paddingRight: '10px',
    marginRight: '10px',
  },
  keyTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    marginBottom: '8px',
    marginRight: '7px',
  },
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  fDivider: {
    marginTop: '4px',
    marginBottom: '12px',
  },
  nDivider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  expertCallBtn: {
    height: '30px',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderRadius: '5px',
    borderColor: theme.palette.info.main,
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.common.white,
      },
    },
    '&.Mui-disabled': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.info.main,
      '& svg path': {
        fill: theme.palette.text.secondary,
      },
    },
  },
  mostProminent: {
    backgroundColor: '#F9FBFF',
    borderLeft: '2px solid',
    borderLeftColor: theme.palette.primary.main,
    marginLeft: 0,
    padding: '2px 0 4px 15px',
    '& ul': {
      listStyleType: 'none',
      '& li:before': {
        content: '"-"',
        marginLeft: '-20px',
        marginRight: '10px',
      },
    },
  },
}));

const InterviewPreview = ({
  interviewId, playerId, isFullScreen, onScroll,
}) => {
  const interviewContainer = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    interviewContainer.current.scrollTop = 0;
  }, [interviewId]);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { trackEvent } = useTracking();
  let legalDisclaimerElHeight = 0;
  let moreOnElHeight = 0;
  let endlessScrollPreviewElHeight = 0;
  let scrollProgress = 0;
  let readProgress;
  let percent;

  const matchInterview = useRouteMatch({ path: '/:interviewId', exact: true });
  const matchHome = useRouteMatch({ path: '/', exact: true });

  const isInterviewPage = !isEmpty(matchInterview) || !isEmpty(matchHome);

  const isAutoDisplayed = () => {
    if (location.state) {
      return location.state.auto_displayed || false;
    }
    return false;
  };

  const [getInterview, {
    loading,
    // error,
    data: { interview = {} } = {},
    // called,
  }] = useLazyQuery(INTERVIEW_QUERY, {
    variables: { interviewId, autoDisplayed: isAutoDisplayed() },
  });

  useEffect(() => {
    if (!loading) {
      getInterview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interviewId]);

  const { me, loading: meLoading } = useMe();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [setInterviewPercentsRead] = useMutation(SET_INTERVIEW_PERCENTS_READ_MUTATION);

  useEffect(() => {
    if (interview.interviewId) {
      trackEvent({
        viewName: 'interview:interview_detail',
        appLabel: 'interview',
        model: 'interview',
        objectId: interview.id,
        path: location.pathname,
        getDict: JSON.stringify({ ...location.state, ...searchToObject(location.search) }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview.interviewId]);

  const handleExpertFilter = (user) => {
    if (!isInterviewPage) {
      history.push('/');
    }
    dispatch(writeFilterData({ expert: [user] }));
  };

  const renderSectors = () => {
    if (isEmpty(interview.tickers)) return null;

    const primarySector = interview.tickers[0].ticker.sector;

    if (!primarySector) return null;

    return (
      <IndustryItem
        filterKey="sectors"
        interviewId={interview.interviewId}
        itemId={primarySector.id}
        itemTitle={primarySector.title}
        itemUUID={primarySector.uuid}
        filterMenuItemText="Filter by this Sector"
        isAddedToWatchList={primarySector.isAddedToWatchList}
      />
    );
  };

  const renderIndustries = () => {
    if (isEmpty(interview.tickers)) return null;

    const primaryIndustry = interview.tickers[0].ticker.industry;

    if (!primaryIndustry) return null;

    return (
      <IndustryItem
        filterKey="industries"
        interviewId={interview.interviewId}
        itemId={primaryIndustry.id}
        itemTitle={primaryIndustry.title}
        itemUUID={primaryIndustry.uuid}
        filterMenuItemText="Filter by this Industry"
        isAddedToWatchList={primaryIndustry.isAddedToWatchList}
      />
    );
  };

  const totalLoading = loading || meLoading;

  // ogurets
  // const context = useContext(TopContext);

  // const { updateInterviewQuery } = context;
  const { updateQuery: updateAllInterviewsQuery } = useAllIntervies();

  const handleProgress = debounce(() => {
    if ((interview?.id && percent) && (percent > interview.percentsRead)) {
      setInterviewPercentsRead({
        variables: {
          interviewPk: interview.id,
          percentsRead: percent,
        },
        update: () => {
          updateAllInterviewsQuery((previousQueryResult) => {
            const results = previousQueryResult.allInterviews?.results.map((el) => {
              if (el.interviewId === interview.interviewId) {
                return { ...el, percentsRead: percent };
              }
              return el;
            });

            return {
              allInterviews: {
                ...previousQueryResult.allInterviews,
                results,
              },
            };
          });
        },
      });
    }
  }, 1000);

  const handleSticky = () => {
    const headerHeight = isDesktop ? 0 : 56;
    const actionsHeight = 45;
    const actionsBox = document.getElementById('interviewActions');
    const player = document.getElementById('audioPlayer');
    const ratingBox = document.getElementById('transcriptRating');
    let actionsBoxShouldSticky;
    let playerShouldSticky;
    let ratingBoxShouldSticky;
    if (actionsBox) {
      actionsBoxShouldSticky = actionsBox.getBoundingClientRect().top <= headerHeight;
      if (actionsBoxShouldSticky) {
        actionsBox.firstChild.style.position = 'fixed';
        actionsBox.firstChild.style.top = `${headerHeight}px`;
      } else {
        actionsBox.firstChild.style.position = 'static';
      }
    }
    if (player) {
      playerShouldSticky = player.getBoundingClientRect().top <= actionsHeight + headerHeight;
      if (playerShouldSticky) {
        player.firstChild.style.position = 'fixed';
        player.firstChild.style.top = `${actionsHeight + headerHeight}px`;
        player.firstChild.style.height = '75px';
        player.firstChild.style.background = 'linear-gradient(180deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0.2) 100%)';
        player.firstChild.firstChild.style.height = '30px';
        player.firstChild.firstChild.style.padding = '0 10px';
      } else {
        player.firstChild.style.position = 'static';
        player.firstChild.style.height = '50px';
        player.firstChild.firstChild.style.height = 'auto';
        player.firstChild.firstChild.style.padding = '8px 10px';
      }
    }
    if (ratingBox && percent >= 40) {
      ratingBoxShouldSticky = ratingBox.getBoundingClientRect().top >= document.documentElement.clientHeight;
      if (ratingBoxShouldSticky && interview.grade === null) {
        ratingBox.firstChild.style.position = 'fixed';
        ratingBox.firstChild.style.bottom = '0';
      } else {
        ratingBox.firstChild.style.position = 'static';
      }
    }
    return { actionsBoxShouldSticky, playerShouldSticky, ratingBoxShouldSticky };
  };

  const handleReadProgress = () => {
    const interviewEl = interviewContainer.current;
    const legalDisclaimerEl = document.getElementById('legalDisclaimer');
    const moreOnEl = document.getElementById('moreOn');
    const endlessScrollPreviewEl = document.getElementById('endlessScrollPreview');
    const otherSpace = 204;
    if (legalDisclaimerEl) {
      legalDisclaimerElHeight = legalDisclaimerEl.scrollHeight;
    }
    if (moreOnEl) {
      moreOnElHeight = moreOnEl.scrollHeight;
    }
    if (endlessScrollPreviewEl) {
      endlessScrollPreviewElHeight = endlessScrollPreviewEl.scrollHeight;
    }
    const bottomContent = legalDisclaimerElHeight + moreOnElHeight + endlessScrollPreviewElHeight + otherSpace;
    if (interviewEl) {
      const interviewHeight = interviewEl.scrollHeight - bottomContent;
      const scrollDistance = interviewEl.scrollTop + window.innerHeight;
      if (scrollProgress < scrollDistance) {
        scrollProgress = scrollDistance;
        readProgress = scrollProgress / interviewHeight;
        if (readProgress < 1) {
          percent = Math.round(readProgress * 100);
        } else {
          percent = 100;
        }
      }
    }
    return percent;
  };

  const handleScroll = () => {
    if (interview.userCanViewInterview && me.role !== 'EXPERT' && me.hasPaidAccess && interview.transcriptionStatus === 'TRANSCRIBED') {
      handleReadProgress();
    }
    const stickyStatus = handleSticky();
    handleProgress();
    onScroll(stickyStatus);
  };

  useEffect(() => {
    if (!isEmpty(interview)) {
      window.dataLayer.push({
        event: 'interviewRendered', id: interview.uuid, auto_shown: isAutoDisplayed(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview]);

  return (
    <Box
      className={clsx(classes.root, 'xlink-interview-full-panel')}
      data-xlink-interview-id={interview.uuid}
      data-xlink-auto-shown={isAutoDisplayed()}
      onScroll={handleScroll}
      ref={interviewContainer}
    >
      {/* <LinearProgressMosaic loading={totalLoading} /> */}
      {totalLoading && (
        <Fade in={totalLoading} timeout={{ enter: 100, exit: 100 }}>
          <Box>
            <InterviewPreviewSkeleton />
          </Box>
        </Fade>
      )}
      <Fade in={!totalLoading} timeout={{ enter: 100, exit: 100 }}>
        <Box height="100%">
          {!isEmpty(interview)
            ? (
              <>
                {COMPLIANCE_ROLES.includes(me.role)
                && (
                  <Box mb="15px">
                    <ComplianceAccessRequests accessRequests={interview.accessRequests} />
                    <Divider />
                  </Box>
                )}

                {interview.accessRequest
                && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  mb={{ xs: '10px', md: '20px' }}
                >
                  {interview.accessRequest && interview.accessRequest.status === 'APPROVED'
                    ? (
                      <Box className={classes.requestStatus}>
                        <svg
                          className="mr-2"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 6L7 10L5 8"
                            stroke="#1168A7"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                            stroke="#1168A7"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <Box
                          display="flex!important"
                        >
                          <span className={classes.requestStatusSpan}>Approved by</span>
                          {interview.accessRequest.statusChangedBy
                            && interview.accessRequest.statusChangedBy.fullName}
                          <span className={classes.requestStatusSpan}>on</span>
                          {formatDate(interview.accessRequest.statusChangedAt)}
                        </Box>
                      </Box>
                    ) : ''}
                </Box>
                )}

                <InterviewActions
                  isCallRequestAllowed={interview.expert?.isCallRequestAllowed}
                  expertPk={interview.expert?.id}
                  interviewId={interview.interviewId}
                  interviewPK={interview.id}
                  isFullScreen={isFullScreen}
                  isDesktop={isDesktop}
                  isPrintAllowed={interview.userCanViewInterview && me.hasPaidAccess && interview.transcriptionStatus === 'TRANSCRIBED'}
                  isMarkUnreadAllowed={me.role === 'MEMBER' && interview.userCanViewInterview && me.hasPaidAccess && interview.transcriptionStatus === 'TRANSCRIBED' && interview.percentsRead >= 5}
                  isInviteGuestsAllowed={me.role === 'MEMBER' && interview.userCanViewInterview && me.hasPaidAccess && interview.transcriptionStatus === 'TRANSCRIBED'}
                  readingListItem={interview.readingListItem}
                  isExpert={me.role === 'EXPERT'}
                />

                <Box mb="10px">
                  {!isEmpty(interview.callDate)
                  && (
                    <Box
                      component="span"
                      className={classes.callDate}
                    >
                      {formatDate(interview.callDate)}
                    </Box>
                  )}

                  {renderSectors()}

                  {renderIndustries()}
                </Box>

                {!isEmpty(interview.tickers)
                && (
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    {(!isEmpty(interview.tickers[0]) && !isEmpty(interview.tickers[0].ticker.description))
                    && (
                      <Box className={classes.tickerDesc}>
                        {interview.tickers[0].ticker.description}
                      </Box>
                    )}
                    {interview.tickers.map(({ ticker }) => (
                      <Tickers
                        key={ticker.id}
                        uuid={ticker.uuid}
                        tickerId={ticker.id}
                        title={ticker.title}
                        interviewId={interview.interviewId}
                        company={ticker?.company?.id}
                        isAddedToWatchList={ticker.isAddedToWatchList}
                      />
                    ))}
                  </Box>
                )}

                <Box mb="8px">
                  {!isEmpty(interview.perspective)
                  && (
                    <Perspective>
                      {interview.perspective}
                    </Perspective>
                  )}

                  {(!isEmpty(interview.perspective) && me.role !== 'EXPERT')
                  && (
                    <Box component="span" className={classes.vDivider} />
                  )}

                  {me.role !== 'EXPERT'
                  && (
                    <Box component="span" className={classes.title}>
                      {interview.relevantRole}
                    </Box>
                  )}
                </Box>

                {!isEmpty(interview.keywords)
                && (
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Box className={classes.keyTitle}>
                      Tags:
                    </Box>
                    <KeywordsGroup
                      interviewId={interview.interviewId}
                      keywords={interview.keywords}
                    />
                  </Box>
                )}

                <Divider className={classes.fDivider} />

                {!isEmpty(interview.headline)
                && (
                  <Box mb="8px">
                    <Typography
                      variant="h3"
                      component="h3"
                      className={classes.headline}
                    >
                      {interview.headline}
                    </Typography>
                  </Box>
                )}

                <Box>
                  {(!isEmpty(interview.summary) && interview.userCanViewInterview && me.hasPaidAccess)
                  && (
                    <Box mb="8px" className={classes.subtitle}>
                      <Box mb="6px">
                        Call Summary:
                      </Box>
                      <CollapseInfo className={classes.mostProminent}>
                        {interview.summary || 'No Information'}
                      </CollapseInfo>
                    </Box>
                  )}

                  {(!isEmpty(interview.tableOfContents) && interview.transcriptionStatus === 'TRANSCRIBED')
                  && (
                    <Box mb="8px" className={classes.subtitle}>
                      <Box mb="6px">
                        Table of Contents:
                      </Box>
                      <CollapseInfo className={classes.mostProminent}>
                        {interview.tableOfContents || 'No Information' }
                      </CollapseInfo>
                    </Box>
                  )}

                  {(!isEmpty(interview.headline) || !isEmpty(interview.summary) || !isEmpty(interview.tableOfContents))
                  && (
                    <Divider className={classes.nDivider} />
                  )}

                  {(!isEmpty(interview.expert) && me.role !== 'EXPERT')
                  && (
                    <>
                      <Box mb="8px" className={classes.subtitle}>
                        <Box mb="6px">
                          Expert Bio:
                        </Box>
                        <CollapseInfo>
                          {interview.expert.bio || 'No Information'}
                        </CollapseInfo>
                      </Box>

                      <Box mb="8px">
                        <RequestExpertCallBtn
                          enabled={interview.expert?.isCallRequestAllowed}
                          expertPk={interview.expert?.id}
                          variant="outlined"
                          className={classes.expertCallBtn}
                          startIcon={<ExpertCallIcon />}
                          interviewId={interview.interviewId}
                        />
                      </Box>

                      <Box mb="8px" className={classes.subtitle}>
                        <Box mb="6px">
                          Employment History:
                        </Box>
                        <CollapseInfo>
                          {interview.expert.employmentHistory || 'No Information'}
                        </CollapseInfo>
                      </Box>
                    </>
                  )}

                  {(COMPLIANCE_ROLES.includes(me.role) && !isEmpty(interview.questionnaire))
                  && (
                    <Box mb="8px" className={classes.subtitle}>
                      <Box mb="6px">
                        Questionnaire:
                      </Box>
                      <CollapseInfo>
                        {interview.questionnaire || 'No Information'}
                      </CollapseInfo>
                    </Box>
                  )}
                </Box>

                {/* TODO remove from backend */}
                {/* {(me.compliance && me.compliance.length === 0 && MEMBER_ROLES.includes(me.role))
                && <AddComplianceOfficer interviewId={interview.interviewId} />} */}

                {me.role !== 'EXPERT'
                  ? (
                    (!isEmpty(interview.expert) || !isEmpty(interview.questionnaire))
                    && (
                      <Divider className={classes.nDivider} />
                    )
                  )
                  : (
                    (!isEmpty(interview.questionnaire))
                    && (
                      <Divider className={classes.nDivider} />
                    )
                  )}

                {!isEmpty(interview.audio) && me.role !== 'EXPERT'
                && (
                  <InterviewPlayer
                    hasPaidAccess={me.hasPaidAccess}
                    userCanViewInterview={interview.userCanViewInterview}
                    playerId={playerId}
                    audio={interview.audio}
                  />
                )}

                <Box
                  display="flex"
                  alignItems={{ xs: 'start', md: 'center' }}
                  flexDirection={{ xs: 'column', md: 'row' }}
                >
                  {!isEmpty(interview.expert)
                  && (
                    <Person
                      purpose="Expert"
                      name={interview.expert.fullName}
                      onClick={() => handleExpertFilter(interview.expert)}
                    />
                  )}
                </Box>

                {(!interview.userCanViewInterview && me.role !== 'EXPERT' && interview.transcriptionStatus === 'TRANSCRIBED')
                  && (interview.accessRequest
                    ? (
                      <MemberAccessRequestStatus
                        accessRequest={interview.accessRequest}
                        // complianceOfficers={me.compliance}
                        // role
                        interviewId={interview.interviewId}
                        interviewPK={interview.id}
                      />
                    )
                    : <MemberAccessRequest interviewId={interview.interviewId} />)}

                {interview.transcriptionStatus === 'NOT_TRANSCRIBED'
                && (
                  (interview.orders && interview.orders.length > 0)
                    ? <OrderInterviewStatus createdAt={interview.orders[0].createdAt} />
                    : <OrderInterview interviewId={interview.interviewId} />
                )}

                {interview.transcriptionStatus === 'TRANSCRIBED'
                && (
                  (me.role === 'EXPERT')
                    ? (
                      <Box
                        dangerouslySetInnerHTML={{ __html: interview.content }}
                        className={classes.expertView}
                      />
                    )
                    : (
                      <>
                        {interview.userCanViewInterview && !me.hasPaidAccess
                          ? (
                            <Box
                              dangerouslySetInnerHTML={{ __html: interview.content }}
                              className={classes.trialView}
                            />
                          )
                          : <Box dangerouslySetInnerHTML={{ __html: interview.content }} />}
                      </>
                    )
                )}

                {(interview.userCanViewInterview && !me.hasPaidAccess && interview.transcriptionStatus === 'TRANSCRIBED')
                && <TrialBanner />}

                {!isEmpty(interview.expert)
                && (
                  <Box mb="24px">
                    <RequestExpertCallBtn
                      enabled={interview.expert?.isCallRequestAllowed}
                      expertPk={interview.expert?.id}
                      variant="outlined"
                      className={classes.expertCallBtn}
                      startIcon={<ExpertCallIcon />}
                      interviewId={interview.interviewId}
                      title={me.role === 'EXPERT' && 'Schedule Consultation'}
                      otherTitle={me.role === 'EXPERT' && 'Consultation Scheduled'}
                      hideTooltip={me.role !== 'EXPERT' && true}
                      tooltipTitle={me.role === 'EXPERT' && 'Click Here to Schedule a Paid Consultation with the Expert'}
                    />
                  </Box>
                )}

                {(interview.userCanViewInterview && me.hasPaidAccess && interview.transcriptionStatus === 'TRANSCRIBED')
                && (
                  <TranscriptRatingBanner
                    interviewId={interview.interviewId}
                    interviewGrade={interview?.grade?.value}
                    isAutoDisplayed={isAutoDisplayed()}
                  />
                )}

                <LegalDisclaimer />

                {!isEmpty(interview.tickers)
                && <MoreOn tickers={interview.tickers} interviewId={interview.interviewId} />}

                {!isEmpty(interview.tickers)
                && <EndlessScrollPreview tickers={interview.tickers} interviewId={interview.interviewId} />}
              </>
            )
            : (
              <InterviewNotFound />
            )}
        </Box>
      </Fade>
    </Box>
  );
};

InterviewPreview.defaultProps = {
  playerId: '',
  isFullScreen: false,
  onScroll: () => {},
};

InterviewPreview.propTypes = {
  interviewId: PropTypes.string.isRequired,
  isFullScreen: PropTypes.bool,
  playerId: PropTypes.string,
  onScroll: PropTypes.func,
};

const areEqual = (prevProps, nextProps) => prevProps.interviewId === nextProps.interviewId;

export default React.memo(InterviewPreview, areEqual);
