/* eslint-disable max-len */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { StaticDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { POSTPONE_TRIAL_MUTATION } from '../../queries/outreachIntergration';
import { formatIsoDate, formatDate } from '../../helpers/dateHelpers';
import StartTrialLaterDone from './StartTrialLaterDone';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1260px',
    width: '100%',
    padding: '20px 20px 30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
  },
  calendarBlock: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 71px)',
    flexGrow: '1',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: 'calc(100vh - 71px)',
      flexGrow: '1',
    },
  },
  title: {
    maxWidth: '630px',
    width: '100%',
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '30px',
    color: theme.palette.text.primary,
    marginBottom: '15px',
    [theme.breakpoints.up('md')]: {
      fontSize: '48px',
      lineHeight: '60px',
    },
  },
  list: {
    padding: '0',
    '& .MuiListItem-root': {
      padding: '0',
      marginBottom: '10px',
      '& .MuiListItemIcon-root': {
        minWidth: '30px',
      },
      '& .MuiListItemText-root': {
        margin: '0',
      },
      '& .MuiListItemText-root span': {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '24px',
        color: theme.palette.text.primary,
        letterSpacing: '0.114286px',
      },
    },
  },
  primaryBtn: {
    height: '40px',
    padding: '10px 20px',
    color: theme.palette.common.white,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      marginRight: '10px',
      marginBottom: '0',
    },
    '&:hover': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.light,
    },
  },
  secondaryBtn: {
    height: '40px',
    padding: '10px 20px',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
    },
    '&:active': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.main,
    },
  },
  calendar: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    padding: '30px 10px',
    borderRadius: '10px',
  },
  calendarTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom: '10px',
  },
  testimonialsBlock: {
    display: 'flex',
    flexDirection: 'column',
    margin: '50px 0',
    [theme.breakpoints.up('md')]: {
      margin: '20px 0 50px',
    },
  },
  subtitle: {
    fontSize: '36px',
    fontWeight: 'bold',
    lineHeight: '45px',
    color: theme.palette.text.primary,
    marginBottom: '40px',
  },
  testimonialsBox: {
    maxWidth: '520px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  testimonialsContent: {
    fontFamily: 'PT serif!important',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '32px',
    fontStyle: 'italic',
    color: theme.palette.text.primary,
    marginBottom: '15px',
  },
  testimonialsContentTitle: {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '24px',
    color: theme.palette.text.primary,
  },
}));

const StartTrialLater = () => {
  const [value, setValue] = useState(new Date());
  const [isDateChanged, setIsDateChanged] = useState(false);
  const { hashStr } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [postponeTrialFunc] = useMutation(POSTPONE_TRIAL_MUTATION);

  const handleDateChange = (newValue) => {
    setValue(newValue);
    postponeTrialFunc({
      variables: {
        hashStr,
        newDate: formatIsoDate(newValue),
      },
    }).then(({ data: { addIndustryToFollowing = {} } = {} }) => {
      if (!isEmpty(addIndustryToFollowing.errors)) {
        enqueueSnackbar(addIndustryToFollowing.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        setIsDateChanged(true);
      }
    });
  };

  if (isDateChanged) {
    return <StartTrialLaterDone date={formatDate(value)} />;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.calendarBlock}>
        <Box display="flex" flexDirection="column" flexGrow="1">
          <Box className={classes.title}>
            When would you like to access our library of transcripts & one-on-one calls with experts?
          </Box>
          <Box mb="10px">
            <List className={classes.list}>
              <ListItem disableGutters>
                <ListItemIcon>
                  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.707 9.707C5.316 10.098 4.683 10.098 4.293 9.707L1 6.414C0.61 6.024 0.61 5.39 1 5C1.39 4.61 2.024 4.61 2.414 5L5 7.586L11.586 1C11.976 0.61 12.61 0.61 13 1C13.39 1.39 13.39 2.024 13 2.414L5.707 9.707Z" fill="#1168A7" />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary="Full Transcripts of All Calls"
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.707 9.707C5.316 10.098 4.683 10.098 4.293 9.707L1 6.414C0.61 6.024 0.61 5.39 1 5C1.39 4.61 2.024 4.61 2.414 5L5 7.586L11.586 1C11.976 0.61 12.61 0.61 13 1C13.39 1.39 13.39 2.024 13 2.414L5.707 9.707Z" fill="#1168A7" />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary="Full Audio of All Calls"
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.707 9.707C5.316 10.098 4.683 10.098 4.293 9.707L1 6.414C0.61 6.024 0.61 5.39 1 5C1.39 4.61 2.024 4.61 2.414 5L5 7.586L11.586 1C11.976 0.61 12.61 0.61 13 1C13.39 1.39 13.39 2.024 13 2.414L5.707 9.707Z" fill="#1168A7" />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary="AI-Generated Call Summaries and Smart Tags"
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.707 9.707C5.316 10.098 4.683 10.098 4.293 9.707L1 6.414C0.61 6.024 0.61 5.39 1 5C1.39 4.61 2.024 4.61 2.414 5L5 7.586L11.586 1C11.976 0.61 12.61 0.61 13 1C13.39 1.39 13.39 2.024 13 2.414L5.707 9.707Z" fill="#1168A7" />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary="Watchlists with Custom Alerts "
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemIcon>
                  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.707 9.707C5.316 10.098 4.683 10.098 4.293 9.707L1 6.414C0.61 6.024 0.61 5.39 1 5C1.39 4.61 2.024 4.61 2.414 5L5 7.586L11.586 1C11.976 0.61 12.61 0.61 13 1C13.39 1.39 13.39 2.024 13 2.414L5.707 9.707Z" fill="#1168A7" />
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary="Discount Access to Calls with Stream Experts and Analyst"
                />
              </ListItem>
            </List>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            mb={{ xs: '20px', md: '0' }}
          >
            <Link
              to={`/accounts/first-login/${hashStr}`}
              className={classes.primaryBtn}
            >
              Get Access Now
            </Link>
            <Link
              to={`/not-interested-in-trial/${hashStr}`}
              className={classes.secondaryBtn}
            >
              I&apos;m Not Interested Anymore
            </Link>
          </Box>
        </Box>
        <Box className={classes.calendar}>
          <Box className={classes.calendarTitle}>
            Please Select a Date
          </Box>
          <StaticDatePicker
            label="New Date"
            displayStaticWrapperAs="desktop"
            openTo="date"
            value={value}
            onChange={handleDateChange}
            renderInput={(props) => <TextField {...props} />}
          />
        </Box>
      </Box>
      <Box className={classes.testimonialsBlock}>
        <Box className={classes.subtitle}>
          Testimonials
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex" mb={{ xs: '20px', md: '0' }}>
              <Box className={classes.testimonialsBox}>
                <Box className={classes.testimonialsContent}>
                  “With stream by mosaic, you get access to conversations with industry experts curated by people
                  knowledgeable in the field. In other words: quality questions and quality answers. Having access to
                  this robust library of content can save time and add fresh perspectives to the research process.”
                </Box>
                <Box className={classes.testimonialsContentTitle}>
                  - Senior Analyst, $1B L/S equity fund
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="flex-end">
              <Box className={classes.testimonialsBox}>
                <Box className={classes.testimonialsContent}>
                  “I&apos;ve found stream by mosaic to be a great service because its cost-effective and allows
                  you to see what questions other investors are asking. It&apos;s also a good way to learn about
                  a company without conducting your own expert call. The questions asked were the most insightful
                  part of the service.”
                </Box>
                <Box className={classes.testimonialsContentTitle}>
                  - Analyst, $5B hedge fund
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StartTrialLater;
