import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.secondary,
    padding: '41px 0',
  },
  footer: {
    fontSize: '14px',
    lineHeight: '18px',
    padding: '0 20px',
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <Box
      display="block"
      displayPrint="none"
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        className={classes.footer}
      >
        <Box
          textAlign="center"
        >
          Copyright &copy; 2021 Stream by Mosaic Research Management Inc. - All right Reserved.
        </Box>
      </Box>
    </Box>
  );
};

Footer.defaultProps = {
  className: '',
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
