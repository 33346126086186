import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.info.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SubmitButton = ({
  submitting, loading, children, ...rest
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Button
        type="submit"
        disabled={submitting}
        disableRipple
        {...rest}
      >
        {children}
      </Button>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

SubmitButton.propTypes = {
  submitting: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};

export default SubmitButton;
