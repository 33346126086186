import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    '& .MuiList-root': {
      padding: '0',
      '& .MuiButtonBase-root': {
        minHeight: '16px!important',
        padding: '0!important',
        '&.Mui-disabled': {
          opacity: '1',
          '& .MuiTypography-root': {
            color: theme.palette.info.main,
          },
          '& svg path': {
            stroke: theme.palette.info.main,
          },
        },
        '& .MuiListItemText-root': {
          margin: '0',
          '& .MuiTypography-root': {
            fontSize: '13px',
            fontWeight: 'bold',
            lineHeight: '16px',
            color: theme.palette.text.primary,
          },
        },
        '& svg': {
          marginLeft: '6px',
        },
        '&:hover': {
          backgroundColor: theme.palette.common.white,
          cursor: 'pointer',
          '& svg path': {
            stroke: theme.palette.primary.main,
          },
        },
      },
    },
  },
  menuItem: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));

const options = {
  REALTIME: 'Real Time',
  WEEKDAYS: 'Weekdays',
  ONCE_A_WEEK: 'Once a Week',
};

const PeriodMenu = ({ onChange, frequency, disabled }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, key) => {
    onChange(key);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.root}>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          disabled={disabled}
          disableRipple
          onClick={handleClickListItem}
        >
          <ListItemText primary={options[frequency]} />
          <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1668 1.85715L6.00016 6.14286L1.8335 1.85715" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.entries(options).map(([key, value]) => (
          <MenuItem
            key={key}
            selected={key === value}
            disableRipple
            onClick={(event) => handleMenuItemClick(event, key)}
            className={classes.menuItem}
          >
            {value}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

PeriodMenu.defaultProps = {
  disabled: false,
};

PeriodMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
  frequency: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default PeriodMenu;
