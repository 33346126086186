/* eslint-disable max-len */
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    maxWidth: '940px',
    width: '100%',
    padding: '20px 20px 30px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  document: {
    background: theme.palette.background.paper,
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      padding: '50px',
    },
  },
  ul: {
    paddingLeft: '30px',
    marginBottom: '16px',
    '& li': {
      color: '#182341',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      letterSpacing: '-0.05px',
    },
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.document}>
        <Typography variant="h1" align="center" gutterBottom>STREAM MEDIA GROUP INC.</Typography>
        <Typography variant="h2" align="center" gutterBottom>PRIVACY POLICY</Typography>

        <Typography paragraph gutterBottom>This Privacy Policy discloses the privacy practices for the website located at stream.mosaicrm.com (the “Site”) operated by STREAM MEDIA GROUP INC., a Delaware corporation (“SMG,” “us,” “we,” or “our”). We are committed to protecting your privacy online. Please read the information below to learn the following regarding your use of the Site:</Typography>
        <ol className={classes.ul}>
          <li>What information do we collect from you?</li>
          <li>Where do we collect information from you and how do we use it?</li>
          <li>With whom do we share your information?</li>
          <li>How can you update, correct or delete your Personally Identifiable Information?</li>
          <li>What are your choices regarding collection, use and distribution of your information?</li>
          <li>What security precautions are in place to protect against the loss, misuse or alteration of your information?</li>
          <li>Do we use “cookies”?</li>
        </ol>

        <Typography paragraph gutterBottom>You acknowledge that this Privacy Policy is part of our Terms of Use, and by accessing or using our Site you agree to be bound by all of their terms and conditions. If you do not agree to these terms, please do not access or use the Site.</Typography>
        <Typography paragraph gutterBottom>We reserve the right to change this Privacy Policy at any time. Such changes, modifications, additions or deletions shall be effective immediately upon notice thereof, which may be given by means including, but not limited to issuing an e-mail to the e-mail address listed by registered users or posting the revised Policy on this page or elsewhere on the Site. You acknowledge and agree that it is your responsibility to maintain a valid e-mail address as a registered user, review this site and this Policy periodically and to be aware of any modifications. Your continued use of the Site after such modifications will constitute your: (a) acknowledgment of the modified Policy; and (b) agreement to abide and be bound by the modified Policy.</Typography>
        <ol className={classes.ul}>
          <li>
            What information do we collect from you?
            <br />
            <Typography paragraph gutterBottom>In order to better provide you with our services, we collect two types of information about our users: Personally Identifiable Information and Non-Personally Identifiable Information. Our primary goal in collecting information from you is to provide you with a smooth, efficient, and customized experience while using our Site.</Typography>
            <Typography paragraph gutterBottom>Personally Identifiable Information: This refers to information that lets us know the specifics of who you are. This information may include, without limitation, your first and last name, email address, physical address, and your payment (such as credit card) information provided to access our subscription services.</Typography>
            <Typography paragraph gutterBottom>Non-Personally Identifiable Information: This refers to information that does not by itself identify a specific individual. This information is compiled and analyzed on both a personal and an aggregated basis.</Typography>
          </li>
          <li>
            Where do we collect information from you and how do we use it?
            <br />
            <Typography paragraph gutterBottom>We do not collect any Personally Identifiable Information about you unless you voluntarily provide it to us. You provide certain Personally Identifiable Information to us when you register for a user account or sign up for subscription services to utilize our services, for example.</Typography>
            <Typography paragraph gutterBottom>In addition, we may also collect, or our third party ad server and/or content server may collect, certain Non-Personally Identifiable Information to diagnose problems with our systems/software and to gather demographic information.</Typography>
            <Typography paragraph gutterBottom>We will use your Personally Identifiable Information to provide our services to you. For example, we will use your information to create your user account and enable you to access the interview transcripts and audio recordings. We will also use Personally Identifiable Information to enhance the operation of our Site, improve our marketing and promotional efforts, statistically analyze the Site use, improve our product and service offerings, and customize our Site’s content, layout, and services. We may use Personally Identifiable Information to deliver information to you and to contact you regarding administrative notices and regarding product or service offerings which we believe may be of interest to you. You will be given an opportunity to opt out of receiving promotional materials from us. We may also use Personally Identifiable Information to troubleshoot problems and enforce our agreements with you, including our Terms of Use and this Private Policy. While you may opt-out of receiving our promotional communications by taking the measures outlined in such communications, you may not opt-out of receiving administrative communications.</Typography>
          </li>
          <li>
            With whom do we share your information?
            <br />
            <Typography paragraph gutterBottom>We do not sell, trade, or rent your Personally Identifiable Information to others. We may utilize the services of third parties and provide some of our services through contractual arrangements with affiliates, services providers, partners and other third parties, and to facilitate such arrangements we reserve the right to share with such third parties Personally Identifiable Information, provided that such third party agree to not share the information. For example, your credit card information will be shared with our credit card processor to process subscription transactions.</Typography>
            <Typography paragraph gutterBottom>Occasionally we may be required by law enforcement or judicial authorities to provide Personally Identifiable Information to the appropriate governmental authorities. We will disclose Personally Identifiable Information upon receipt of a court order, subpoena, or to cooperate with a law enforcement investigation, or if we reasonably believe that we are required to do so by law. We fully cooperate with law enforcement agencies in identifying those who use our services for illegal activities. We reserve the right to report to law enforcement agencies any activities that we in good faith believe to be unlawful.</Typography>
            <Typography paragraph gutterBottom>We may also provide Non-Personally Identifiable Information about our customers’ traffic patterns, and related site information to third party advertisers, but these statistics do not include Personally Identifiable Information.</Typography>
          </li>
          <li>
            How can you update or correct your Personally Identifiable Information?
            <br />
            <Typography paragraph gutterBottom>We believe you should have the ability to access and edit the Personally Identifiable Information that you have provided to us. You may change any of your Personally Identifiable Information in your account at any time by logging on to your account and updating your information.</Typography>
            <Typography paragraph gutterBottom>We encourage you to promptly update your Personally Identifiable Information if it changes. You may ask to have the information on your account deleted or removed; however, because we keep track of past transactions, you cannot delete information associated with past transactions on the Site. In addition, it may be impossible to completely delete your information without some residual information because of backups.</Typography>
          </li>
          <li>
            What are your choices regarding collection, use, and distribution of your information?
            <br />
            <Typography paragraph gutterBottom>We may occasionally send you direct mail (including e-mail) about products and services that we feel may be of interest to you. You will have the ability to opt out of receiving such communications from us by following the instructions in such communications.</Typography>
          </li>
          <li>
            What security precautions are in place to protect against the loss, misuse, or alteration of your information?
            <br />
            <Typography paragraph gutterBottom>Your Personally Identifiable Information is secure on the Site, consistent with current industry standards. The importance of security for all Personally Identifiable Information associated with our users is of utmost concern to us. Your Personally Identifiable Information is protected in several ways. Access by you to your Personally Identifiable Information is available through a password and unique customer ID selected by you. This password is encrypted. We recommend that you do not divulge your password to anyone. In addition, your Personally Identifiable Information resides on a secure server that only our selected personnel and contractors have access to via password. We encrypt your Personally Identifiable Information to prevent unauthorized parties from viewing such information when it is transmitted to us.</Typography>
            <Typography paragraph gutterBottom>Unfortunately, however, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your Personally Identifiable Information, you acknowledge that: (a) there are security and privacy limitations of the Internet which are beyond our control; (b) the security, integrity and privacy of any and all information and data exchanged between you and us through the Site cannot be guaranteed; and (c) any such information and data may be viewed or tampered with in transit by a third party. Accordingly, and notwithstanding anything to the contrary, while our systems utilize industry-standard security measures, we will not be liable for security or data breaches that circumvent such security measures.</Typography>
          </li>
          <li>
            Do we use “cookies”?
            <br />
            <Typography paragraph gutterBottom>When you use our Site we may store cookies on your computer/device in order to facilitate and customize your use of our Site. A cookie is a small data text file, which a website stores on your device’s hard drive that can later be retrieved to identify you to us. Our cookies store randomly assigned user identification numbers. The cookies make your use of the Site easier, make it run more smoothly and help us to maintain a secure Site. You are always free to decline our cookies if your browser permits, but some parts of our Site may not work properly in that case.</Typography>
          </li>
        </ol>

        <Typography paragraph gutterBottom>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us by sending us an email to _______________________.</Typography>

        <Typography paragraph gutterBottom>This Privacy Policy is effective as of February 26, 2020</Typography>

        <Typography variant="h3" align="center" gutterBottom>GDPR NOTICE</Typography>
        <Typography paragraph gutterBottom>This GDPR Notice provides mandatory information as required under Articles 13 and 14 of the European General Data Protection Regulation (GDPR) regarding the transparency of personal data processing. This GDPR Notice is effective as of February 26, 2020. This GDPR Notice applies only to data processing activities of data subjects residing in the European Economic Area as well as, regardless of residence, the processing of any Personal Data in the European Economic Area. In the event there is a conflict between this GDPR Notice and other parts of our Privacy Policy, this GDPR Notice shall control with respect to residents of the European Economic Area. As used in this GDPR Notice, “Personal Data” means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</Typography>
        <Typography paragraph gutterBottom>Pursuant to the GDPR, SMG may function in the capacity of a “processor” and/or “controller”.</Typography>
        <Typography paragraph gutterBottom>This GDPR Notice incorporates within it the terms outlined in the Privacy Policy above, provided, however, that in the event of a conflict between this GDPR Notice and the Privacy Policy, this GDPR Notice shall control.</Typography>

        <Typography variant="h5" gutterBottom>How is Your Personal Data Collected</Typography>
        <Typography paragraph gutterBottom>SMG collects Personal Data about users through the following sources and you hereby consent to each of the following:</Typography>

        <ul className={classes.ul}>
          <li>Direct interactions. You may give SMG your Personal Data (including identity and contact information) by registering for an SMG account or subscription.</li>
          <li>Automated technologies or interactions. As you interact with the SMG Site SMG may automatically collect technical data about your equipment, browsing actions and patterns.</li>
        </ul>

        <Typography variant="h5" gutterBottom>Purpose of Processing Your Personal Data</Typography>
        <Typography paragraph gutterBottom>SMG processes your Personal Data in order to facilitate the business services of SMG, namely providing the Services and Materials available on the SMG Site.</Typography>

        <Typography variant="h5" gutterBottom>How We Use/Process Your Personal Data</Typography>
        <Typography paragraph gutterBottom>SMG will only use your Personal Data when the law allows it. Most commonly, SMG will use your Personal Data in the following circumstances:</Typography>

        <ul className={classes.ul}>
          <li>Where SMG needs to perform its Services to you (including to provide you with our the SMG subscription services).</li>
          <li>Where it is necessary for SMG’s legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
          <li>Where SMG needs to comply with a legal obligation.</li>
          <li>Based on your consent, SMG utilizes your Personal Data to provide our services to you.</li>
        </ul>

        <Typography variant="h5" gutterBottom>Legal Basis for Processing Personal Data</Typography>
        <Typography paragraph gutterBottom>As noted above, SMG collects and processes your Personal Data for a variety of different purposes, each of which is supported by a legal basis (or more than one legal basis) for processing such Personal Data under the GDPR. The primary legal basis is your consent and SMG’s need for Personal Data in order to provide you with requested services.</Typography>

        <Typography paragraph gutterBottom>To the extent that SMG is required to process or share Personal Data to comply with laws, regulators, court orders, or other legal obligations, or pursuant to legal process, the legal basis for such processing and sharing is done pursuant to GDPR’s “compliance with legal obligations” basis for processing.</Typography>

        <Typography paragraph gutterBottom>Marketing SMG does not sell or rent your Personal Data to third parties. Likewise, except as provided in this GDPR Notice, SMG does not disclose your Personal Data to third parties for marketing purposes. SMG will get your express opt-in consent before it shares your Personal Data with any third party for marketing purposes.</Typography>

        <Typography paragraph gutterBottom>Without limiting the foregoing, you agree that SMG may utilize your Personal Data to market or provide SMG services directly to you, to provide support to you and to use the Personal Data for the purpose for which it was provided to SMG.</Typography>

        <Typography paragraph gutterBottom>To the extent that SMG sends you marketing messages or materials, you can opt out of such messages by clicking the “unsubscribe” link included in such email marketing messages or by contacting us at _______________________.</Typography>

        <Typography variant="h5" gutterBottom>Consent Withdrawal and Contact Details</Typography>
        <Typography paragraph gutterBottom>You may at any time withdraw the consent you provide for the processing of your Personal Data by contacting us at ______________________, provided that we are not required by applicable law or professional standards to retain such information.</Typography>

        <Typography variant="h5" gutterBottom>Data Security</Typography>
        <Typography paragraph gutterBottom>SMG has put in place appropriate security measures to prevent your Personal Data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, SMG limits access to your Personal Data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your Personal Data on our instructions and they are subject to a duty of confidentiality. SMG has put in place procedures to deal with any suspected Personal Data breach and will notify you and any applicable regulator of a breach where SMG is legally required to do so.</Typography>

        <Typography paragraph gutterBottom>SMG will maintain the confidentiality of your Personal Data and will require that persons authorized to process and access the Personal Data have committed themselves to maintain the confidentiality of Personal Data.</Typography>

        <Typography variant="h5" gutterBottom>Data Retention</Typography>
        <Typography paragraph gutterBottom>SMG will only retain your Personal Data for as long as reasonably necessary to fulfil the purposes SMG collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. SMG may retain your personal data for a longer period in the event of a complaint or if SMG reasonably believes there is a prospect of litigation in respect to its relationship with you.</Typography>

        <Typography paragraph gutterBottom>To determine the appropriate retention period for personal data, SMG considers the amount, nature and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which SMG collected and processed your Personal Data and whether SMG can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</Typography>

        <Typography variant="h5" gutterBottom>Your Legal Rights Under the GDPR</Typography>
        <Typography paragraph gutterBottom>Under certain circumstances, you have rights under data protection laws in relation to your Personal Data, including to:</Typography>
        <ul className={classes.ul}>
          <li>Request access to your Personal Data.</li>
          <li>Request correction of your Personal Data.</li>
          <li>Request erasure of your personal data.</li>
          <li>Object to processing of your Personal Data.</li>
          <li>Request restriction of processing your Personal Data.</li>
          <li>Request transfer of your Personal Data.</li>
          <li>Right to withdraw consent.</li>
          <li>Lodge a complaint with a supervisory authority.</li>
        </ul>

        <Typography paragraph gutterBottom>If you wish to exercise any of the rights set out above, please contact SMG at the contact information provided below.</Typography>

        <Typography variant="h5" gutterBottom>Contact Information</Typography>
        <Typography paragraph gutterBottom>If you have any questions about this GDPR Notice or our privacy practices, please contact our privacy manager:</Typography>

        STREAM MEDIA GROUP INC.
        <br />
        590 Madison Avenue
        <br />
        New York, NY 10022 US
        <br />
        <br />
        <a href="callto:917685-2631">(917) 685-2631</a>
        <br />
        <br />
        <a href="mailto:compliance@streamrg.com">compliance@streamrg.com</a>
        <br />
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
