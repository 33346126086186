import React, { useEffect, useRef, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import isEmpty from 'lodash/isEmpty';
import useMe from '../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 71px)',
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },
}));

const Subscribe = (formId) => {
  const classes = useStyles();
  const formEl = useRef(null);
  const [formLoaded, setFormLoaded] = useState(false);
  const { me } = useMe();
  const { hashid } = useParams();

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://mosaicrm.activehosted.com/f/embed.php?id=${formId.formId}`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      script.remove();
      // TODO handle correct remove AC form from DOM
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formEl.current.addEventListener('DOMSubtreeModified', () => setFormLoaded(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const referredBy = document.getElementsByName('field[8]')[0];

    if (referredBy) {
      referredBy.value = hashid;
    }

    if (!isEmpty(me) && formLoaded) {
      // document.getElementsByName('fullname')[0].value = me.realFullName || '';
      document.getElementsByName('email')[0].value = me.email || '';
    }
  }, [me, formLoaded, hashid]);

  return (
    <div className={classes.root}>
      <div ref={formEl} className={`_form_${formId.formId}`} id="activeCampaingForm" />
    </div>
  );
};

Subscribe.defaultProps = {
  formId: '1',
};

Subscribe.propTypes = {
};

export default withRouter(Subscribe);
