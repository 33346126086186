import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexGrow: '1',
  },
  logBox: {
    position: 'relative',
    maxWidth: '540px',
    width: '100%',
    padding: '20px',
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginBottom: '20px',
  },
  svgImageWrap: {
    width: '100%',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  submitBtn: {
    display: 'block',
    borderRadius: '0',
    letterSpacing: 'normal',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    height: '40px',
    padding: '10px 30px',
    textAlign: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.dark,
    },
  },
}));

const ResetPasswordComplete = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.logBox}>
        <Box
          p={{ xs: '20px', md: '50px' }}
          bgcolor="background.paper"
        >
          <Box className={classes.text}>
            Your password has been set. You may go ahead and log in now.
          </Box>
          <Box className={classes.svgImageWrap}>
            <img src="/images/icons/password_reset_complete.svg" alt="password reset complete" />
          </Box>
          <Link to="/accounts/login" className={classes.submitBtn}>Log In Now</Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPasswordComplete;
