import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import { formatDate } from '../../../../../../../../../helpers/dateHelpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginBottom: '5px',
    borderRadius: '5px',
    boxShadow: 'none',
    border: '1px solid',
    padding: '1px',
    borderColor: theme.palette.info.main,
    '&:hover': {
      borderColor: theme.palette.info.dark,
      [theme.breakpoints.up('md')]: {
        '&> button': {
          display: 'inline-flex',
        },
        '&> span': {
          display: 'none',
        },
      },
    },
    '&.active': {
      border: '2px solid',
      borderColor: theme.palette.primary.main,
      padding: '0',
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  headingBox: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '145px',
    padding: '4px 4px 4px 6px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
  },
  btn: {
    padding: '4px',
    minWidth: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  callDate: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    padding: '4px',
    whiteSpace: 'nowrap',
  },
}));

const CompanyItem = ({
  id, description, watchlistId, isItemActive, uuid, lastPublishedAt,
  handleCompanyClick, handleRemoveFromWatchlist, handleAddToWatchlist,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(
        classes.root,
        isItemActive && 'active',
      )}

      key={id}
      data-xlink-company-id={uuid}
    >
      <Box
        className={classes.headingBox}
        onClick={handleCompanyClick}
      >
        {description}
      </Box>
      {watchlistId
        ? (
          <>
            <Box component="span" className={classes.callDate}>
              {formatDate(lastPublishedAt)}
            </Box>
            <Tooltip title="Remove from Watchlist">
              <Button
                onClick={handleRemoveFromWatchlist}
                disableRipple
                className={classes.btn}
              >
                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.50143 0C3.5795 0 0.767658 5.14381 0.0854872 6.61206C-0.0284957 6.85706 -0.0284957 7.14123 0.0854872 7.38623C0.767658 8.85536 3.5795 14 9.50143 14C15.4026 14 18.2164 8.89257 18.9106 7.40332C19.0298 7.14695 19.0298 6.85305 18.9106 6.59668C18.2164 5.10743 15.4026 0 9.50143 0ZM9.50143 2.625C11.8856 2.625 13.819 4.58413 13.819 7C13.819 9.41587 11.8856 11.375 9.50143 11.375C7.11729 11.375 5.18389 9.41587 5.18389 7C5.18389 4.58413 7.11729 2.625 9.50143 2.625ZM9.50143 4.375C8.81438 4.375 8.15547 4.65156 7.66965 5.14384C7.18384 5.63613 6.91091 6.30381 6.91091 7C6.91091 7.69619 7.18384 8.36387 7.66965 8.85616C8.15547 9.34844 8.81438 9.625 9.50143 9.625C10.1885 9.625 10.8474 9.34844 11.3332 8.85616C11.819 8.36387 12.092 7.69619 12.092 7C12.092 6.30381 11.819 5.63613 11.3332 5.14384C10.8474 4.65156 10.1885 4.375 9.50143 4.375Z" fill="#1168A7" />
                </svg>
              </Button>
            </Tooltip>
          </>
        )
        : (
          <>
            <Box component="span" className={classes.callDate}>
              {formatDate(lastPublishedAt)}
            </Box>
            <Tooltip title="Add to Watchlist">
              <Button
                onClick={handleAddToWatchlist}
                disableRipple
                className={classes.btn}
              >
                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.50143 0C3.5795 0 0.767658 5.14381 0.0854872 6.61206C-0.0284957 6.85706 -0.0284957 7.14123 0.0854872 7.38623C0.767658 8.85536 3.5795 14 9.50143 14C15.4026 14 18.2164 8.89257 18.9106 7.40332C19.0298 7.14695 19.0298 6.85305 18.9106 6.59668C18.2164 5.10743 15.4026 0 9.50143 0ZM9.50143 2.625C11.8856 2.625 13.819 4.58413 13.819 7C13.819 9.41587 11.8856 11.375 9.50143 11.375C7.11729 11.375 5.18389 9.41587 5.18389 7C5.18389 4.58413 7.11729 2.625 9.50143 2.625ZM9.50143 4.375C8.81438 4.375 8.15547 4.65156 7.66965 5.14384C7.18384 5.63613 6.91091 6.30381 6.91091 7C6.91091 7.69619 7.18384 8.36387 7.66965 8.85616C8.15547 9.34844 8.81438 9.625 9.50143 9.625C10.1885 9.625 10.8474 9.34844 11.3332 8.85616C11.819 8.36387 12.092 7.69619 12.092 7C12.092 6.30381 11.819 5.63613 11.3332 5.14384C10.8474 4.65156 10.1885 4.375 9.50143 4.375Z" fill="#BFCCDC" />
                </svg>
              </Button>
            </Tooltip>
          </>
        )}
    </Box>
  );
};

CompanyItem.defaultProps = {
  watchlistId: null,
  lastPublishedAt: '',
};

CompanyItem.propTypes = {
  id: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  lastPublishedAt: PropTypes.string,
  description: PropTypes.string.isRequired,
  watchlistId: PropTypes.number,
  isItemActive: PropTypes.bool.isRequired,
  handleCompanyClick: PropTypes.func.isRequired,
  handleRemoveFromWatchlist: PropTypes.func.isRequired,
  handleAddToWatchlist: PropTypes.func.isRequired,
};

const areEqual = (prevProps, nextProps) => {
  const prevObj = {
    uuid: prevProps.uuid,
    watchlistId: prevProps.watchlistId,
    isItemActive: prevProps.isItemActive,
  };
  const nextObj = {
    uuid: nextProps.uuid,
    watchlistId: nextProps.watchlistId,
    isItemActive: nextProps.isItemActive,
  };
  return isEqual(prevObj, nextObj);
};

export default React.memo(CompanyItem, areEqual);
