import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import copy from 'copy-to-clipboard';
import { connect } from 'react-redux';
import { CREATE_SAVED_FILTER_MUTATION } from '../../queries/interview/interview';
import useMe from '../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    color: theme.palette.secondary.main,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    padding: '1px 0',
    minWidth: '10px',
    '&:hover': {
      color: theme.palette.secondary.dark,
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: theme.palette.secondary.light,
      backgroundColor: 'transparent',
    },
    '& .MuiButton-label': {
      textTransform: 'initial',
    },
  },
}));

const CopyFiltersButton = ({ filterData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { me } = useMe();

  const [createFilter] = useMutation(CREATE_SAVED_FILTER_MUTATION);

  const handleCopyFilters = () => {
    createFilter({
      variables: {
        filterData: JSON.stringify(filterData),
      },
    }).then(({ data: { createSavedFilter = {} } = {} }) => {
      if (!isEmpty(createSavedFilter.errors)) {
        enqueueSnackbar(createSavedFilter.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        const link = `${window.location.protocol}//${window.location.host}/sf/${createSavedFilter.savedFilterHashid}`;
        copy(link);
        enqueueSnackbar('Link has been copied.', { variant: 'success', autoHideDuration: 4000 });
      }
    });
  };
  return (
    <>
      {(me && me.isStaff)
      && (
      <Box mb="10px">
        <Button
          disableRipple
          onClick={handleCopyFilters}
          className={classes.button}
        >
          Copy Filters URL
        </Button>
      </Box>
      )}
    </>
  );
};

CopyFiltersButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filterData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { filterState } = state;
  return {
    filterData: filterState.filterData,
  };
};

export default connect(mapStateToProps)(CopyFiltersButton);
