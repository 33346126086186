/* eslint-disable max-len */
import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { Link, useParams } from 'react-router-dom';
import RejectionFeedbackForm from '../../components/RejectionFeedbackForm/RejectionFeedbackForm';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1260px',
    width: '100%',
    padding: '20px 20px 30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
  },
  confirmBox: {
    maxWidth: '520px',
    width: '100%',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '30px',
    color: theme.palette.text.primary,
    marginRight: '12px',
    [theme.breakpoints.up('md')]: {
      fontSize: '48px',
      lineHeight: '60px',
    },
  },
  confirmText: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '30px',
    color: theme.palette.text.primary,
  },
  img: {
    maxWidth: '292px',
    width: '100%',
  },
  textareaBlock: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    padding: '30px',
    borderRadius: '5px',
  },
  backBtn: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '40px',
    padding: '10px 20px',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
    },
    '&:active': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.main,
    },
  },
  textareaBlockTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
    color: theme.palette.text.primary,
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      fontSize: '36px',
      lineHeight: '45px',
    },
  },
  textareaBlockSubtitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '24px',
    color: theme.palette.text.primary,
    marginBottom: '10px',
  },
  textareaBlockText: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    color: theme.palette.text.primary,
    marginBottom: '20px',
  },
  divider: {
    marginBottom: '20px',
  },
  submitBtn: {
    height: '40px',
    padding: '10px 50px',
    color: theme.palette.common.white,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const RejectionOfTrialAnotherProduct = () => {
  const classes = useStyles();
  const { hashStr } = useParams();

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
        mt={{ xs: '0', md: '80px' }}
        mb={{ xs: '30px', md: '80px' }}
      >
        <Box display="flex" flexGrow="1" flexDirection="column">
          <Box className={classes.confirmBox}>
            <Box display="flex" alignItems="center" mb="20px">
              <Box className={classes.title}>
                Done
              </Box>
              <Box>
                <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.8422 19.1755C10.0384 19.9792 8.73728 19.9792 7.93561 19.1755L1.16667 12.4066C0.365 11.6049 0.365 10.3017 1.16667 9.5C1.96833 8.69833 3.27156 8.69833 4.07322 9.5L9.38889 14.8157L22.9268 1.27778C23.7284 0.476111 25.0317 0.476111 25.8333 1.27778C26.635 2.07944 26.635 3.38267 25.8333 4.18433L10.8422 19.1755Z" fill="#1168A7" />
                </svg>
              </Box>
            </Box>
            <Box className={classes.confirmText}>
              We won&apos;t contact you about free access to our library of transcripts and one-on-one calls with experts anymore.
            </Box>
          </Box>
        </Box>
        <Box mb={{ xs: '20px', md: '0' }}>
          <Box className={classes.img}>
            <img alt="join community" src="/images/join_community.svg" />
          </Box>
        </Box>
      </Box>
      <Box className={classes.textareaBlock}>
        <Box mb="20px">
          <Link
            to={`/not-interested-in-trial/${hashStr}`}
            className={classes.backBtn}
          >
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.69491 6L6.67645 1.92897C7.10785 1.48788 7.10785 0.771908 6.67645 0.330818C6.24506 -0.110273 5.54483 -0.110273 5.11343 0.330818L0.305572 5.24674C-0.101857 5.66332 -0.101857 6.33774 0.305572 6.75326L5.11343 11.6692C5.54483 12.1103 6.24506 12.1103 6.67645 11.6692C7.10785 11.2281 7.10785 10.5121 6.67645 10.071L2.69491 6Z" fill="#1168A7" />
            </svg>
            <Box ml="15px">Back</Box>
          </Link>
        </Box>
        <Box className={classes.textareaBlockTitle}>
          I Found Another Product
        </Box>
        <Box className={classes.textareaBlockText}>
          I ended up using another product so I don&apos;t need Stream anymore.
        </Box>
        <Divider className={classes.divider} />
        <Box className={classes.textareaBlockSubtitle}>
          Can you tell us why?
        </Box>
        <Box className={classes.textareaBlockText}>
          We&apos;d love to know what about the other product that made you chose it?
        </Box>
        <RejectionFeedbackForm
          placeholder="Letting us know why you picked another product will help us improve Stream in the future..."
          reason="another_product"
        />
      </Box>
    </Box>
  );
};

export default RejectionOfTrialAnotherProduct;
