/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { Link as Anhor } from 'react-scroll';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useTracking } from 'react-tracking';
import {
  CollapseInfo,
  LegalDisclaimer,
  MoreOn,
  Perspective,
  Tickers,
} from '../../components/InterviewPreviewComponents';
import { LinearProgressMosaic } from '../../components';
import { INTERVIEW_PUBLIC_QUERY } from '../../queries/interview/interview';
import Subscribe from '../Subscribe';
import { searchToObject } from '../../helpers/locationSearchToJson';
import { formatDate } from '../../helpers/dateHelpers';
import useMe from '../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    padding: '20px 20px 30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      padding: '25px 20px 30px',
    },
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  lColumn: {
    maxWidth: '745px',
    marginBottom: '15px',
    [theme.breakpoints.up('md')]: {
      border: '1px solid',
      borderColor: theme.palette.info.main,
      borderRadius: '5px',
      padding: '24px',
      marginRight: '30px',
      marginBottom: 0,
    },
  },
  rColumn: {
    width: '100%',
    maxWidth: '365px',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      width: '365px',
      minWidth: '365px',
    },
    '& > div': {
      minHeight: 'auto',
      padding: 0,
      [theme.breakpoints.up('md')]: {
        position: 'fixed',
      },
      '& > div': {
        width: '100%',
      },
    },
  },
  headline: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '30px',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '23px',
    },
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    '& .toc-link': {
      cursor: 'text !important',
      '&:hover': {
        color: '#8293A6 !important',
      },
    },
  },
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  vDivider: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.info.dark,
    paddingRight: '10px',
    marginRight: '10px',
  },
  fDivider: {
    marginTop: '5px',
    marginBottom: '15px',
  },
  callDate: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginRight: '10px',
  },
  tickerDesc: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '25px',
    color: theme.palette.text.primary,
    marginRight: '15px',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '30px',
    },
  },
  trialBanner: {
    display: 'none',
    position: 'fixed',
    bottom: '10px',
    flexDirection: 'column',
    padding: '20px !important',
    margin: '0 auto',
    width: 'calc(100% - 40px)',
    maxWidth: '365px',
    backgroundColor: theme.palette.info.light,
    borderRadius: '5px',
    textAlign: 'center',
  },
  bannerTitle: {
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom: '10px',
  },
  bannerSubtitle: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: '500',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom: '10px',
  },
  bannerBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    border: '1px solid',
    borderColor: theme.palette.success.main,
    display: 'inline-block',
    width: '60%',
    padding: '10px',
    fontSize: '14px',
    fontWeight: '500',
    height: '40px',
    lineHeight: '18px',
    borderRadius: '5px',
    textTransform: 'inherit',
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
      borderColor: theme.palette.success.main,
    },
    '&:active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.light,
    },
  },
  mostProminent: {
    backgroundColor: '#F9FBFF',
    borderLeft: '2px solid',
    borderLeftColor: theme.palette.primary.main,
    marginLeft: 0,
    padding: '2px 0 4px 15px',
    '& ul': {
      listStyleType: 'none',
      '& li:before': {
        content: '"-"',
        marginLeft: '-20px',
        marginRight: '10px',
      },
    },
  },
  transcript: {
    '& .tts': {
      cursor: 'text !important',
      '&:hover': {
        color: '#8293A6 !important',
      },
    },
  },
}));

const InterviewGuests = () => {
  const classes = useStyles();
  const { interviewHash, hashid } = useParams();
  const location = useLocation();
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });

  const { me } = useMe();

  const {
    loading,
    data: { interviewPublic: interview } = {},
    called,
  } = useQuery(INTERVIEW_PUBLIC_QUERY, {
    variables: { interviewHash, hashid },
    errorPolicy: 'all',
  });

  const handleScroll = () => {
    document.addEventListener('scroll', () => {
      const trialForm = document.getElementById('activeCampaingForm');
      const trialBanner = document.getElementById('trialBanner');
      let trialBannerShouldSticky;
      if (trialBanner && trialForm) {
        trialBannerShouldSticky = trialForm.getBoundingClientRect().top >= document.documentElement.clientHeight;
        if (trialBannerShouldSticky) {
          trialBanner.style.display = 'block';
        } else {
          trialBanner.style.display = 'none';
        }
      }
    });
  };

  useEffect(() => {
    if (isMobile) {
      handleScroll();
    }
    if (!isEmpty(interview)) {
      trackEvent({
        viewName: 'interview:interview_detail_public',
        appLabel: 'interview',
        model: 'interview',
        objectId: interview.id,
        path: location.pathname,
        getDict: JSON.stringify(searchToObject(location.search)),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interview]);

  if (loading && isEmpty(interview)) return <LinearProgressMosaic loading={loading} />;

  if (called && isEmpty(interview)) {
    return <Redirect to="/not-found" />;
  }

  if (!isEmpty(me)) {
    return <Redirect to={`/${interview.interviewId}`} />;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.grid}>
        <Box className={classes.lColumn}>
          <Fade in={!loading}>
            <Box>
              <Box mb="10px">
                {!isEmpty(interview.callDate)
                && (
                  <Box
                    component="span"
                    className={classes.callDate}
                  >
                    {formatDate(interview.callDate)}
                  </Box>
                )}
              </Box>

              {!isEmpty(interview.tickers)
              && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  {interview.tickers[0]
                  && (
                    <Box className={classes.tickerDesc}>
                      {interview.tickers[0].ticker.description}
                    </Box>
                  )}
                  {interview.tickers.map(({ ticker }) => (
                    <Tickers
                      key={ticker.id}
                      uuid={ticker.uuid}
                      tickerId={ticker.id}
                      title={ticker.title}
                      interviewId={interview.interviewId}
                      isAddedToWatchList={ticker.isAddedToWatchList}
                      company={ticker?.company?.id}
                    />
                  ))}
                </Box>
              )}

              <Box mb="10px">
                {!isEmpty(interview.perspective)
                && (
                  <Perspective>
                    {interview.perspective}
                  </Perspective>
                )}

                {(!isEmpty(interview.perspective))
                && (
                  <Box component="span" className={classes.vDivider} />
                )}

                <Box component="span" className={classes.title}>
                  {interview.relevantRole}
                </Box>
              </Box>

              <Divider className={classes.fDivider} />

              {!isEmpty(interview.headline)
              && (
                <Box mb="10px">
                  <Typography
                    variant="h3"
                    component="h3"
                    className={classes.headline}
                  >
                    {interview.headline}
                  </Typography>
                </Box>
              )}

              <Box>
                {!isEmpty(interview.summary)
                && (
                  <Box mb="8px" className={classes.subtitle}>
                    <Box mb="6px">
                      Call Summary:
                    </Box>
                    <CollapseInfo className={classes.mostProminent}>
                      {interview.summary || 'No Information'}
                    </CollapseInfo>
                  </Box>
                )}

                {!isEmpty(interview.tableOfContents)
                && (
                  <Box mb="8px" className={classes.subtitle}>
                    <Box mb="6px">
                      Table of Contents:
                    </Box>
                    <CollapseInfo className={classes.mostProminent}>
                      {interview.tableOfContents || 'No Information' }
                    </CollapseInfo>
                  </Box>
                )}
              </Box>

              {(!isEmpty(interview.headline) || !isEmpty(interview.summary) || !isEmpty(interview.tableOfContents))
              && (
                <Divider className={classes.divider} />
              )}

              <Box className={classes.transcript}>
                <Box dangerouslySetInnerHTML={{ __html: interview.content }} />
              </Box>

              {interview.tickers
              && <MoreOn tickers={interview.tickers} interviewId={interview.interviewId} />}

              {!isMobile
              && <LegalDisclaimer />}
            </Box>
          </Fade>
        </Box>
        <Box className={classes.rColumn}>
          <Subscribe formId="4" />
          <Box
            id="trialBanner"
            className={classes.trialBanner}
          >
            <Box className={classes.bannerTitle}>Sign up for a free 21-day trial</Box>
            <Box className={classes.bannerSubtitle}>*No credit card details required</Box>
            <Anhor
              className={classes.bannerBtn}
              to="activeCampaingForm"
              smooth
              offset={-81}
            >
              Start free trial
            </Anhor>
          </Box>
          {isMobile
          && <Box mt="16px"><LegalDisclaimer /></Box>}
        </Box>
      </Box>
    </Box>
  );
};

export default InterviewGuests;
