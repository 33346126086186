import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { ALL_SECTORS } from '../../../queries/interview/interview';
import { openCompanies } from '../../../actions/sidebarActions';
import { writeFilterData } from '../../../actions/filterActions';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    fontSize: '34px',
    fontWeight: '500',
    lineHeight: '42px',
    color: theme.palette.text.primary,
    paddingBottom: '24px',
  },
  gridContainer: {
    width: 'calc(100% + 20px)',
    margin: '-10px',
  },
  gridItem: {
    padding: '10px',
  },
  sectorBtn: {
    height: '120px',
    width: '100%',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    padding: '10px',
    transition: 'opacity 400ms',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '&:hover': {
      opacity: '.8',
    },
  },
  sectorSkeleton: {
    height: '120px',
    width: '100%',
    background: 'linear-gradient(180deg, rgba(24, 35, 65, 0.516) 0%, rgba(24, 35, 65, 0.86) 100%)',
    opacity: '.3',
    borderRadius: '5px',
  },
}));

const SectorsArea = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const handleOpenCompanies = () => dispatch(openCompanies());
  const handleWriteFilterData = (data) => dispatch(writeFilterData(data));

  const {
    data: {
      allSectors,
    } = {}, loading,
  } = useQuery(ALL_SECTORS, {
    variables: {
      first: 100,
      skip: 0,
    },
  });

  const handleSectorClick = (sector) => {
    handleOpenCompanies();
    handleWriteFilterData({ sectors: [sector] });
    window.scrollTo(0, 0);
  };

  const SectorsSkeleton = () => {
    const items = [];
    const skeletonItem = () => (
      <Grid item xs={12} sm={4} md={3} className={classes.gridItem}>
        <Box className={classes.sectorSkeleton} />
      </Grid>
    );
    for (let i = 0; i < 12; i += 1) {
      items.push(skeletonItem());
    }
    return (
      <>{items}</>
    );
  };

  return (
    <>
      <Box className={classes.sectionTitle}>Sectors</Box>
      <Box>
        <Grid container className={classes.gridContainer}>
          {!loading ? (allSectors?.results?.map((item) => (
            <Grid item xs={6} sm={4} md={3} className={classes.gridItem}>
              <Button
                onClick={() => handleSectorClick(item)}
                disableRipple
                className={classes.sectorBtn}
                style={{ backgroundImage: `linear-gradient(180deg, rgba(24, 35, 65, 0.516) 0%, rgba(24, 35, 65, 0.86) 100%), url(https://mosaic-stream.s3.amazonaws.com/images/${item.slug}@2x.png)` }}
                data-track="discovery/filter-sector"
              >
                {item.title}
              </Button>
            </Grid>
          ))) : (<SectorsSkeleton />)}
        </Grid>
      </Box>
    </>
  );
};

export default SectorsArea;
