import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import { SAVED_FILTER_QUERY } from '../../queries/interview/interview';
import { writeFilterData } from '../../actions/filterActions';

const SavedFilters = ({ handleWriteFilterData }) => {
  const { hashid } = useParams();
  const { data: { savedFilter } = {} } = useQuery(SAVED_FILTER_QUERY, {
    variables: { hashid },
  });
  const history = useHistory();

  useEffect(() => {
    if (savedFilter) {
      const savedFilterData = JSON.parse(savedFilter.filterData);
      handleWriteFilterData({ ...savedFilterData });
      history.push('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedFilter]);
  return null;
};

export default connect(
  null,
  {
    handleWriteFilterData: writeFilterData,
  },
)(SavedFilters);
