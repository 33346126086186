import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 171px)',
    backgroundColor: theme.palette.background.default,
    padding: '20px',
  },
}));

const CreateAccount = () => {
  const classes = useStyles();
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://mosaicrm.activehosted.com/f/embed.php?id=1';
    script.async = true;

    document.body.appendChild(script);
  }, []);
  return (
    <div className={classes.root}>
      <div className="_form_1" id="activeCampaingForm" />
    </div>
  );
};

CreateAccount.propTypes = {
};

export default withRouter(CreateAccount);
