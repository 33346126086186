import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ReactComponent as LikeIcon } from '../../assets/icons/likeIcon.svg';
import { ReactComponent as DislikeIcon } from '../../assets/icons/dislikeIcon.svg';

import {
  INTERVIEW_QUERY,
  TRANSCRIPT_RATING_MUTATION,
} from '../../queries/interview/interview';

const useStyles = makeStyles((theme) => ({
  ratingBox: {
    width: '100%',
  },
  ratingChild: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    paddingBottom: '10px',
  },
  container: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    padding: '10px',
  },
  ratingTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '23px',
    },
  },
  ratingSubtitle: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  likeBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '10px',
    [theme.breakpoints.up('md')]: {
      marginRight: '16px',
    },
    '&:hover svg path': {
      fill: theme.palette.text.secondary,
    },
  },
  dislikeBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '5px',
    [theme.breakpoints.up('md')]: {
      marginRight: '10px',
    },
    '&:hover svg path': {
      fill: theme.palette.text.secondary,
    },
  },
  marked: {
    cursor: 'default',
    '& svg path': {
      fill: theme.palette.primary.main,
    },
    '&:hover svg path': {
      fill: theme.palette.primary.main,
    },
  },
  unmarked: {
    '& svg path': {
      fill: theme.palette.info.main,
    },
    '&:hover svg path': {
      fill: theme.palette.info.dark,
    },
  },
}));

const TranscriptRatingBanner = ({
  interviewId, interviewGrade, isAutoDisplayed,
}) => {
  const classes = useStyles();
  const [sendInterviewGradeMutation] = useMutation(TRANSCRIPT_RATING_MUTATION, {
    update: (cache, { data: { setInterviewGrade } }) => {
      const { interview } = cache.readQuery({
        query: INTERVIEW_QUERY,
        variables: { interviewId, autoDisplayed: isAutoDisplayed },
      });

      cache.writeQuery({
        query: INTERVIEW_QUERY,
        variables: { interviewId },
        data: {
          interview: {
            ...interview,
            grade: {
              value: setInterviewGrade?.grade?.value,
            },
          },
        },
      });
    },
  });

  const handleTranscriptLiked = () => {
    sendInterviewGradeMutation({
      variables: {
        interviewId,
        grade: 'like',
      },
    });
  };

  const handleTranscriptDisliked = () => {
    sendInterviewGradeMutation({
      variables: {
        interviewId,
        grade: 'dislike',
      },
    });
  };

  return (
    <Box
      mb="10px"
      id="transcriptRating"
      className={classes.ratingBox}
    >
      <Box className={classes.ratingChild}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.container}
        >
          <Box mr="10px">
            <Box className={classes.ratingTitle}>
              Was this transcript helpful?
            </Box>
            <Box className={classes.ratingSubtitle}>
              Your feedback will help us improve content
            </Box>
          </Box>
          <Box display="flex">
            <Box
              className={clsx(
                classes.likeBtn,
                interviewGrade === 'LIKE' && classes.marked,
                interviewGrade === 'DISLIKE' && classes.unmarked,
              )}
              onClick={handleTranscriptLiked}
              data-track="positiveFeedback"
            >
              <LikeIcon />
            </Box>
            <Box
              className={clsx(
                classes.dislikeBtn,
                interviewGrade === 'LIKE' && classes.unmarked,
                interviewGrade === 'DISLIKE' && classes.marked,
              )}
              onClick={handleTranscriptDisliked}
              data-track="negativeFeedback"
            >
              <DislikeIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

TranscriptRatingBanner.defaultProps = {
  interviewGrade: null,
  isAutoDisplayed: false,
};

TranscriptRatingBanner.propTypes = {
  interviewId: PropTypes.string.isRequired,
  interviewGrade: PropTypes.string,
  isAutoDisplayed: PropTypes.bool,
};

export default TranscriptRatingBanner;
