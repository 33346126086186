import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useParams } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
// eslint-disable-next-line import/no-cycle
import InterviewPreview from '../Interview/components/InterviewPreview/InterviewPreview';

const AUDIO_PLAYER_ID = 'audioPlayerFullScreen';

const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: theme.palette.background.default,
  },
  container: {
    maxWidth: '940px',
    width: '100%',
    padding: '20px 20px 30px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  wrapper: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const InterviewFullscreen = () => {
  const classes = useStyles();
  const { interviewId } = useParams();

  function handlePlayerWidth() {
    const player = document.getElementById(AUDIO_PLAYER_ID);
    if (!player) return;

    const { width } = player.getBoundingClientRect();
    player.firstChild.style.width = `${width}px`;
  }

  const handleScroll = () => {
    const headerHeight = 0;
    document.addEventListener('scroll', () => {
      const player = document.getElementById(AUDIO_PLAYER_ID);
      if (player) {
        if (player.getBoundingClientRect().top <= headerHeight) {
          player.firstChild.style.position = 'fixed';
          player.firstChild.style.top = '0px';
          handlePlayerWidth();
        } else {
          player.firstChild.style.position = 'static';
        }
      }
    });
  };

  useEffect(() => {
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box className={classes.body}>
        <Box className={classes.container}>
          <Box className={classes.wrapper}>
            <InterviewPreview
              interviewId={interviewId}
              playerId={AUDIO_PLAYER_ID}
              setSelected={() => {}}
              isFullScreen
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InterviewFullscreen;
