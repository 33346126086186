import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import EditIcon from '@material-ui/icons/Edit';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'apollo-link-sentry/lib/utils';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useLocation, useRouteMatch } from 'react-router';
import {
  ALL_READING_LISTS_QUERY, DELETE_READING_LIST_MUTATION,
  INTERVIEW_QUERY,
  UPDATE_READING_LIST_MUTATION,
} from '../../../../../../../../../../queries/interview/interview';
import { ReactComponent as CancelIcon } from '../../../../../../../../../../assets/icons/cancelIcon.svg';
import { ReactComponent as SaveIcon } from '../../../../../../../../../../assets/icons/saveIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../../../../assets/icons/deleteIcon.svg';
import { writeFilterData, cleanFilterData } from '../../../../../../../../../../actions/filterActions';
import useReadingListTitle from '../../../../../../../../../../hooks/useReadingListTitle';
import useAllIntervies from '../../../../../../../../../../hooks/useAllIntervies';

const ChangeTitleField = withStyles((theme) => ({
  root: {
    width: '100%',
    padding: '4px 5px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      height: '26px',
      padding: '0px 5px',
      '& input': {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '18px',
        color: theme.palette.text.primary,
        padding: '3px 0px',
      },
      '& fieldset': {
        borderColor: theme.palette.info.main,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.info.dark,
          },
        },
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '5px',
    '& .MuiPaper-root': {
      borderRadius: '5px',
      boxShadow: 'none',
      border: '1px solid',
      padding: '1px',
      borderColor: theme.palette.info.main,
      '& .MuiButtonBase-root': {
        padding: '0',
        minHeight: '26px',
        '& .MuiAccordionSummary-content': {
          alignItems: 'center',
          margin: '0',
          overflow: 'hidden',
          minHeight: '26px',
        },
      },
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-expanded': {
        borderColor: theme.palette.primary.main,
        '& span svg': {
          display: 'block',
        },
      },
      '&.active': {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        padding: '0',
      },
      '&.deleting': {
        borderColor: theme.palette.error.main,
        '&:hover': {
          borderColor: theme.palette.error.main,
        },
      },
    },
    '&:hover span svg': {
      display: 'block',
    },
    '&:hover .drag-this-item': {
      display: 'block',
    },
    '&:hover .change-padding': {
      padding: '4px 6px 4px 0px',
    },
    '& .MuiAccordionSummary-root.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  headingBox: {
    flexGrow: '1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&.confirmation': {
      padding: '4px 0',
    },
  },
  heading: {
    width: '100%',
    padding: '4px 6px 4px 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  headingConfirmation: {
    width: '100%',
    display: 'flex',
    padding: '4px 6px 4px 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
  },
  headingConfirmationName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginLeft: '4px',
  },
  badge: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',
    padding: '2px 10px',
    borderRadius: '9px',
    marginRight: '8px',
  },
  editBtn: {
    width: '26px',
    minWidth: '26px',
  },
  dragBtn: {
    display: 'none',
    width: '26px',
    minWidth: '26px',
  },
  edit: {
    display: 'block',
    width: '18px',
    height: '26px',
    cursor: 'pointer',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drag: {
    display: 'block',
    width: '18px',
    height: '26px',
    cursor: 'move',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  notification: {
    '& svg': {
      width: '18px',
      height: '26px',
      marginRight: '10px',
      marginLeft: '2px',
    },
  },
  details: {
    flexDirection: 'column',
    alignItems: 'start',
    margin: '0 10px',
    padding: '10px 0 0',
    borderTop: '1px solid',
    borderTopColor: theme.palette.info.main,
  },
  secondaryHeading: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    whiteSpace: 'wrap',
    marginBottom: '10px',
  },
  checkbox: {
    margin: '0 0 10px',
    '& .MuiTypography-root': {
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '16px',
      color: theme.palette.text.secondary,
    },
    '& .MuiButtonBase-root': {
      minHeight: '18px!important',
      padding: '0 5px!important',
      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
        '& path': {
          color: theme.palette.info.main,
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        '& .MuiSvgIcon-root path': {
          color: theme.palette.info.dark,
        },
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root path': {
          color: theme.palette.primary.main,
        },
        '&:hover .MuiSvgIcon-root path': {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  actions: {
    padding: '0 10px 10px',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    cursor: 'pointer!important',
    minWidth: '30px',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: 'none',
    textTransform: 'capitalize',
    '& .MuiButton-startIcon': {
      marginLeft: '0',
      marginRight: '5px',
    },
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      '& svg path': {
        fill: theme.palette.text.secondary,
      },
    },
    '&:active': {
      color: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
      '& svg path': {
        fill: theme.palette.info.main,
      },
    },
  },
  deleteBtn: {
    cursor: 'pointer!important',
    minWidth: '30px',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.error.main,
    backgroundColor: theme.palette.common.white,
    border: 'none',
    textTransform: 'capitalize',
    '& svg path': {
      fill: theme.palette.error.main,
    },
    '& .MuiButton-startIcon': {
      marginLeft: '0',
      marginRight: '5px',
    },
    '&:hover': {
      color: theme.palette.error.dark,
      backgroundColor: theme.palette.common.white,
      '& svg path': {
        fill: theme.palette.error.dark,
      },
    },
    '&:active': {
      color: theme.palette.error.light,
      backgroundColor: theme.palette.common.white,
      '& svg path': {
        fill: theme.palette.error.light,
      },
    },
  },
  saveBtn: {
    cursor: 'pointer!important',
    minWidth: '30px',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    border: 'none',
    textTransform: 'capitalize',
    '& .MuiButton-startIcon': {
      marginLeft: '0',
      marginRight: '5px',
    },
    '&:hover': {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.common.white,
      '& svg path': {
        fill: theme.palette.primary.dark,
      },
    },
    '&:active': {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.common.white,
      '& svg path': {
        fill: theme.palette.primary.light,
      },
    },
  },
}));

const ReadingListItem = ({
  itemName, itemId, onFilterBy, unreadCount, filterData, handleCleanFilterData,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [value, setValue] = useState(itemName);
  const { enqueueSnackbar } = useSnackbar();

  const readingListTitle = useReadingListTitle();
  const matchInterview = useRouteMatch({ path: '/:interviewId', exact: true });
  const { refetch } = useAllIntervies();

  const [updateReadingList, { error: updateErrors }] = useMutation(UPDATE_READING_LIST_MUTATION);
  const [deleteReadingList, { error: deleteErrors }] = useMutation(DELETE_READING_LIST_MUTATION);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleConfirmation = () => {
    setConfirmation((prev) => !prev);
  };

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  const handleCancel = () => {
    setExpanded(false);
    setConfirmation(false);
  };

  const handleClearFilter = () => {
    handleCleanFilterData();
  };

  const interviewId = matchInterview ? window.location.pathname.replace('/', '') : null;
  const location = useLocation();
  const isAutoDisplayed = () => {
    if (location.state) {
      return location.state.auto_displayed || false;
    }
    return false;
  };

  const handleDelete = () => {
    handleClearFilter();
    deleteReadingList({
      variables: {
        readingListId: itemId,
      },
      refetchQueries: [
        { query: ALL_READING_LISTS_QUERY },
        {
          query: INTERVIEW_QUERY,
          variables: {
            interviewId, autoDisplayed: isAutoDisplayed(),
          },
        },
      ],
      update: ((cache) => {
        // refresh interivews
        refetch();
        // refresh reading lists
        const { allReadingList } = cache.readQuery({ query: ALL_READING_LISTS_QUERY });
        const results = allReadingList.results.filter(
          (el) => el.id !== itemId,
        );

        cache.writeQuery({
          query: ALL_READING_LISTS_QUERY,
          data: {
            allReadingList: {
              ...allReadingList,
              totalCount: allReadingList.totalCount - 1,
              results,
            },
          },
        });
        enqueueSnackbar(`${readingListTitle} has been deleted.`,
          { variant: 'success', autoHideDuration: 4000 });
      }),
    }).catch((res) => {
      const messages = res.graphQLErrors.map((el) => el.message).join(', ');
      enqueueSnackbar(messages, {
        variant: 'error',
        autoHideDuration: 4000,
      });
    });
  };

  const handleSave = () => {
    updateReadingList({
      variables: {
        readingListId: itemId,
        title: value,
      },
      update: ((cache, { data }) => {
        const { allReadingList } = cache.readQuery({ query: ALL_READING_LISTS_QUERY });
        const results = allReadingList.results.map((el) => {
          if (el.id === data.updateReadingList.readingList.id) {
            return { ...el, title: data.updateReadingList.title };
          }
          return el;
        });

        cache.writeQuery({
          query: ALL_READING_LISTS_QUERY,
          data: {
            allReadingList: {
              ...allReadingList,
              results,
            },
          },
        });
        enqueueSnackbar(`${readingListTitle} has been updated.`,
          { variant: 'success', autoHideDuration: 4000 });
        handleCancel();
      }),
    });
  };

  const handleItemClick = (event) => {
    event.stopPropagation();
    onFilterBy();
  };

  const handleStopExpand = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (updateErrors) {
      enqueueSnackbar('Save failed.', {
        variant: 'error',
        autoHideDuration: 4000,
      });
    }
    if (deleteErrors) {
      enqueueSnackbar('Deletion failed.', {
        variant: 'error',
        autoHideDuration: 4000,
      });
    }
  }, [updateErrors, deleteErrors, enqueueSnackbar]);

  const isSelected = !isEmpty(filterData.readingLists)
        && filterData.readingLists[0].title === itemName;

  const confirmationSummary = () => {
    if (confirmation) {
      return (
        <Box
          className={clsx(classes.headingBox, 'confirmation')}
          onClick={handleStopExpand}
        >
          <Box className={classes.headingConfirmation}>
            <Box>Delete</Box>
            <Box className={classes.headingConfirmationName}>{itemName}</Box>
            <Box>?</Box>
          </Box>
        </Box>
      );
    }
    return (
      <ChangeTitleField
        value={value}
        variant="outlined"
        autoFocus
        onChange={handleValueChange}
        onClick={handleStopExpand}
      />
    );
  };

  const confirmationActions = () => {
    if (confirmation) {
      return (
        <Button
          size="small"
          color="inherit"
          disableRipple
          onClick={handleDelete}
          className={classes.deleteBtn}
          startIcon={<DeleteIcon />}
        >
          Confirm Deletion
        </Button>
      );
    }
    return (
      <>
        <Button
          size="small"
          color="inherit"
          disableRipple
          className={classes.saveBtn}
          onClick={handleSave}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
        <Button
          size="small"
          color="inherit"
          disableRipple
          onClick={handleConfirmation}
          className={classes.deleteBtn}
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </>
    );
  };

  return (
    <Box className={classes.root}>
      <Accordion
        expanded={expanded}
        onChange={handleChange('panel1')}
        className={clsx(isSelected && 'active', confirmation && 'deleting')}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {expanded
            ? confirmationSummary() : (
              <>
                <Box
                  component="span"
                  className={clsx(classes.dragBtn, 'drag-this-item')}
                  onClick={handleStopExpand}
                >
                  <DragIndicatorIcon
                    color="primary"
                    className={classes.drag}
                  />
                </Box>
                <Box
                  className={classes.headingBox}
                  onClick={handleItemClick}
                >
                  <Box className={clsx(classes.heading, 'change-padding')}>
                    {itemName}
                  </Box>
                </Box>
                <Box
                  component="span"
                  ml={{ xs: '5px', md: '0' }}
                  className={classes.editBtn}
                >
                  <EditIcon
                    color="primary"
                    className={classes.edit}
                  />
                </Box>
                {unreadCount > 0
                && (
                  <Box
                    component="span"
                    className={classes.badge}
                    onClick={handleItemClick}
                  >
                    {unreadCount}
                  </Box>
                )}
              </>
            )}
        </AccordionSummary>
        <AccordionActions className={classes.actions}>
          <Button
            size="small"
            color="inherit"
            disableRipple
            onClick={handleCancel}
            className={classes.cancelBtn}
            startIcon={<CancelIcon />}
          >
            Cancel
          </Button>
          {confirmationActions()}
        </AccordionActions>
      </Accordion>
    </Box>
  );
};

ReadingListItem.defaultProps = {
  unreadCount: 0,
};

ReadingListItem.propTypes = {
  itemName: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  unreadCount: PropTypes.number,
  onFilterBy: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filterData: PropTypes.object.isRequired,
  handleCleanFilterData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { filterState } = state;
  return {
    filterData: filterState.filterData,
  };
};

const areEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export default React.memo(
  connect(
    mapStateToProps,
    {
      handleWriteFilterData: writeFilterData,
      handleCleanFilterData: cleanFilterData,
    },
  )(ReadingListItem),
  areEqual,
);
