import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Link, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { makeStyles } from '@material-ui/styles';
import { formatDate } from '../../helpers/dateHelpers';
import useMe from '../../hooks/useMe';
import { searchToObject } from '../../helpers/locationSearchToJson';

const useStyles = makeStyles((theme) => ({
  more: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.info.main,
    paddingBottom: '15px',
    marginBottom: '20px',
  },
  moreon: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '23px',
    color: theme.palette.text.primary,
    paddingBottom: '15px',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.info.dark,
  },
  link: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '23px',
    color: theme.palette.text.primary,
    marginBottom: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const MoreOn = ({ tickers, interviewId }) => {
  const classes = useStyles();
  const { me } = useMe();
  const location = useLocation();
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent({
      viewName: 'interview:more_on_link',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
      extraData: JSON.stringify({ interviewId }),
    });
  };

  return (
    <Box mt="65px" id="moreOn">
      {tickers.map((ticker) => {
        const tickerInterviews = ticker.ticker.interviews.results.filter(
          (i) => i.interviewId !== interviewId,
        );

        return (
          tickerInterviews.map((interview, index) => (
            <Box key={interview.id}>
              {index === 0 && (
                <Box className={classes.more}>
                  <Box component="span" className={classes.moreon}>
                    More on
                    {' '}
                    {ticker.ticker.title}
                  </Box>
                </Box>
              )}
              <Box
                key={interview.id}
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
                alignItems="start"
                mb="20px"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  mr="20px"
                >
                  <Box>
                    <Link
                      className={classes.link}
                      to={`/${interview.interviewId}`}
                      onClick={handleClick}
                    >
                      {me.role === 'EXPERT'
                        ? (
                          interview.headline
                        )
                        : (
                          interview.relevantRole
                        )}
                    </Link>
                  </Box>
                  <Box
                    fontSize="14px"
                    fontWeight="bold"
                    lineHeight="18px"
                    color="text.primary"
                  >
                    {interview.perspective}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  mt="4px"
                >
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="3" width="16" height="4" stroke="#BFCCDC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.66732 1V3" stroke="#BFCCDC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.3333 1V3" stroke="#BFCCDC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M17 7H1V17H17V7Z" stroke="#BFCCDC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <Box
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="18px"
                    color="text.secondary"
                    whiteSpace="nowrap"
                    ml="10px"
                  >
                    {formatDate(interview.publishedAt)}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))
        );
      })}
    </Box>
  );
};

MoreOn.propTypes = {
  tickers: PropTypes.arrayOf(PropTypes.object).isRequired,
  interviewId: PropTypes.string.isRequired,
};

export default MoreOn;
