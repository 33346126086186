import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory, useRouteMatch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { COMPLIANCE_ROLES } from '../../config';
import {
  ADD_INDUSTRY_TO_FOLLOWING_MUTATION,
  ADD_SECTOR_TO_FOLLOWING_MUTATION,
  ALL_FOLLOWING_INDUSTRIES_QUERY,
  ALL_FOLLOWING_SECTORS_QUERY,
} from '../../queries/interview/following';
import { INTERVIEW_QUERY } from '../../queries/interview/interview';
import { writeFilterData } from '../../actions/filterActions';
import useMe from '../../hooks/useMe';

const userStyles = makeStyles((theme) => ({
  root: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    padding: '0 10px',
    borderLeft: '1px solid',
    borderLeftColor: theme.palette.info.dark,
    marginBottom: '10px',
    cursor: 'pointer',
  },
  item: {
    fontWeight: 'bold',
    lineHeight: '18px',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    '&:active': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const IndustryItem = ({
  filterKey, interviewId, isAddedToWatchList, itemId, itemUUID, itemTitle,
  filterMenuItemText, handleWriteFilterData,
}) => {
  const classes = userStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const matchInterview = useRouteMatch({ path: '/:interviewId', exact: true });
  const matchHome = useRouteMatch({ path: '/', exact: true });

  const isInterviewPage = !isEmpty(matchInterview) || !isEmpty(matchHome);

  const { me } = useMe();

  const [addIndustry] = useMutation(ADD_INDUSTRY_TO_FOLLOWING_MUTATION, {
    refetchQueries: [
      { query: ALL_FOLLOWING_INDUSTRIES_QUERY },
      { query: INTERVIEW_QUERY, variables: { interviewId } },
    ],
  });

  const [addSector] = useMutation(ADD_SECTOR_TO_FOLLOWING_MUTATION, {
    refetchQueries: [
      { query: ALL_FOLLOWING_SECTORS_QUERY },
      { query: INTERVIEW_QUERY, variables: { interviewId } },
    ],
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = (value) => {
    if (!isInterviewPage) {
      history.push('/');
    }
    handleWriteFilterData({ [filterKey]: value });
    handleClose();
  };

  const handleAddIndustry = () => {
    addIndustry({
      variables: { itemId },
    }).then(({ data: { addIndustryToFollowing = {} } = {} }) => {
      if (!isEmpty(addIndustryToFollowing.errors)) {
        enqueueSnackbar(addIndustryToFollowing.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        enqueueSnackbar('Added to Watchlist.',
          { variant: 'success', autoHideDuration: 4000 });
      }
    });
    handleClose();
  };

  const handleAddSector = () => {
    addSector({
      variables: { itemId },
    }).then(({ data: { addSectorToFollowing = {} } = {} }) => {
      if (!isEmpty(addSectorToFollowing.errors)) {
        enqueueSnackbar(addSectorToFollowing.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        enqueueSnackbar('Added to Watchlist.',
          { variant: 'success', autoHideDuration: 4000 });
      }
    });
    handleClose();
  };

  const renderAddToFollowingBtn = () => {
    if (isEmpty(me) || COMPLIANCE_ROLES.includes(me.role) || isAddedToWatchList) return null;

    let handler;
    if (filterKey === 'industries') {
      handler = handleAddIndustry;
    } else if (filterKey === 'sectors') {
      handler = handleAddSector;
    }

    return (
      <MenuItem
        onClick={handler}
        disableRipple
        className={classes.item}
      >
        Add to Watchlist
      </MenuItem>
    );
  };

  return (
    <>
      <Box
        component="span"
        className={classes.root}
        onClick={handleClick}
      >
        {itemTitle}
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {renderAddToFollowingBtn()}
        <MenuItem
          disableRipple
          onClick={() => handleFilter([{ id: itemId, uuid: itemUUID, title: itemTitle }])}
          className={classes.item}
        >
          {filterMenuItemText}
        </MenuItem>
      </Menu>
    </>
  );
};

IndustryItem.defaultProps = {
  isAddedToWatchList: false,
};

IndustryItem.propTypes = {
  filterKey: PropTypes.string.isRequired,
  itemUUID: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  itemTitle: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  filterMenuItemText: PropTypes.string.isRequired,
  isAddedToWatchList: PropTypes.bool,
  handleWriteFilterData: PropTypes.func.isRequired,
};

export default connect(
  null,
  { handleWriteFilterData: writeFilterData },
)(IndustryItem);
