import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router';

import Intercom from 'react-intercom';
import LogRocket from 'logrocket';
import { RouteWithLayout } from './components';
import {
  Main as MainLayout,
  MainWhite as MainWhiteLayout,
  MainNoHeader as MainNoHeaderLayout,
  MainSidebar as MainSidebarLayout,
} from './layouts';
import { formatDate } from './helpers/dateHelpers';
import {
  CreateAccount as CreateAccountView,
  FirstLogin as FirstLoginView,
  Interivew as InterivewView,
  InterviewFullscreen as InterviewFullscreenView,
  InterviewPublic as InterviewPublicView,
  LogIn as LogInView,
  NotFound as NotFoundView,
  PrivacyPolicy as PrivacyPolicyView,
  Profile as ProfileView,
  RejectionOfTrial as RejectionOfTrialView,
  RejectionOfTrialAnotherProduct as RejectionOfTrialAnotherProductView,
  RejectionOfTrialDone as RejectionOfTrialDoneView,
  RejectionOfTrialReasonsChanged as RejectionOfTrialReasonsChangedView,
  RejectionOfTrialSomethingElse as RejectionOfTrialSomethingElseView,
  RejectionOfTrialTechnicalProblems as RejectionOfTrialTechnicalProblemsView,
  ResetPassword as ResetPasswordView,
  ResetPasswordComplete as SetResetPasswordComplete,
  SavedFilters as SavedFiltersView,
  SetPassword as SetPasswordView,
  SidebarTabsInitial as SidebarTabsInitialView,
  StartTrialLater as StartTrialLaterView,
  Subscribe as SubscribeView,
  TermsAndConditions as TermsAndConditionsView,
  InterviewGuests as InterviewGuestsView,
  DiscoveryPage as DiscoveryPageView,
  InterviewRoute as InterviewRouteView,
} from './views';
import useMe from './hooks/useMe';
import useReadingListTitle from './hooks/useReadingListTitle';

if (process.env.REACT_APP_TARGET === 'prod') {
  LogRocket.init('orsrdp/stream');
} else if (process.env.REACT_APP_TARGET === 'dev') {
  LogRocket.init('orsrdp/stream-dev');
} else if (process.env.REACT_APP_TARGET === 'hellcat') {
  LogRocket.init('orsrdp/stream-hellcat');
}

const Routes = () => {
  const { me } = useMe();

  const resolvePaidStatus = (isPaid, isBonus) => {
    if (isPaid === undefined && isPaid === undefined) return '';
    return isPaid || isBonus ? 'Paid' : 'Free';
  };

  if (process.env.REACT_APP_TARGET === 'prod') {
    LogRocket.identify(me ? me.id : 'None', {
      name: me.realFullName || '',
      email: me.email || '',
      role: me.roleDisplay || '',
      subscriptionType: resolvePaidStatus(me.isPaid, me.isBonus),
      isConsentToReceiveInfo: me.isConsentToReceiveInfo || '',
      isStaff: me.isStaff !== undefined ? me.isStaff : '',
      isInternal: me.isInternal !== undefined ? me.isInternal : '',
    });
  }

  // push data to heap
  useEffect(() => {
    window.dataLayer.push({
      event: 'userUpdate',
      uuid: me.uuid,
      user_is_trial: me.isTrial !== undefined ? me.isTrial : false,
      user_is_paid: me.isPaid !== undefined ? me.isPaid : false,
      user_is_bonus: me.isBonus !== undefined ? me.isBonus : false,
      user_is_staff: me.isStaff !== undefined ? me.isStaff : false,
      user_is_internal: me.isInternal !== undefined ? me.isInternal : false,
      user_role: me.role ? me.role.toLowerCase() : '',
      trial_start_date: me.trialStartDate || '',
      trial_expire_date: me.trialExpireDate || '',
      become_paid_at: me.becomePaid || '',
      watchlist_count: (me.sectorWatchlistCount
                      + me.companyWatchlistCount
                      + me.industryWatchlistCount
                      + me.keywordWatchlistCount) || 0,
      account_members: me.accountMembersCount || 0,
    });
  }, [me]);

  // user data for intercom
  const intercomUser = {
    name: me.realFullName || '',
    first_name: me.firstName || '',
    last_name: me.lastName || '',
    email: me.email || '',
    created_at: me.createdAt ? formatDate(me.createdAt) : '',
    uuid: me.uuid,
    user_is_trial: me.isTrial !== undefined ? me.isTrial : false,
    user_is_paid: me.isPaid !== undefined ? me.isPaid : false,
    user_is_bonus: me.isBonus !== undefined ? me.isBonus : false,
    user_is_staff: me.isStaff !== undefined ? me.isStaff : false,
    user_is_internal: me.isInternal !== undefined ? me.isInternal : false,
    user_role: me.role ? me.role.toLowerCase() : '',
    trial_start_date: me.trialStartDate || '',
    trial_expire_date: me.trialExpireDate || '',
    become_paid_at: me.becomePaid || '',
    watchlist_count: (me.sectorWatchlistCount
      + me.companyWatchlistCount
      + me.industryWatchlistCount
      + me.keywordWatchlistCount) || 0,
    account_members: me.accountMembersCount || 0,
  };

  const readingListTitle = useReadingListTitle();

  window.analytics.identify(me.uuid, {
    name: me.realFullName || '',
    email: me.email || '',
    XP_reading_list_title: readingListTitle,
  });

  return (
    <>
      {
          me.id
            ? (
              <Intercom appID="pkeyx02n" {...intercomUser} />
            )
            : (
              <Intercom appID="pkeyx02n" />
            )
      }
      <Switch>

        {/* public */}
        <RouteWithLayout
          component={CreateAccountView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path={['/start', '/accounts/create-account']}
        />
        <RouteWithLayout
          component={SubscribeView}
          exact
          loginRequired={false}
          layout={MainSidebarLayout}
          path="/accounts/subscribe"
        />
        <RouteWithLayout
          component={LogInView}
          exact
          loginRequired={false}
          layout={MainNoHeaderLayout}
          path="/accounts/login"
        />
        <RouteWithLayout
          component={FirstLoginView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/accounts/first-login/:hashStr"
        />
        <RouteWithLayout
          component={ResetPasswordView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/accounts/password-reset"
        />
        <RouteWithLayout
          component={SetPasswordView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/accounts/reset/:uidb/:token"
        />
        <RouteWithLayout
          component={SetResetPasswordComplete}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/accounts/password-reset-complete"
        />
        <RouteWithLayout
          component={InterviewPublicView}
          exact
          loginRequired={false}
          layout={MainWhiteLayout}
          path="/interview/:interviewHash"
        />
        <RouteWithLayout
          component={InterviewPublicView}
          exact
          loginRequired={false}
          layout={MainWhiteLayout}
          path="/i/:hashid"
        />
        <RouteWithLayout
          component={InterviewGuestsView}
          exact
          loginRequired={false}
          layout={MainWhiteLayout}
          path="/f/:hashid"
        />
        <RouteWithLayout
          component={TermsAndConditionsView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/terms-and-conditions"
        />
        <RouteWithLayout
          component={PrivacyPolicyView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/privacy-policy"
        />

        {/* trial reminder routes */}
        <RouteWithLayout
          component={StartTrialLaterView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/start-trial-later/:hashStr"
        />
        <RouteWithLayout
          component={RejectionOfTrialView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/not-interested-in-trial/:hashStr"
        />
        <RouteWithLayout
          component={RejectionOfTrialAnotherProductView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/not-interested-in-trial-another-product/:hashStr"
        />
        <RouteWithLayout
          component={RejectionOfTrialReasonsChangedView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/not-interested-in-trial-reasons-changed/:hashStr"
        />
        <RouteWithLayout
          component={RejectionOfTrialSomethingElseView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/not-interested-in-trial-something-else/:hashStr"
        />
        <RouteWithLayout
          component={RejectionOfTrialTechnicalProblemsView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/not-interested-in-trial-technical-problems/:hashStr"
        />
        <RouteWithLayout
          component={RejectionOfTrialDoneView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/not-interested-in-trial-done/:hashStr"
        />
        {/* end trial reminder routes */}

        <RouteWithLayout
          component={NotFoundView}
          exact
          loginRequired={false}
          layout={MainLayout}
          path="/not-found"
        />
        <RouteWithLayout
          component={DiscoveryPageView}
          exact
          layout={MainSidebarLayout}
          path="/discovery"
        />

        {/* private */}
        <RouteWithLayout
          component={SavedFiltersView}
          exact
          layout={MainSidebarLayout}
          path="/sf/:hashid"
        />
        <RouteWithLayout
          component={SidebarTabsInitialView}
          exact
          layout={MainSidebarLayout}
          path="/tab/:tabname"
        />
        <RouteWithLayout
          component={ProfileView}
          exact
          layout={MainSidebarLayout}
          path="/accounts/profile"
        />
        <RouteWithLayout
          component={InterviewFullscreenView}
          exact
          layout={MainSidebarLayout}
          path="/:interviewId/fullscreen"
        />
        <RouteWithLayout
          component={InterviewRouteView}
          exact
          loginRequired={false}
          path="/:interviewId"
        />
        <RouteWithLayout
          component={InterivewView}
          exact
          layout={MainSidebarLayout}
          path="/"
        />
        <Redirect to="/not-found" />
      </Switch>
    </>
  );
};

export default Routes;
