import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { formatDate } from '../../helpers/dateHelpers';

const useStyles = makeStyles(() => ({
  statusContent: {
    border: '1px solid #E2E9F1',
    backgroundColor: '#FFFFFF',
    color: '#182341',
    padding: '20px',
    margin: '20px 0',
    textAlign: 'center!important',
  },
  statusLogo: {
    height: '48px',
    width: '48px',
    backgroundColor: '#E6EAF2',
    marginBottom: '10px',
    marginLeft: 'auto!important',
    marginRight: 'auto!important',
    alignItems: 'center!important',
    justifyContent: 'center!important',
    display: 'flex!important',
    borderRadius: '50%!important',
  },
  statusText: {
    fontFamily: 'PT Serif Caption!important',
    fontSize: '18px!important',
    fontWeight: 'bold',
    lineHeight: '24px',
    marginBottom: '.5rem!important',
  },
  statusInfoLine: {
    fontFamily: 'PT Root UI',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '19px',
    marginBottom: '10px',
    alignItems: 'center!important',
    justifyContent: 'center!important',
    display: 'flex!important',
  },
  mr3: {
    marginRight: '0.5em',
    marginBottom: '-3px',
  },
  redBckgrnd: {
    backgroundColor: '#FFDADA',
  },
  repeatRequestBttn: {
    border: '1px solid #E2E9F1',
    borderRadius: '0px',
    height: '40px',
    color: '#182341',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    padding: '11px 20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1168A7',
      color: 'white',
    },
  },
}));

const OrderInterview = ({ createdAt }) => {
  const classes = useStyles();

  return (
    <Box className={classes.statusContent}>
      <Box className={classes.statusLogo}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9997 22.2222C17.0569 22.2222 16.1997 21.6 15.8569 20.6222C15.8569 20.5333 15.8569 20.4444 15.7711 20.4444H8.14258C8.14258 20.5333 8.14258 20.6222 8.05686 20.6222C7.79972 21.6 6.94258 22.2222 5.99972 22.2222C5.48544 22.2222 5.14258 22.5778 5.14258 23.1111C5.14258 23.6444 5.48544 24 5.99972 24H17.9997C18.514 24 18.8569 23.6444 18.8569 23.1111C18.8569 22.5778 18.514 22.2222 17.9997 22.2222Z" fill="#1168A7">
            {/*  */}
          </path>
          <path d="M14.7426 7.02222L12.8569 7.64444V5.33333C12.8569 4.8 12.514 4.44444 11.9997 4.44444C11.4854 4.44444 11.1426 4.8 11.1426 5.33333V8.88889C11.1426 9.15555 11.314 9.42222 11.4854 9.6C11.6569 9.68889 11.8283 9.77777 11.9997 9.77777C12.0854 9.77777 12.1711 9.77777 12.2569 9.68888L15.2569 8.62222C15.6854 8.44444 15.9426 8 15.7711 7.46666C15.6854 7.11111 15.1711 6.84444 14.7426 7.02222Z" fill="#1168A7">
            {/*  */}
          </path>
          <path d="M21.4286 0H2.57143C1.11429 0 0 1.15556 0 2.66667V16C0 17.5111 1.11429 18.6667 2.57143 18.6667H8.48571H15.4286H21.4286C22.8857 18.6667 24 17.5111 24 16V2.66667C24 1.15556 22.8857 0 21.4286 0ZM12 15.1111C8.65714 15.1111 6 12.3556 6 8.88889C6 5.42222 8.65714 2.66667 12 2.66667C15.3429 2.66667 18 5.42222 18 8.88889C18 12.3556 15.3429 15.1111 12 15.1111Z" fill="#1168A7">
            {/*  */}
          </path>
        </svg>
      </Box>
      <Box className={classes.statusText}>
        Your request has been successfully sent.
      </Box>
      <Box className={classes.statusText}>
        The interview will be available within 24 hrs.
      </Box>
      <Box component="div" className={classes.statusInfoLine}>
        <Box className={classes.mr3}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 16.3125C13.0386 16.3125 16.3125 13.0386 16.3125 9C16.3125 4.96142 13.0386 1.6875 9 1.6875C4.96142 1.6875 1.6875 4.96142 1.6875 9C1.6875 13.0386 4.96142 16.3125 9 16.3125Z" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
              {/*  */}
            </path>
            <path d="M9 3.9375V9H12.9375" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
              {/*  */}
            </path>
          </svg>
        </Box>
        {formatDate(createdAt)}
      </Box>
    </Box>
  );
};

OrderInterview.propTypes = {
  createdAt: PropTypes.string.isRequired,
};

export default OrderInterview;
