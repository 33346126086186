import React, { useEffect, useReducer } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useMutation } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import {
  ME_QUERY,
  UPDATE_USER_SETTINGS_MUTATION,
} from '../../../../../../../../../../queries/account';
import PeriodMenu from '../FollowingItem/PeriodMenu';
import useMe from '../../../../../../../../../../hooks/useMe';

const useStyles = makeStyles((theme) => ({
  root: {
    // position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    backgroundColor: theme.palette.background.paper,
    padding: '10px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    marginBottom: '10px',
  },
  checkbox: {
    margin: '0 0 10px',
    '& .MuiTypography-root': {
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: '16px',
      color: theme.palette.text.secondary,
    },
    '& .MuiButtonBase-root': {
      minHeight: '18px!important',
      padding: '0 5px!important',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
  },
  cancelBtn: {
    cursor: 'pointer!important',
    flexGrow: '1',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '0',
    textTransform: 'capitalize',
    marginRight: '5px',
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  saveBtn: {
    cursor: 'pointer!important',
    flexGrow: '1',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '0',
    textTransform: 'capitalize',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
    },
  },
  settings: {
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&:hover svg path': {
      stroke: theme.palette.primary.main,
    },
  },
}));

const DefaultSettings = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [settings, setSettings] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
  );

  const handleEmailAlertChange = (event) => {
    setSettings({ ...settings, [event.target.name]: event.target.checked });
  };

  const handleFrequency = (value) => {
    setSettings({ ...settings, notifyPeriod: value });
  };

  const { me } = useMe();

  const [updateSettings] = useMutation(UPDATE_USER_SETTINGS_MUTATION, {
    refetchQueries: [
      { query: ME_QUERY },
    ],
  });

  useEffect(() => {
    if (me.settings) {
      setSettings(me.settings);
    }
  }, [me]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
    if (me.settings) {
      setSettings(me.settings);
    }
  };

  const handleSave = () => {
    updateSettings({
      variables: {
        settingsId: settings.id,
        notifyPeriod: settings.notifyPeriod,
        emailAlerts: settings.emailAlerts,
      },
    }).then(({ data: { updateUserSettingsMutation = {} } = {} }) => {
      if (!isEmpty(updateUserSettingsMutation.errors)) {
        enqueueSnackbar(updateUserSettingsMutation.errors.map((err) => (
          `${err.field}: ${err.messages.join(', ')}`
        )),
        { variant: 'error', autoHideDuration: 4000 });
      } else {
        enqueueSnackbar('Saved.',
          { variant: 'success', autoHideDuration: 4000 });
        handleClickAway(false);
      }
    });
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={classes.root}>
        <IconButton
          color="inherit"
          aria-label="settings"
          size="small"
          disableRipple
          className={classes.settings}
          onClick={handleClick}
        >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.1228 7.48479C15.1881 7.60162 14.3118 7.1927 13.786 6.31643C13.3187 5.49858 13.3771 4.44706 13.9613 3.74604C13.085 2.92819 12.0335 2.34401 10.9236 1.99351C10.5731 2.81136 9.75522 3.39554 8.76211 3.39554C7.76901 3.39554 6.95116 2.81136 6.60065 1.93509C5.4323 2.2856 4.38077 2.86977 3.56292 3.68762C4.08868 4.44706 4.20552 5.49858 3.67976 6.31643C3.21242 7.13428 2.27773 7.60162 1.34305 7.48479C1.22621 8.06896 1.10938 8.65314 1.10938 9.23732C1.10938 9.8215 1.16779 10.4641 1.34305 10.9899C2.27773 10.873 3.154 11.2819 3.67976 12.1582C4.1471 12.9761 4.08869 14.0276 3.50451 14.7286C4.38077 15.5464 5.4323 16.1306 6.54223 16.4811C6.89274 15.6633 7.71059 15.0207 8.70369 15.0207C9.6968 15.0207 10.5146 15.6049 10.8652 16.4811C12.0335 16.1306 13.085 15.5464 13.9029 14.7286C13.3771 14.0276 13.2603 12.9761 13.7276 12.1582C14.195 11.3404 15.1297 10.873 16.0643 10.9899C16.1812 10.4057 16.298 9.8215 16.298 9.23732C16.3564 8.65314 16.298 8.01055 16.1228 7.48479Z"
              stroke="#BFCCDC"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.7623 10.9899C9.7302 10.9899 10.5148 10.2052 10.5148 9.23734C10.5148 8.26944 9.7302 7.4848 8.7623 7.4848C7.7944 7.4848 7.00977 8.26944 7.00977 9.23734C7.00977 10.2052 7.7944 10.9899 8.7623 10.9899Z"
              stroke="#BFCCDC"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </IconButton>
        {open ? (
          <Box className={classes.dropdown}>
            <Box className={classes.title}>
              Default settings for all newly added items
            </Box>
            <FormControlLabel
              labelPlacement="start"
              label="Enable email alerts"
              className={classes.checkbox}
              control={(
                <Checkbox
                  checked={settings.emailAlerts}
                  onChange={handleEmailAlertChange}
                  name="emailAlerts"
                  color="primary"
                  disableRipple
                />
                        )}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Box
                fontSize="13px"
                fontWeight="500"
                lineHeight="16px"
                color={settings.emailAlerts ? 'text.secondary' : 'info.main'}
              >
                Email Frequency
              </Box>
              <PeriodMenu
                onChange={handleFrequency}
                disabled={!settings.emailAlerts}
                frequency={settings.notifyPeriod}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              mt="10px"
            >
              <Button
                size="small"
                color="inherit"
                disableRipple
                onClick={handleClickAway}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                size="small"
                color="inherit"
                disableRipple
                className={classes.saveBtn}
                onClick={handleSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

export default DefaultSettings;
