/* eslint-disable no-unused-vars */
import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { gql } from '@apollo/client/core';
import { REQUEST_EXPERT_CALL_MUTATION, INTERVIEW_QUERY } from '../../queries/interview/interview';
import { ReactComponent as SuccessImage } from '../../assets/images/success.svg';

const useStyles = makeStyles((theme) => ({
  closeDialog: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
  },
  dialogContent: {
    padding: '0 15px',
    [theme.breakpoints.up('md')]: {
      padding: '0 50px',
    },
  },
  dialogContentFirst: {
    padding: '10px 15px 0',
    [theme.breakpoints.up('md')]: {
      padding: '10px 30px 0',
    },
  },
  dialogMessage: {
    width: '100%',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '25px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '30px',
    },
  },
  dialogMessageFirst: {
    width: '100%',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    textAlign: 'center',
  },
  dialogActions: {
    padding: '15px',
    [theme.breakpoints.up('md')]: {
      padding: '20px 50px 50px',
    },
  },
  dialogActionsFirst: {
    padding: '15px',
    [theme.breakpoints.up('md')]: {
      padding: '16px 30px 30px',
    },
  },
  confirmBtn: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  closeDialogBtn: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const RequestExpertCallBtn = ({
  variant, className, startIcon, enabled, expertPk, interviewId,
  hideTooltip, tooltipTitle, title, otherTitle,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [sendCallRequest] = useMutation(REQUEST_EXPERT_CALL_MUTATION, {
    update: (cache) => {
      cache.modify({
        fields: {
          interview(interview = {}) {
            cache.writeFragment({
              // eslint-disable-next-line no-underscore-dangle
              id: interview.__ref,
              fragment: gql`
                fragment interviewExpert on InterviewType {
                  expert {
                    isCallRequestAllowed
                  }
                }
              `,
              data: {
                expert: {
                  isCallRequestAllowed: false,
                },
              },
            });
          },
        },
      });
    },
  });
  const [open, setOpen] = React.useState(false);
  const [send, setSend] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSend(false);
  };

  const handleClick = () => {
    sendCallRequest({ variables: { expertPk } })
      .then(({ data: { sendExpertCallRequest = {} } = {} }) => {
        setSend(true);
      }).catch((error) => {
        const messages = error.graphQLErrors.map((el) => el.message).join(', ');
        enqueueSnackbar(messages, { variant: 'error', autoHideDuration: 4000 });
      });
  };

  return (
    <>
      <Tooltip
        disableFocusListener={hideTooltip}
        disableHoverListener={hideTooltip}
        disableTouchListener={hideTooltip}
        title={tooltipTitle}
      >
        <Button
          onClick={handleOpen}
          variant={variant}
          className={className}
          startIcon={startIcon}
          disabled={!enabled}
          disableRipple
        >
          {enabled
            ? (
              title || 'Request a Call with this Expert'
            )
            : (
              otherTitle || 'Call Requested'
            )}
        </Button>
      </Tooltip>
      <Dialog onClose={handleClose} open={open}>
        <MuiDialogTitle disableTypography>
          <IconButton
            aria-label="close"
            className={classes.closeDialog}
            onClick={handleClose}
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        {send
          ? (
            <>
              <MuiDialogContent className={classes.dialogContent}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <SuccessImage />
                  <Box className={classes.dialogMessage}>
                    Thanks for your request.
                    We will be in touch with the expert’s availability shortly.
                  </Box>
                </Box>
              </MuiDialogContent>
              <MuiDialogActions className={classes.dialogActions}>
                <Button
                  autoFocus
                  onClick={handleClose}
                  disableRipple
                  className={classes.closeDialogBtn}
                >
                  Close
                </Button>
              </MuiDialogActions>
            </>
          )
          : (
            <>
              <MuiDialogContent className={classes.dialogContentFirst}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box className={classes.dialogMessageFirst}>
                    Are you sure you want to have a call with this expert?
                  </Box>
                </Box>
              </MuiDialogContent>
              <MuiDialogActions className={classes.dialogActionsFirst}>
                <Button
                  onClick={handleClose}
                  disableRipple
                  className={classes.closeDialogBtn}
                >
                  Cancel
                </Button>
                <Button
                  autoFocus
                  onClick={handleClick}
                  disableRipple
                  className={classes.confirmBtn}
                >
                  Confirm
                </Button>
              </MuiDialogActions>
            </>
          )}
      </Dialog>
    </>
  );
};

RequestExpertCallBtn.defaultProps = {
  variant: 'text',
  className: '',
  startIcon: null,
  hideTooltip: true,
  tooltipTitle: '',
  title: null,
  otherTitle: null,
};

RequestExpertCallBtn.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  startIcon: PropTypes.node,
  hideTooltip: PropTypes.bool,
  tooltipTitle: PropTypes.node,
  title: PropTypes.node,
  otherTitle: PropTypes.node,
  enabled: PropTypes.bool.isRequired,
  expertPk: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
};

export default RequestExpertCallBtn;
