import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { singletonHook } from 'react-singleton-hook';
import { ALL_INTERVIEWS_QUERY } from '../queries/interview/interview';
import { INTERVIEW_PAGINATION_FIRST, INTERVIEW_PAGINATION_SKIP } from '../constants/pagination';
import { getFiltersData } from '../helpers/filterHelpers';

const INITIAL_VALS = {
  loading: false,
  allInterviews: {},
  refetch: () => {},
  called: false,
  updateQuery: () => {},
};

// const userStateReducer = (state, action) => {
//   switch (action.type) {
//     case actions.user.SET_USER:
//       return { ...state, user: action.payload };
//     case action.user.UNSET_USER:
//       return { ...state, user: initialData.user };
//     default:
//       throw new Error(`Action type \`${action.type}\` does not exists on userStateReducer`);
//   }
// };

const useAllIntervies = () => {
  const activeTab = useSelector((state) => state.sideBar.activeTab, isEqual);
  const filterData = useSelector((state) => state.filterState.filterData, isEqual);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const first = query.get('first') || INTERVIEW_PAGINATION_FIRST;
  const skip = query.get('skip') || INTERVIEW_PAGINATION_SKIP;

  const [interviewState, dispatch] = useReducer(
    (state, newState) => ({ ...state, ...newState }), INITIAL_VALS,
  );

  const [getAllInterviews, {
    loading,
    data: { allInterviews = {} } = {},
    refetch,
    called,
    updateQuery,
  }] = useLazyQuery(ALL_INTERVIEWS_QUERY, {
    variables: {
      activeTab,
      first,
      skip,
      ...getFiltersData(filterData),
    },
    // fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    getAllInterviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && called) {
      getAllInterviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, first, skip, filterData]);

  useEffect(() => {
    let newState = {};
    if (!isEqual(allInterviews, interviewState.allInterviews)) {
      newState = { ...newState, allInterviews };
    }

    if (isEmpty(interviewState.allInterviews) && !isEmpty(allInterviews)) {
      newState = { ...newState, refetch, updateQuery };
    }

    if (loading !== interviewState.loading) {
      newState = { ...newState, loading };
    }

    if (called !== interviewState.called) {
      newState = { ...newState, called };
    }

    if (!isEmpty(newState)) {
      dispatch({ ...interviewState, ...newState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allInterviews, loading, called]);

  return interviewState;
};

export default singletonHook(INITIAL_VALS, useAllIntervies);
